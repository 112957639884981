import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { downlaodFile } from "../../apis/projectapi";
import { downloadedFile } from "../../functions/functions";
import { message, Spin, Modal, Progress, Button } from "antd";
import { updateInfoWithID } from "../../apis/paymentapi";
import { mailPaymentReceipt } from "../../apis/mailapi";
import { CELLNINJACOLOR } from "../../utils/constants";
import DOWNLOADICON from "../../images/downloading-icon-cloud.png";
import { dispatch } from "../../store";
import { saveSelfServiceData } from "../../actions/selfServiceEntityAction";
import { useSelector } from "react-redux";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [messageAsLabel, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [paid, setPaid] = useState("false");
  const id = localStorage.getItem("uniqueId");
  const fileName = localStorage.getItem("fileName");
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadCompletionModal, setDownloadCompletionModal] = useState(false);

  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );
  useEffect(() => {
    if (!stripe) {
      return;
    }
    setIsLoading(false);
  }, [stripe]);

  const getReportCharges = () => {
    if (currentUser.reportCount < 21) return "$10";
    else if (getSelfServiceEntity.reportType === "basic") return "$175";
    else if (getSelfServiceEntity.reportType === "premium") return "$250";
    else return "$325";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    await stripe
      .confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          // return_url: "http://localhost:3000/",
          receipt_email: email,
        },
        redirect: "if_required",
      })
      .then((res) => {
        if (res) {
          if (res.paymentIntent) {
            switch (res.paymentIntent.status) {
              case "succeeded":
                setMessage("Payment Succeeded!");
                setPaid(true);
                updatePaymentStatus(id, res.paymentIntent);
                break;

              case "processing":
                setMessage("Your payment is processing.");
                break;

              case "requires_payment_method":
                setMessage(
                  "Your payment was not successful, please try again."
                );
                break;

              default:
                setMessage("Something went wrong.");
                break;
            }
          } else if (res.error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Otherwise, your customer will be redirected to
            // your `return_url`. For some payment methods like iDEAL, your customer will
            // be redirected to an intermediate site first to authorize the payment, then
            // redirected to the `return_url`.
            if (
              res.error.type === "card_error" ||
              res.error.type === "validation_error" ||
              res.error.type === "invalid_request_error"
            ) {
              setMessage(res.error.message);
            } else {
              setMessage("An unexpected error occured.");
            }
          }

          setIsLoading(false);
        }
      });
  };

  const updatePaymentStatus = (id, data) => {
    updateInfoWithID(id, data)
      .then((res) => {
        if (res.status === 200) {
          downloadFile();
          emailPaymentReceipt(res.data.uniqueId);
        }
      })
      .catch((error) => {
        message.error("error", error);
      });
  };

  const emailPaymentReceipt = (id) => {
    mailPaymentReceipt(id)
      .then((response) => {
        if (response.status === 200) {
          console.log("Email Sent Successfully");
        } else {
          console.log("Error Occured on Server");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadFile = () => {
    if (paid) {
      let analysisReport;
      if (fileName.includes("AnalysisReport")) {
        analysisReport = true;
      } else {
        analysisReport = false;
      }
      setDownloadModal(true);
      downlaodFile(id, analysisReport)
        .then((response) => {
          if (response.status === 200) {
            downloadedFile(response, "Final Report_" + fileName);
            getSelfServiceEntity.reportPayment = true;
            dispatch(saveSelfServiceData());
            if (props) props.onPaymentDone();
          }
        })
        .catch((error) => {
          message.error("error", error);
        })
        .finally(() => {
          setDownloadModal(false);
          setDownloadCompletionModal(true);
        });
    }
  };

  return (
    <>
      <form
        style={{ margin: "1rem" }}
        id="payment-form"
        onSubmit={handleSubmit}
      >
        <div style={{ textAlign: "center" }}>
          <h2 style={{ color: "black", fontSize: "20px" }}>
            <b>Payable to Cell Ninja</b>
          </h2>
          {/* <h2 style={{ color: "black", fontSize: "20px" }}>
            <b>dba Cell Ninja</b>
          </h2> */}
        </div>
        <div style={{ textAlign: "center" }}>
          <h2 style={{ color: "black", fontSize: "25px" }}>
            <b>{getReportCharges()}</b>
          </h2>
        </div>
        <input
          className="payment-email-field"
          required
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoFocus={true}
          placeholder="Enter email address"
        />
        <PaymentElement
          id="payment-element"
          onReady={() => (document.getElementById("submit").disabled = false)}
        />
        <Spin spinning={isLoading ? true : false}>
          <button disabled={true} id="submit" className="stripe-pay-btn">
            <span id="button-text">
              <div
                style={{
                  display: "-webkit-inline-box",
                  whiteSpace: "pre-wrap",
                }}
              >
                <p style={{ color: "white", display: "inline" }}>Pay Now</p>
              </div>
            </span>
          </button>
        </Spin>

        <div>
          <Modal
            open={downloadModal}
            width={500}
            footer={null}
            closable={false}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img
                alt="downloadicon"
                src={DOWNLOADICON}
                height={80}
                width={80}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{"Your file is downloading..."}</h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setDownloadModal(false);
                }}
              >
                OK
              </Button>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            open={downloadCompletionModal}
            width={500}
            footer={null}
            closable={false}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img
                alt="downloadicon"
                src={DOWNLOADICON}
                height={80}
                width={80}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{"Downloading Completed..."}</h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setDownloadCompletionModal(false);
                }}
              >
                OK
              </Button>
            </div>
          </Modal>
        </div>

        {/* Show any error or success messages */}
        {messageAsLabel && <div id="payment-message">{messageAsLabel}</div>}
      </form>
    </>
  );
}
