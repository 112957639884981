import { Button, Table, Tag, Modal, Spin, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import RoleForm from "./roleForm";
import { useSelector } from "react-redux";
import { getRoles } from "../../../apis/settingsapi";
import { EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import "./style.scss";
import {
  ADD_ROLE,
  UPDATE_ROLE,
  CELLNINJACOLOR,
} from "../../../utils/constants";
import { checkToken } from "../../../functions/functions";
import { useNavigate } from "react-router-dom";

const Roles = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [specificRole, setSpecificRole] = useState([]);
  const [apiLoading, setAPILoading] = useState(true);
  const [roleKey, setRoleKey] = useState();
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach(
          (permission) => (uniquePermissions[permission.id] = permission)
        );
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const newPermissions = () => {
    setShowModal(true);
    setRoleKey("Add");
  };

  const onEditRole = () => {
    roles.forEach((role) => {
      if (role.id === selectedRows[0]) {
        setSpecificRole(role);
      }
    });
    setShowModal(true);
    setRoleKey("Edit");
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const fetchRoles = async () => {
    const response = await getRoles();
    if (response.status === 200) {
      setAPILoading(false);
      setRoles(response.data.sort((role1, role2) => role1.id - role2.id));
    }
  };

  useEffect(() => {
    fetchRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPermissionsTitle = () => {
    return (
      <header className="table-header">
        <h3>Roles</h3>
        <div className="table_actions">
          <div>
            {permissions.map((permission) => {
              return permission.name === ADD_ROLE ? (
                <Tooltip title="Add Role">
                  <Button
                    style={{ marginLeft: "10px" }}
                    type="primary"
                    shape="round"
                    onClick={newPermissions}
                    icon={<PlusCircleOutlined />}
                  ></Button>
                </Tooltip>
              ) : (
                ""
              );
            })}

            {permissions.map((permission) => {
              return permission.name === UPDATE_ROLE ? (
                <Tooltip title="Edit Role">
                  <Button
                    style={{ marginLeft: "10px" }}
                    type="default"
                    shape="round"
                    disabled={selectedRows.length !== 1}
                    onClick={onEditRole}
                    icon={<EditOutlined />}
                  ></Button>
                </Tooltip>
              ) : (
                ""
              );
            })}
          </div>
        </div>
      </header>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      key: "name",
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      align: "center",
      key: "permissions",
      render: (record) => (
        <>
          {record.map((permission) => {
            return (
              <Tag color="blue" key={permission.id} style={{ margin: "1px" }}>
                {permission.name ? permission.name : permission.name}
              </Tag>
            );
          })}
        </>
      ),
    },
  ];

  const onSelectChange = (keys) => {
    setSelectedRows(keys);
  };

  return (
    <React.Fragment>
      <Spin
        spinning={apiLoading}
        style={{ color: CELLNINJACOLOR }}
        tip="Loading Roles..."
        className="spin-loading"
      >
        <Table
          bordered
          dataSource={roles}
          columns={columns}
          title={renderPermissionsTitle}
          pagination={false}
          rowKey={"id"}
          rowSelection={
            roles.length > 0
              ? {
                  type: "checkbox",
                  onChange: onSelectChange,
                  selectedRowKeys: selectedRows,
                }
              : null
          }
        />
      </Spin>
      <Modal open={showModal} onCancel={hideModal} title="Roles" footer={null}>
        <RoleForm
          action={roleKey}
          id={selectedRows[0]}
          role={specificRole}
          closeModal={() => setShowModal()}
          newRole={() => fetchRoles()}
        />
      </Modal>
    </React.Fragment>
  );
};
export default Roles;
