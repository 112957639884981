/* eslint-disable react-hooks/rules-of-hooks */
import { CheckCircleOutlined } from "@ant-design/icons";
import { Modal, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getUserHistory } from "../../../apis/ssr";

const userHistory = ({ user, visible, setVisible }) => {
  const [records, setRecords] = useState();
  const [totalPayments, setTotalPayments] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserRecords(user?.id);
  }, [visible, user]);

  const fetchUserRecords = (id) => {
    if (id !== undefined)
      getUserHistory(id)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            let payment = 0;
            setRecords(response.data);
            response.data.forEach((record) => {
              payment += parseInt(record.amount ? record.amount : "0");
            });
            setTotalPayments(payment);
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
  };

  const columns = [
    {
      title: "Original File",
      dataIndex: "fileName",
      key: "fileName",
      align: "center",
      width: "23%",
      render: (fileName) => (
        <React.Fragment>{fileName ? fileName : "-"}</React.Fragment>
      ),
    },

    {
      title: "Final Report",
      dataIndex: "outputPath",
      key: "outputPath",
      align: "center",
      width: "23%",
      render: (outputPath) => {
        if (outputPath) {
          let file = outputPath.split("/").pop();
          return <React.Fragment>{file ? file : ""}</React.Fragment>;
        }
      },
    },
    {
      title: "File Size (kb)",
      dataIndex: "fileSize",
      width: "5.3%",
      align: "center",
      key: "fileSize",
      render: (fileSize) => (fileSize ? `${(fileSize / 1024).toFixed(0)}` : ""),
    },
    {
      title: "Run Date (UTC)",
      dataIndex: "creationDate",
      align: "center",
      width: "8.3%",
      render: (creationDate) => (
        <React.Fragment>
          {creationDate ? moment(creationDate).format("MM/DD/YYYY HH:mm") : ""}
        </React.Fragment>
      ),
    },
    {
      title: "Processing Time (s)",
      dataIndex: "processingTime",
      key: "processingTime",
      align: "center",
      width: "6%",
      render: (processingTime) => (
        <React.Fragment>
          {processingTime
            ? `${moment.duration(processingTime).asSeconds().toFixed(0)}`
            : ""}
        </React.Fragment>
      ),
    },

    {
      title: "Paid",
      dataIndex: "isPaid",
      key: "isPaid",
      align: "center",
      width: "3%",
      render: (isPaid) => (
        <React.Fragment>
          {isPaid ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "17px" }} />
          ) : (
            ""
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Amt ($)",
      dataIndex: "amount",
      key: "amt",
      align: "center",
      width: "6%",
    },
  ];

  return (
    <React.Fragment>
      <Modal
        open={visible}
        onCancel={() => {
          setVisible(false);
          setRecords();
          setTotalPayments(0);
        }}
        title={"User History For: " + user?.id}
        footer={null}
        width={1000}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <h4>Name:</h4>
            <h4>Registration Date:</h4>
            <h4>Payments Total:</h4>
          </div>
          <div style={{ marginLeft: "5px" }}>
            <h4>
              <strong>{" " + user?.name}</strong>
            </h4>
            <h4>
              <strong>
                {" " +
                  moment(user?.creationDate).format("MM/DD/yyyy hh:mm:ss a")}
              </strong>
            </h4>
            <h4>
              <strong>{" " + totalPayments + " $"}</strong>
            </h4>
          </div>
        </div>
        <Table
          scroll={{ y: "40vh" }}
          bordered
          loading={loading}
          dataSource={records}
          columns={columns}
          // title={renderUsers}
          pagination={false}
        ></Table>
      </Modal>
    </React.Fragment>
  );
};

export default userHistory;
