import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Roles from "./roles";
import Users from "./users";
import { useSelector } from "react-redux";
import { VIEW_ROLE, VIEW_USER } from "../../../utils/constants";

const Settings = () => {
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const { TabPane } = Tabs;

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          uniquePermissions[permission.id] = permission;
        });
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser]);

  return (
    <React.Fragment>
      <Tabs defaultActiveKey="1" centered>
        {permissions.map((permission) => {
          return permission.name === VIEW_ROLE ? (
            <TabPane
              tab={
                <span>
                  <UserOutlined />
                  Roles
                </span>
              }
              key="1"
            >
              <Roles />
            </TabPane>
          ) : (
            ""
          );
        })}
        {permissions.map((permission) => {
          return permission.name === VIEW_USER ? (
            <TabPane
              tab={
                <span>
                  <UserOutlined />
                  Users
                </span>
              }
              key="2"
            >
              <Users />
            </TabPane>
          ) : (
            ""
          );
        })}
      </Tabs>
    </React.Fragment>
  );
};
export default Settings;
