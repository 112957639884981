import {
  MessageFilled,
  MessageTwoTone,
  SettingFilled,
} from "@ant-design/icons";
import CategoryIcon from "@material-ui/icons/Category";
import DescriptionIcon from "@material-ui/icons/Description";
import { Button, Dropdown, Layout, Menu, Modal } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import {
  AnimationTypes,
  ComponentTransition,
} from "react-component-transition";
import { useSelector } from "react-redux";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  resetSelfService,
  saveSelfServiceData,
} from "../actions/selfServiceEntityAction";
import Settings from "../components/admin/settings/settings";
import ProjectInfo from "../components/projects/projectinfo";
import Projects from "../components/projects/projects";
import ProjectView from "../components/projects/projectview";
import Loader from "../components/ui-kit/loader/index";
import logo from "../images/Cell_Ninja_adjusted_logo.png";
import { dispatch } from "../store";
import {
  VIEW_ALL_PROJECTS,
  VIEW_SETTINGS,
  VIEW_SSR_TABLE,
} from "../utils/constants";
import ChangePasswordForm from "./admin/settings/changePasswordForm";
import "./app/index.css";
import ProjectTowers from "./projects/projecttowers";
import SelfServiceRequest from "./selfServiceRequest/selfServiceRequest";
import CustomerMessages from "./admin/customerMessages/customerMessages";

const { Header, Content, Footer } = Layout;

function Home(props) {
  const navigate = useNavigate();
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));

  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          uniquePermissions[permission.id] = permission;
        });
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser]);

  useEffect(() => {
    if (
      (!localStorage.getItem("user") || localStorage.getItem("user") === {}) &&
      !localStorage.getItem("__CDR-Analyzer")
    ) {
      navigate("/user/login");
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const getViewKey = () => {
    switch (location?.pathname) {
      case "/projectDetails":
        return "projectDetails";
      case "/parserconfig":
        return "parserconfig";
      case "/templates":
        return "templates";
      case "/settings":
        return "settings";
      case "/uniquephonenumbers":
        return "uniquephonenumbers";
      case "/customermessages":
        return "customermessages";
      default:
        return "projects";
    }
  };

  const [selectedKey, setSelectedKey] = useState(getViewKey());

  const handleEntityChange = (name) => {
    if (Number.isInteger(name.id)) {
      setSelectedKey(name);
      navigate("/user/home/projectDetails");
    } else {
      setSelectedKey(name);
      navigate("/user/home/" + name);
    }
  };

  const onSave = () => {
    setChangePasswordModal(false);
    handleSignOut();
    navigate("/user/login");
  };

  const handleSignOut = () => {
    props.onLogout();
  };

  const changePassword = () => {
    setChangePasswordModal(true);
  };

  const editProfile = () => {};

  const subMenu = (
    <Menu hidden="" style={{ padding: "5px", borderRadius: "8px" }}>
      <div style={{ display: "flex", pointerEvents: "none" }}>
        <div>
          <Button
            shape="circle"
            type="text"
            style={{
              border: "1px solid",
              margin: "5px",
              backgroundColor: "#e2cece",
            }}
          >
            {" "}
            {user?.name[0]}{" "}
          </Button>
        </div>
        <div>
          <div>
            <strong>{user?.name}</strong>
          </div>
          <div>{user?.email}</div>
        </div>
      </div>
      <Menu.Item key="profile" onClick={editProfile}>
        Edit Profile
      </Menu.Item>
      <Menu.Item key="password" onClick={changePassword}>
        Change Password
      </Menu.Item>
      <a href="/">
        <Menu.Item key="logout" onClick={handleSignOut}>
          Logout
        </Menu.Item>
      </a>
    </Menu>
  );

  const clearRedux = () => {
    dispatch(resetSelfService());
    getSelfServiceEntity.uploadedFiles.voice = "";
    getSelfServiceEntity.uploadedFiles.data = "";
    getSelfServiceEntity.uploadedFiles.sms = "";
    getSelfServiceEntity.uploadedFiles.ipSessions = "";
    getSelfServiceEntity.uploadedFiles.tower = "";

    dispatch(saveSelfServiceData("filesToUpload", []));
    dispatch(saveSelfServiceData());
  };

  return (
    <React.Fragment>
      <Outlet />
      <Suspense fallback={<Loader />}>
        <Layout className="header-style">
          <Header
            style={{
              position: "fixed",
              zIndex: 1,
              width: "100%",
              height: "72px",
            }}
          >
            <Menu mode="horizontal">
              <Menu.Item
                key="logo"
                onClick={() => {
                  clearRedux();
                  navigate("/user/selfservice");
                }}
              >
                <div
                  style={{
                    width: "130px",
                    background: "rgba(255, 255, 255, 0.3)",
                  }}
                >
                  <img
                    src={logo}
                    alt="companylogo"
                    style={{
                      width: "101px",
                      height: "68px",
                    }}
                  />
                </div>
              </Menu.Item>
              <div
                style={{
                  marginLeft: "15px",
                  marginRight: "4%",
                  float: "right",
                }}
              >
                <Dropdown overlay={subMenu}>
                  <Button shape="circle">{user?.name[0]}</Button>
                </Dropdown>
              </div>
            </Menu>
          </Header>
          <Content>
            <Layout className="site-layout-background">
              <div
                style={{
                  display: "flex",
                  background: "#c0cad9",
                }}
              >
                <div
                  className="menu-div-style"
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    marginTop: "60px",
                    display: "flex",
                    width: "136px",
                  }}
                >
                  <Menu
                    selectedKeys={selectedKey}
                    defaultSelectedKeys={["projects"]}
                    defaultOpenKeys={["projects"]}
                    style={{
                      textAlignLast: "left",
                      width: "-webkit-fill-available",
                    }}
                  >
                    {permissions.map((permission) => {
                      return permission.name === VIEW_ALL_PROJECTS ? (
                        <Menu.Item
                          icon={<DescriptionIcon />}
                          key="projects"
                          style={{ textAlign: "center" }}
                          onClick={() => handleEntityChange("projects")}
                        >
                          Projects
                        </Menu.Item>
                      ) : (
                        ""
                      );
                    })}

                    {permissions.map((permission) => {
                      return permission.name === VIEW_SSR_TABLE ? (
                        <Menu.Item
                          icon={<CategoryIcon />}
                          key="ssr"
                          style={{ textAlign: "center" }}
                          onClick={() => handleEntityChange("ssr")}
                        >
                          SSR
                        </Menu.Item>
                      ) : (
                        ""
                      );
                    })}
                    {permissions.map((permission) => {
                      return permission.name === VIEW_SETTINGS ? (
                        <Menu.Item
                          icon={<SettingFilled />}
                          key="settings"
                          style={{ textAlign: "center" }}
                          onClick={() => handleEntityChange("settings")}
                        >
                          Settings
                        </Menu.Item>
                      ) : (
                        ""
                      );
                    })}
                    {permissions.map((permission) => {
                      return permission.name === VIEW_SETTINGS ? (
                        <Menu.Item
                          icon={<MessageTwoTone />}
                          key="settings"
                          style={{ textAlign: "center" }}
                          onClick={() => handleEntityChange("customermessages")}
                        >
                          Customer Messages
                        </Menu.Item>
                      ) : (
                        ""
                      );
                    })}
                  </Menu>
                </div>
                <div
                  className=""
                  style={{
                    width: "-webkit-fill-available",
                    marginTop: "60px",
                    display: "inline-grid",
                    marginRight: "8px",
                  }}
                >
                  <Content className="projects-cards-background">
                    <ComponentTransition
                      animateContainer={true}
                      enterAnimation={AnimationTypes.slideRight.enter}
                      exitAnimation={AnimationTypes.slideRight.exit}
                    >
                      <Routes key={location.key} location={location}>
                        {permissions.map((permission) => {
                          return permission.name === VIEW_ALL_PROJECTS ? (
                            <Route
                              exact
                              path="projects"
                              element={
                                <Projects
                                  projectView={handleEntityChange}
                                  logout={handleSignOut}
                                />
                              }
                            />
                          ) : (
                            ""
                          );
                        })}
                        {permissions.map((permission) => {
                          return permission.name === VIEW_ALL_PROJECTS ? (
                            <Route
                              exact
                              path="uniquephonenumbers"
                              element={<ProjectInfo />}
                            />
                          ) : (
                            ""
                          );
                        })}
                        {permissions.map((permission) => {
                          return permission.name === VIEW_ALL_PROJECTS ? (
                            <Route
                              exact
                              path="uniquetowers"
                              element={<ProjectTowers />}
                            />
                          ) : (
                            ""
                          );
                        })}
                        {permissions.map((permission) => {
                          return permission.name === VIEW_SSR_TABLE ? (
                            <Route
                              exact
                              path="ssr"
                              element={<SelfServiceRequest />}
                            />
                          ) : (
                            ""
                          );
                        })}
                        {permissions.map((permission) => {
                          return permission.name === VIEW_ALL_PROJECTS ? (
                            <Route
                              exact
                              path="projectDetails"
                              element={<ProjectView project={selectedKey} />}
                            />
                          ) : (
                            ""
                          );
                        })}
                        {permissions.map((permission) => {
                          return permission.name === VIEW_SETTINGS ? (
                            <Route
                              exact
                              path="settings"
                              element={<Settings />}
                            />
                          ) : (
                            ""
                          );
                        })}
                        {permissions.map((permission) => {
                          return permission.name === VIEW_SETTINGS ? (
                            <Route
                              exact
                              path="customermessages"
                              element={<CustomerMessages />}
                            />
                          ) : (
                            ""
                          );
                        })}
                      </Routes>
                    </ComponentTransition>
                  </Content>
                </div>
              </div>
            </Layout>
          </Content>
          <Footer
            style={{
              textAlign: "center",
              position: "fixed",
              width: "100%",
              left: "0",
              bottom: "0",
              zIndex: 1,
            }}
          >
            COPYRIGHT © 2021 · Cell Ninja
          </Footer>
        </Layout>
      </Suspense>
      <Modal
        open={changePasswordModal}
        footer={null}
        title="Change Password"
        onCancel={() => setChangePasswordModal(false)}
      >
        <ChangePasswordForm
          style={{ textAlign: "-webkit-center" }}
          onSave={() => onSave()}
        />
      </Modal>
    </React.Fragment>
  );
}
export default Home;
