import { getCustomerMessages } from "../apis/customermessages";
import { dispatch } from "../store";
import { SET_LOADING, RESET_LOADING, SET_MESSAGE } from "./../utils/constants";

/** APP ACTIONS */

// Set Loadinng with default values

export const setLoading = (
  payload = { text: "Loading...", status: true },
  context = "app"
) => {
  return {
    type: SET_LOADING,
    payload: {
      context,
      ...payload,
    },
  };
};

// Reset Loading Based on context

export const resetLoading = (context) => {
  return {
    type: RESET_LOADING,
    payload: { context },
  };
};

// export const saveMessages = (messages) => {
//   return {
//     type: SET_MESSAGE,
//     payload: messages,
//   };
// };

export const setCustomerMessage = (response) => {
  if (response) {
    const hashMap = {};
    response.forEach((item) => {
      hashMap[item.occasion] = item.messages;
    });
    const customerMessages = JSON.stringify(hashMap);
    localStorage.setItem("customerMessages", customerMessages);
  }
};
