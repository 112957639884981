import React from 'react'
import './style.scss'
import howitworksimg from '../../../images/fast-results.jpg'
import verizon from '../../../images/verizon.png'
import Atnt from '../../../images/atnt.png'
import Tmobile from '../../../images/tmobil.png'


const Fast = () => {
    return (
        <div className='main-container-fast'>
            <div className='staticheading'>
                <span className='about'>Fast </span>
                <span className='us'>Results</span>
            </div>
            <div className='m-con'>
                <div className='left-right-con'>
                    <div className='leftie'>
                        <div className='point'>
                            <span className='firstpara'>We’ve engineered the time and complexity out of cellphone records analysis, to give you nearly instant insights into cell phone users’ activities, locations, movements, local times, connections, and patterns of behavior.</span>
                        </div>
                        <div className='sec-portion'>
                            <span className='span-understand'>CellNinja cellular reports save you valuable time and money!<br /></span>
                            <ul className='ul-for-accurate'>
                                <li>Get the relevant facts effortlessly, and assess potential cases faster.</li>
                                <li>Cut through immaterial data to focus on critical evidence in minutes.</li>
                                <li>Expedite negotiation, litigation or prosecution decision-making, with highly-effective and reliable visual and documentary evidence.</li>
                                <li>See vital connections and patterns of behavior that others miss!</li>
                            </ul>
                        </div>
                        <div className='badges-container'>
                            <img src={verizon} className="badge" alt="" />
                            <img src={Atnt} className="badge" alt="" />
                            <img src={Tmobile} className="badge" alt="" />
                        </div>
                        <div className='affiliate-container'>
                            <span className='affiliates-span'>(plus their affiliates)</span>
                        </div>
                    </div>
                    <div className='rightie'>
                        <img className='img-how-it-works' src={howitworksimg} alt="" />
                        <div className='facts-container'>
                            <span className='facts'>Fact:</span>
                            <span className='people-span'>A cell phone connects to the nearest cell tower with its own coverage area.</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Fast;