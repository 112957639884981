import React from "react"
import {
    CheckOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import './subscribe.scss'
const list = [
    {
        key: 1,
        feature: 'Easy-to-read CellNinja Reports',
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    },
    { // \u00a0 is used to represent a space
        key: 2,
        feature: '\u00a0\u00a0\u00a0 -\u00a0\u00a0Details of voice calls, Internet use, and text messaging by date and time',
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    },
    {
        key: 3,
        feature: '\u00a0\u00a0\u00a0 -\u00a0\u00a0 All activities in local times',
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    },
    {
        key: 4,
        feature: '\u00a0\u00a0\u00a0 -\u00a0\u00a0 General location and movement of the target cell phone',
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    },
    {
        key: 5,
        feature: '\u00a0\u00a0\u00a0 -\u00a0\u00a0 Internet usage details',
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    },
    {
        key: 6,
        feature: `Cellphone companies' subpoena contacts `,
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 7,
        feature: 'Report certification letter',
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 8,
        feature: 'Federal Motor Carrier Safety Administration (FMCSA) cell phone use rules',
        basicRPD: <CheckOutlined className='tick-adjuster' />,
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 9,
        feature: 'Maps of cell tower coverage areas for each activity',
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 10,
        feature: 'Customize your report with incident date and time',
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 11,
        feature: 'Highlights activities when cell phone is moving',
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 12,
        feature: 'Highlights activities at the incident time ',
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 13,
        feature: 'Highlights 911 calls',
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 14,
        feature: <><span>Details ALL cell towers used during each activity <sup className='sup-color'>1</sup></span></>,
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 15,
        feature: <><span>Shows route map of ALL cell towers used for each activity<sup className='sup-color'>1</sup></span></>,
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 16,
        feature: 'Customize your report by entering names for associated telephone numbers',
        basicRPD: ' ',
        premiumRPD: <CheckOutlined className='tick-adjuster' />,
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 17,
        feature: (
            <div style={{ width: "100%" }} className='mid-row'>
                <Typography.Text>Member Only Benefits</Typography.Text>
            </div>
        ),
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: ' ',
    }, {
        key: 18,
        feature: '1 FREE report with membership',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 19,
        feature: '20% discount on reports for Annual Members',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 20,
        feature: 'Volume discounts',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 21,
        feature: 'Templates to subpoena or preserve cell phone records',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 22,
        feature: 'Report of most frequently called and calling-numbers',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 23,
        feature: 'Report of most frequently utilized CELL TOWERS, with addresses and map links',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 24,
        feature: 'Report of Internet usage with sent and received data volumes, durations and times',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 25,
        feature: 'Peer-reviewed cellphone impairment studies library',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 26,
        feature: 'Telecommunications terminology glossary',
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 27,
        feature: <><span>Litigation support <sup className='sup-color'>1</sup></span></>,
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 28,
        feature: <><span>Introduction to Cellphone Basics <sup className='sup-color'>*</sup></span></>,
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }, {
        key: 29,
        feature: <><span>User Name / cellphone number look-up service<sup className='sup-color'>*</sup></span></>,
        basicRPD: ' ',
        premiumRPD: ' ',
        annualRPD: <CheckOutlined className='tick-adjuster' />,
    }

]
export default list;