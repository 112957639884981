import { Layout } from "antd";
import "antd/dist/reset.css";
import { Content, Footer } from "antd/lib/layout/layout";
import React from "react";
import { Route, Routes } from "react-router-dom";
import LookUp from "../staticPages/lookup/lookup";
import UserReports from "../userReports/UserReports";
import "./style.scss";
import SelfServiceNew from "./selfServiceNew";
import HeaderNew from "../newscreens/HeaderNew";
import { Outlet } from "react-router-dom/dist";
import Navigation from "./navigation";

const SelfServiceUpdate = (props) => {
  return (
    <React.Fragment>
      <Outlet />
      <Layout className="self-service-layout">
        <div style={{ margin: "1px 1%" }}>
          <HeaderNew />

          <Navigation />
          <Content>
            <Routes>
              <Route index element={<SelfServiceNew />} />
              <Route exact path="reports" element={<UserReports />} />

              <Route exact path="lookup" element={<LookUp />} />
            </Routes>
          </Content>
        </div>
        <div className="footer-container">
          <Footer
            style={{
              textAlign: "center",

              width: "100%",
              left: "0",
              bottom: "0",
              zIndex: 1,
              background: "white",
              color: "black",
            }}
          >
            Copyright 2023 IoT Analytics, LLC.
          </Footer>
        </div>
      </Layout>
    </React.Fragment>
  );
};
export default SelfServiceUpdate;
