import React, { useState } from "react";
import { Route } from "react-router-dom";
import { AppProvider } from "../../contexts/app";
import Static from "../staticPages/staticRoutes";
import MainIndex from "../app/mainIndex";
import { Routes } from "react-router-dom";
import { Outlet, useNavigate } from "react-router-dom/dist";
import { initAxiosInterceptors } from "../../utils/axios-interceptors";

const Root = () => {
  const [language, setLanguage] = useState("en");
  const navigate = useNavigate();

  initAxiosInterceptors(navigate);
  return (
    <AppProvider value={{ language, setLanguage }}>
      <main className="cdr-analyzer-app">
        <div
          className="content"
          style={{ paddingTop: "0.1px", background: "white" }}
        >
          <Outlet />

          <Routes>
            <Route exact path="/*" element={<Static />} />
            <Route path="user/*" element={<MainIndex />} />
          </Routes>
        </div>
      </main>
    </AppProvider>
  );
};

export default Root;
