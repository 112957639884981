import { setCookie, getCookie, removeCookie } from "tiny-cookie";

export const getAuthToken = () => {
  //TDOD, have to get token from cookies
  // return "Basic ZmFoZWVtOnNwbDEyMw==";
  return localStorage.getItem("__CDR-Analyzer") || getCookie("__CDR-Analyzer");
};

export const setAuthToken = (token) => {
  setCookie("__CDR-Analyzer", token);
  localStorage.setItem("__CDR-Analyzer", token);
};

export const clearAuth = () => {
  removeCookie("__CDR-Analyzer");
  localStorage.removeItem("__CDR-Analyzer");
};
