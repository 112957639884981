import { Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import { saveSelfServiceData } from "../../actions/selfServiceEntityAction";
import { dispatch } from "../../store";
import "./ScreenFour.scss";

const ScreenFour = () => {
  const [reportType, setReportType] = useState("");

  useEffect(() => {
    if (reportType) {
      dispatch(saveSelfServiceData("reportType", reportType));
    }
  }, [reportType]);

  return (
    <React.Fragment>
      <div className="main-con-four m-top">
        <div className="content-cont-four">
          <div className="page-title ml">
            <div className="step-i mr-four num-btn num-btn-color">1</div>
            <span className="big-head-normal-four">
              <span className="big-head-spec-four">S</span>ELECT
              <span className="big-head-spec-four"> Y</span>OUR
              <span className="big-head-spec-four"> R</span>EPORT
            </span>
          </div>
          <Space className="select-container">
            <div className="div-cont">
              <div>
                <div className="heading">
                  <h1 className="icon-color report-font align-text">
                    <b>Basic</b>
                  </h1>
                  <h3 className="align-text report-txt-font">
                    Quickly assess potential case evidence
                  </h3>
                </div>
                <div className="content-card">
                  <h1 className="icon-color align-text top-margin">
                    <b>$175</b>
                  </h1>
                  <h4>
                    <br />
                  </h4>

                  <ul class="play-icon-list">
                    <li>Voice, Internet & Text Activities by Date and Time </li>
                    <li>Activities Shown in Local Time</li>
                    <li>
                      1<sup>st</sup> & Last Cell Tower Addresses per Activity
                    </li>
                    <li>Internet Data Usage Volumes</li>
                  </ul>
                </div>
                <div className="align-text">
                  <Button
                    className="btn-size"
                    size="large"
                    type="primary"
                    onClick={() => setReportType("basic")}
                  >
                    <h2>
                      <b>Select</b>
                    </h2>
                  </Button>
                </div>
              </div>
            </div>
            <div className="div-cont mr-left mrleft-20">
              <div>
                <div className="heading">
                  <h2 className="icon-color report-font align-text">
                    <b>Premium</b>
                  </h2>
                  <h3 className="align-text report-txt-font">
                    CellNinja flags key evidence for you
                  </h3>
                </div>
                <div className="content-card">
                  <h1 className="icon-color align-text top-margin">
                    <b>$250</b>
                  </h1>
                  <h4 className="align-text top-margin font">
                    <b>Basic Report Features PLUS</b>
                  </h4>

                  <ul class="play-icon-list">
                    <li>Highlighted Activities While Moving </li>
                    <li>Highlighted 911 calls</li>
                    <li>Highlighted Incident Time & Date</li>
                    <li>
                      <b>
                        1<sup>st</sup> & Last
                      </b>{" "}
                      Cell Tower Coverage Maps
                    </li>
                  </ul>
                </div>
                <div className="align-text">
                  <Button
                    className="btn-size"
                    size="large"
                    type="primary"
                    onClick={() => setReportType("premium")}
                  >
                    <h2>
                      <b>Select</b>
                    </h2>
                  </Button>
                </div>
              </div>
            </div>
            <div className="div-cont mr-left mrleft-zero">
              <div>
                <div className="heading">
                  <h2 className="icon-color report-font align-text">
                    <b>Pro</b>
                  </h2>
                  <h3 className="align-text report-txt-font">
                    For added perspective and exhibits
                  </h3>
                </div>
                <div className="content-card">
                  <h1 className="icon-color align-text top-margin">
                    <b>$325</b>
                  </h1>
                  <h4 className="align-text top-margin font">
                    <b>Premium Report Features PLUS</b>
                  </h4>
                  <ul class="play-icon-list">
                    <li>
                      <b>ALL</b> Cell Tower Coverage Maps
                    </li>
                    <li>
                      Customization: Enter names to display with phone numbers
                    </li>
                    <li>Driving (moving) Report</li>
                    <li>Frequent Phone Number Report</li>
                    <li>Internet Data Usage Report</li>
                    <li>Cell Tower Usage Report</li>
                  </ul>
                </div>
                <div className="align-text">
                  <Button
                    className="btn-size"
                    size="large"
                    type="primary"
                    onClick={() => setReportType("annual")}
                  >
                    <h2>
                      <b>Select</b>
                    </h2>
                  </Button>
                </div>
              </div>
            </div>
          </Space>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ScreenFour;
