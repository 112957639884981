import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const resetPasswordMailSend = (email) => {
    return axios.post(
        APP_CONTEXT_URL + "/v1/open/searchuser",
        [email],
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }
    );
};

export const chkTokenValidation = (token) => {
    return axios.post(
        APP_CONTEXT_URL + "/v1/open/tokenvalidation",
        [token],
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }
    );
};

export const resetUserPassword = (value, token) => {
    return axios.post(
        APP_CONTEXT_URL + "/v1/open/resetpassword/" + token,
        [value],
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
            },
        }
    );
};