import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const logout = () => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/logout",
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};
