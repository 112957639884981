/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowDropDownOutlined, MenuOutlined } from "@material-ui/icons";
import { Dropdown, Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { resetEntities } from "../../../actions/data";
import { saveUserData } from "../../../actions/user";
import { logout } from "../../../apis/logout";
import { dispatch } from "../../../store";
import { clearAuth, getAuthToken } from "../../../utils/authToken";
import "./NavigationBar.scss";
const NavigationBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [token, setToken] = useState(false);
  const expandhandler = () => {
    setIsNavExpanded(false);
  };

  useEffect(() => {
    if (getAuthToken()) setToken(true);
    else setToken(false);
  }, []);
  const onLogout = () => {
    logout();
    setToken(null);
    for (var k in window.localStorage) {
      if (k === "TERMLY_API_CACHE") continue;

      delete window.localStorage[k];
    }
    clearAuth();
    dispatch(resetEntities());
    dispatch(saveUserData());
    navigate("/");
  };

  const menu = () => (
    <Menu onClick={(event) => hightOptionAndRouting(null, event.key)}>
      <Menu.Item key={"/accurate"}>
        <Link className="menu-item-for-products" to={"/accurate"}>
          ACCURATE
        </Link>
      </Menu.Item>
      <Menu.Item key={"/easy"}>
        <Link className="menu-item-for-products" to={"/easy"}>
          EASY
        </Link>
      </Menu.Item>
      <Menu.Item key={"/fast"}>
        <Link className="menu-item-for-products" to={"/fast"}>
          FAST
        </Link>
      </Menu.Item>
      <Menu.Item key={"/secure"}>
        <Link className="menu-item-for-products" to={"/secure"}>
          SECURE
        </Link>
      </Menu.Item>
      <Menu.Item key={"/how-it-works"}>
        <Link className="menu-item-for-products" to={"/how-it-works"}>
          HOW IT WORKS
        </Link>
      </Menu.Item>
      <Menu.Item key={"/call-detail-records"}>
        <Link className="menu-item-for-products" to={"/call-detail-records"}>
          ABOUT CALL RECORDS
        </Link>
      </Menu.Item>
    </Menu>
  );
  const hightOptionAndRouting = (event, route) => {
    navigate(route);
    if (document.querySelector(".active"))
      document.querySelector(".active").classList.remove("active");
    if (event && event.currentTarget)
      event.currentTarget.classList.add("active");
    else if (route) {
      // eslint-disable-next-line default-case
      switch (route) {
        case "/home":
          document.getElementById("homeRoute").classList.add("active");
          break;
        case "/accurate":
          document.getElementById("productRoute").classList.add("active");
          break;
        case "/aboutus":
          document.getElementById("aboutusRoute").classList.add("active");
          break;
        case "/contactus":
          document.getElementById("contactusRoute").classList.add("active");
          break;
        case "/create-account":
          document.getElementById("createAccountRoute").classList.add("active");
          break;
        case "/subscribe":
          document.getElementById("subscribeRoute").classList.add("active");
          break;
      }
    }
    expandhandler();
  };
  const checkForToken = () => {
    if (!getAuthToken()) {
      onLogout();
    }
  };
  return (
    <React.Fragment>
      <div className="nav-adjuster">
        <div className="new-con-for-nav">
          <nav className="navigation">
            <div className="hamburger">
              <MenuOutlined
                style={{ fontSize: "2.5rem" }}
                onClick={() => {
                  setIsNavExpanded(!isNavExpanded);
                }}
              />
            </div>
            <div
              className={
                isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
              }
            >
              <ul className="ul-for-nav">
                {location?.pathname !== "/" &&
                location?.pathname !== "/home" ? (
                  <li
                    id="homeRoute"
                    className="nav-list-items"
                    onClick={(e) => hightOptionAndRouting(null, "/")}
                  >
                    <Link
                      className="color-adjuster-for-navbar"
                      onClick={() => expandhandler()}
                      to={"/"}
                    >
                      HOME
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li
                  id="aboutusRoute"
                  onClick={(e) => hightOptionAndRouting(e, "/aboutus")}
                  className="nav-list-items"
                >
                  <Link className="color-adjuster-for-navbar" to={"/aboutus"}>
                    ABOUT
                  </Link>
                </li>
                <li id="productRoute" className="nav-list-items">
                  <Dropdown
                    className="color-adjuster-for-navbar"
                    style={{ marginTop: "10%" }}
                    overlay={menu}
                    placement="bottomLeft"
                  >
                    <a
                      className="ant-dropdown-link new-allignment"
                      onClick={(e) => e.preventDefault()}
                    >
                      PRODUCT <ArrowDropDownOutlined />
                    </a>
                  </Dropdown>
                </li>
                {/* <li
                  id="subscribeRoute"
                  onClick={(e) => hightOptionAndRouting(e, "/subscribe")}
                  className="nav-list-items"
                >
                  <Link className="color-adjuster-for-navbar" to={"/subscribe"}>
                    PLANS
                  </Link>
                </li> */}
                <li
                  id="contactusRoute"
                  onClick={(e) => hightOptionAndRouting(e, "/contactus")}
                  className="nav-list-items"
                >
                  <Link className="color-adjuster-for-navbar" to={"/contactus"}>
                    CONTACT
                  </Link>
                </li>
                {token ? (
                  <>
                    <li
                      className="nav-list-items"
                      onClick={(e) =>
                        (window.location.href = "/user/selfservice")
                      }
                    >
                      <a
                        className="color-adjuster-for-navbar"
                        href="/user/selfservice"
                        onClick={checkForToken}
                      >
                        ACCOUNT
                        {(e) => hightOptionAndRouting(e, "/user/login")}
                      </a>
                    </li>
                    <li className="nav-list-items" onClick={onLogout}>
                      <a
                        className="color-adjuster-for-navbar"
                        onClick={onLogout}
                      >
                        LOGOUT
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li
                      id="createAccountRoute"
                      onClick={(e) =>
                        hightOptionAndRouting(e, "/create-account")
                      }
                      className="nav-list-items"
                    >
                      <Link
                        className="color-adjuster-for-navbar"
                        to={"/create-account"}
                      >
                        REGISTER
                      </Link>
                    </li>
                    <li
                      className="nav-list-items"
                      onClick={(e) => (window.location.href = "/user/login")}
                    >
                      <a
                        className="color-adjuster-for-navbar"
                        href="/user/login"
                      >
                        LOGIN
                      </a>
                    </li>
                  </>
                )}
                {/* <Link
                                {/* <span id="siteseal"
                  style={{ position: "absolute", top: "26px", right: "0", zIndex: "5" }}
                >
                  <script async type="text/javascript"
                    src="https://seal.godaddy.com/getSeal?sealID=fKSYGbDITb0zCSYN284A5MsIRjhz9BSIndbQgUZ4frbsliQfRAznElBva3Eb"></script>
                </span> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};
export default NavigationBar;
