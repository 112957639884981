import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const sendErrorMail = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/sendErrorMail",
    { uniqueId: id },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const sendMailWithAttachment = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/sendErrormailAttachment",
    { uniqueId: id },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const mailPaymentReceipt = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/paymentReceipt/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const mailErrorReport = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/mailerrorreport/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const sendContactUsMail = (values) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/contactus",
    values,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};
