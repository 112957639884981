/**
 *
 *   APPLICATION CONSTANTS
 *
 */

export const APP_CONTEXT_URL = "/cdr";
export const SET_LOADING = "SET_LOADING";
export const RESET_LOADING = "RESET_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOAD_CURRENT_USER = "SET_CURRENT_USER";

export const SET_MESSAGE = "SET_MESSAGE";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";

export const SET_SELFSERVICE = "SET_SELFSERVICE";
export const RESET_SELFSERVICE = "RESET_SELFSERVICE";

/** CONTRIES Constants */
export const GET_CONTRIES = "GET_CONTRIES";
export const SET_CONTRY = "SET_CONTRY";

export const GET_ENTITIES = "GET_ENTITIES";
export const SET_ENTITY = "SET_ENTITY";
export const RESET_ENTITIES = "RESET_ENTITIES";

/** DATE Format Constants */
export const SWIFTPAC_DATETIME_FORMAT = "YYYY-MM-DD hh:mm:ss";
export const SWIFTPAC_DATE_FORMAT = "YYYY-MM-DD";
export const SWIFTPAC_TIME_FORMAT = "HH:mm:ss";

/** Regex Alphabatic Inputs */
export const ALPHABETICAL_INPUTS = "^[a-zA-Z ]+$";

//** Regex Numeric Inputs */
export const NUMERIC_INPUTS = "^[0-9]+$";

/* Date Regex for Customize Your Report */
export const DATE_REGEX = new RegExp(
  /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})$/
);

/* Time Regex for Customize Your Report */
export const TIME_REGEX = new RegExp(
  /^(0?[1-9]|1[0-2]):[0-5][0-9]\s?([AaPp][Mm])$/
);
/* Lat Lng for Customize Your Report */
export const LATLNG_REGEX = new RegExp(
  /^\s*(?:-?\d{1,2}(?:\.\d+)?,\s*-?\d{1,3}(?:\.\d+)?\s*|[^,]+(?:, [^,]+)*)?$/
);

export const STRIPE_API_KEY_TEST = "pk_test_JGrCX5KZb6skvTePKwzSuYdR00Vmd6BwsS";
export const STRIPE_API_KEY_PRODUCTION =
  "pk_live_wu8cYXtwTWvbg0PVwsGmtLEd00jGagahzF";

export const GOOGLE_TAG_MANAGER_ID = "AW-11481537949";

export const CELL_NINJA_HOST_NAME = "cell-ninja.com";

export const CELLNINJACOLOR = "#e4003b";

export const CELL_NINJA = "q16iov25luzxh5g8402zwomrk85t11x";

export const BETA_USER_TYPE = "BetaUser";

export const BASIC_USER_TYPE = "Basic";

export const PREMIUM_USER_TYPE = "Premium";

export const ANNUAL_USER_TYPE = "Annual";

export const HOME_TAB_ACCESS = "HOME_TAB_ACCESS";

export const VIEW_ALL_PROJECTS = "VIEW_ALL_PROJECTS";

export const VIEW_SSR_TABLE = "VIEW_SSR_TABLE";

export const VIEW_MOBILE_META_TABLE = "VIEW_MOBILE_META_TABLE";

export const VIEW_SETTINGS = "VIEW_SETTINGS";

export const IMPORT_CDR_FILE = "IMPORT_CDR_FILE";

export const DOWNLOAD_FINAL_REPORT_PDF = "DOWNLOAD_FINAL_REPORT_PDF";

export const DOWNLOAD_FINAL_REPORT_EXCEL = "DOWNLOAD_FINAL_REPORT_EXCEL";

export const VIEW_UNIQUE_PHONE_NUMBER = "VIEW_UNIQUE_PHONE_NUMBER";

export const VIEW_UNIQUE_TOWER_LOCATION = "VIEW_UNIQUE_TOWER_LOCATION";

export const DELETE_PROJECT = "DELETE_PROJECT";

export const VIEW_USER = "VIEW_USER";

export const ADD_USER = "ADD_USER";

export const UPDATE_USER = "UPDATE_USER";

export const DELETE_USER = "DELETE_USER";

export const VIEW_ROLE = "VIEW_ROLE";

export const ADD_ROLE = "ADD_ROLE";

export const UPDATE_ROLE = "UPDATE_ROLE";

export const DELETE_ROLE = "DELETE_ROLE";

export const VIEW_PERMISSION = "VIEW_PERMISSION";

export const ADD_PERMISSION = "ADD_PERMISSION";

export const UPDATE_PERMISSION = "UPDATE_PERMISSION";

export const DELETE_PERMISSION = "DELETE_PERMISSION";
