import { getCustomerMessages } from "../apis/customermessages";
import request from "../service/index";
import { APP_CONTEXT_URL, SET_CURRENT_USER } from "../utils/constants";
import { setCustomerMessage } from "./app";

/* USER ACTIONS */

export const registerUser = (values, navigate) => {
  request({
    method: "POST",
    url: APP_CONTEXT_URL + "/v1/register",
    data: values,
    loadingText: "Please Wait...",
  }).then(() => {
    navigate("/login");
  });
};
export const loginUser = (values, navigate, props) => {
  return (dispatch) => {
    request({
      method: "POST",
      url: APP_CONTEXT_URL + "/v1/login",
      data: values,
      loadingText: "Logging You In...",
    })
      .then((res) => {
        if (res.credentialsNonExpired === true) {
          dispatch(saveUserData(res));
          let { from } = {
            from: {
              pathname: "/user/selfservice",
              // "/home/projects"
            },
          };
          navigate(from);
          props.authStatus();
        } else {
          let { from } = {
            from: {
              pathname:
                // "/login"
                "/user/login",
            },
          };
          navigate(from);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const loadUserInfo = () => {
  return (dispatch) => {
    request({
      method: "POST",
      url: APP_CONTEXT_URL + "/v1/user",
      loadingText: "Loading User Info...",
    }).then((res) => {
      dispatch(saveUserData(res));
      const response = getCustomerMessages();
      setCustomerMessage(response.data);
    });
  };
};
export const saveUserData = (userInfo) => {
  return {
    type: SET_CURRENT_USER,
    payload: userInfo,
  };
};
