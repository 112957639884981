import React from "react";
import "./ScreenSix.scss";
import StripComponent from "../stripe/App";
const ScreenSix = () => {
  return (
    <div className="main-con-six m-top">
      <div className="content-cont-six">
        {/* <div className="vol-I"> */}
        <div className="step-i mr-six num-btn num-btn-color">4</div>
        {/* </div> */}
        <div className="cont-arr-six">
          <div className="vol-II-six">
            <span className="big-head-normal-six">
              <span className="big-head-spec-six">P</span>AY AND
              <span className="big-head-spec-six"> D</span>OWNLOAD
              <span className="big-head-spec-six"> Y</span>OUR
              <span className="big-head-spec-six"> R</span>EPORT
              <span className="big-head-spec-six"> !</span>
            </span>
          </div>
          <div className="main-for-six">
            {/* <div className="leftie-six">
              <span className="span-six">
                You can re-run your prior call reports from your CellNinja
                dashboard at no charge for up to 30 days.
              </span>
            </div> */}
            <div className="rightie-six">
              <StripComponent onPaymentDone={() => {}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenSix;
