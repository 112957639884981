
import {SET_SELFSERVICE, RESET_SELFSERVICE} from "../utils/constants"

export const saveSelfServiceData = (key, value) => {
    return {
      type: SET_SELFSERVICE,
      payload: {
        key,
        value,
      },
    };
  };

  export const resetSelfService = () => {
    return {
      type: RESET_SELFSERVICE,
    };
  };