import { Button, Table, Input, Form } from "antd";
import "./style.scss";
import React, { useEffect, useState } from "react";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
// import "antd/dist/antd.css";
import {
  getUniqiuePhoneNumbers,
  updateprojectUpn,
} from "../../apis/projectapi";
import { useLocation } from "react-router-dom";
import { checkToken } from "../../functions/functions";
import { useNavigate } from "react-router-dom/dist";

const ProjectInfo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [editingKey, setEditingKey] = useState("");

  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const loadUPN = () => {
    if (location?.state?.projectId) {
      getUniqiuePhoneNumbers(location?.state?.projectId).then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    loadUPN();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const isEditing = (record) => record.id === editingKey;

  const columns = [
    {
      title: "Phone",
      dataIndex: "number",
      key: "number",
      width: "20%",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "40%",
      align: "center",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      align: "center",
      width: "15%",
      render: (_, record) => {
        const editable = isEditing(record);
        // setEditable(isEditing(record));
        return editable ? (
          <div>
            <Button
              title="Save"
              style={{ marginRight: "5px" }}
              shape="round"
              icon={<SaveOutlined />}
              onClick={() => saveData(record)}
            ></Button>
            <Button
              title="Cancel"
              shape="round"
              onClick={() => cancelEditing()}
              icon={<StopOutlined />}
            ></Button>
          </div>
        ) : (
          <Button
            title="Edit"
            shape="round"
            disabled={editingKey !== ""}
            onClick={() => editPhoneData(record)}
            icon={<EditOutlined />}
          ></Button>
        );
      },
    },
  ];

  const mergedColumnsForReportTb = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const editPhoneData = (record) => {
    form.setFieldsValue({
      id: record.id,
      name: record.name,
      number: record.number,
    });
    setEditingKey(record.id);
  };

  const saveData = (record) => {
    form.validateFields().then(async (values) => {
      const { name } = values;
      let newValues = {
        id: record.id !== "" ? record.id : null,
        name: name,
        number: record.number,
        projectId: {
          id: record.projectId,
        },
      };

      const response = await updateprojectUpn(newValues);
      if (response.status === 200) {
        setEditingKey("");
        loadUPN();
      }
    });
  };

  const cancelEditing = () => {
    setEditingKey("");
  };

  const editableCell = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: `Please Input ${title}!`,
            //   },
            // ]}
          >
            <Input style={{ width: "50%" }} />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex" }}>
          <ArrowLeftOutlined
            style={{
              marginTop: "5px",
              marginRight: "10px",
            }}
            onClick={() => navigate("/user/home/projects")}
          />
          <h1>Unique Phone Numbers</h1>
        </div>
      </div>
      <Form component={false} name="" form={form}>
        <Table
          scroll={{ y: "67vh" }}
          loading={loading}
          style={{ margin: "0.5% 0%" }}
          bordered
          className="admin-table-container"
          rowKey={"id"}
          rowClassName="editable-row"
          components={{
            body: {
              cell: editableCell,
            },
          }}
          key="1"
          size="middle"
          columns={mergedColumnsForReportTb}
          dataSource={data}
          pagination={false}
        />
      </Form>
    </React.Fragment>
  );
};

export default ProjectInfo;
