import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom/dist";
import { setCustomerMessage } from "../../actions/app";
import { resetEntities } from "../../actions/data";
import { getCustomerMessages } from "../../apis/customermessages";
import { logout } from "../../apis/logout";
import { AppProvider } from "../../contexts/app";
import {
  clearSessionIdentifier,
  getSessionIdentifier,
  setSessionIdentifier,
} from "../../utils/sessionIdentifier";
import doSessionManagement from "../../utils/sessionManager";
import SelfServiceUpdate from "../selfServiceNew/selfServiceUpdate";
import { loadUserInfo, saveUserData } from "./../../actions/user";
import { clearAuth, getAuthToken } from "./../../utils/authToken";
import Home from "./../home";
import LoginUser from "./../login/login";

/////////////// export customer messages from redux ///////////////

export function fetchCustomerMessages() {
  return Messages();
}
const Messages = () => {
  // const { messages = {} } = useSelector(({ app }) => app); // redux
  const messages = JSON.parse(localStorage.getItem("customerMessages")); // local storage
  return messages;
};

/////////////// export customer messages from redux ///////////////

const Main = () => {
  const [token, setToken] = useState(getAuthToken() ? getAuthToken() : null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [language, setLanguage] = useState("en");
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [sessionManage, setSessionManage] = useState(true);

  const authStatus = () => {
    navigate("/user/selfservice");
    setToken(getAuthToken());
  };

  const onLogout = () => {
    logout();
    setToken(null);
    for (var k in window.localStorage) {
      if (k === "TERMLY_API_CACHE") continue;
      if (k === "customerMessages") continue;
      delete window.localStorage[k];
    }
    clearAuth();
    dispatch(resetEntities());
    dispatch(saveUserData());
  };

  const setMessagesForCustomer = () => {
    getCustomerMessages().then((response) => {
      setCustomerMessage(response.data.object);
    });
  };

  useEffect(() => {
    if (currentUser) {
      localStorage.setItem("user", JSON.stringify(currentUser));
      setMessagesForCustomer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      dispatch(loadUserInfo());
      setSessionIdentifier(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const token = getAuthToken();
    if (!getSessionIdentifier()) {
      clearSessionIdentifier();
    }

    if (token && sessionManage && !getSessionIdentifier()) {
      doSessionManagement(token);
      setSessionManage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppProvider value={{ language, setLanguage }}>
      <main className="cdr-analyzer-app">
        <div
          className="content"
          style={{ paddingTop: "0.1px", background: "white" }}
        >
          <Outlet />
          <Routes>
            <Route
              exact
              path="login"
              element={<LoginUser history={navigate} authStatus={authStatus} />}
            />
            <Route
              path="selfservice/*"
              element={
                <SelfServiceUpdate
                  onLogout={onLogout}
                  jwtToken={false}
                  token={token}
                />
              }
            />
            <Route
              path="home/*"
              element={<Home onLogout={onLogout} token={token} />}
            />
          </Routes>
        </div>
      </main>
    </AppProvider>
  );
};

export default Main;
