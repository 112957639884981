import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  EyeTwoTone,
  FileExcelTwoTone,
  FilePdfTwoTone,
  FileTextTwoTone,
  FilterOutlined,
  MailTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Pagination,
  Popover,
  Spin,
  Table,
  Tooltip,
  Modal,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { mailPaymentReceipt } from "../../apis/mailapi";
import {
  downlaodFile,
  downlaodInputFile,
  generateExcel,
} from "../../apis/projectapi";
import {
  getAllSSR,
  getFile,
  getSSRCount,
  getSSRFilterCount,
  getSSRFilterData,
} from "../../apis/ssr";
import { checkToken, downloadedFile } from "../../functions/functions";
import "./style.scss";
import { CELLNINJACOLOR } from "../../utils/constants";
import DOWNLOADICON from "../../images/downloading-icon-cloud.png";
import { fetchCustomerMessages } from "../app/mainIndex";

// This file is for Administration > SSR flow.
const SelfServiceRequest = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [genratingFile, setGenratingFile] = useState(false);
  const [filter, setFilter] = useState(false);
  const [searchFile, setSearchFile] = useState(false);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [formValues, setFormValues] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [ssrCount, setSSRCount] = useState();
  const [sortBy, setSortBy] = useState("date");
  const [orderBy, setOrderBy] = useState();
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadCompletionModal, setDownloadCompletionModal] = useState(false);

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    loadSSRCount();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const loadSSRCount = (
    pageNumber = 1,
    recordsPerPage = 10,
    sortBy = "creationDate",
    orderBy = "descend"
  ) => {
    setLoading(true);
    getSSRCount().then((res) => {
      if (res.status === 200) {
        setSSRCount(res.data);
        loadSSRRecords(pageNumber, recordsPerPage, sortBy, orderBy);
      }
    });
  };

  const loadSSRRecords = (current, pageSize, sortBy, orderBy) => {
    getAllSSR(current, pageSize, sortBy, orderBy)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onShowSizeChange = (pageNumber, recordsPerPage) => {
    setPageNumber(pageNumber);
    setRecordsPerPage(recordsPerPage);
    loadSSRRecords(pageNumber, recordsPerPage, sortBy, orderBy);
    if (formValues === undefined)
      loadSSRRecords(pageNumber, recordsPerPage, sortBy, orderBy);
    else onSearch(pageNumber, recordsPerPage, formValues);
  };

  const downloadInputFile = (record) => {
    let fileName = record.fileName;
    downlaodInputFile(record.uniqueId)
      .then((response) => {
        if (response.status === 200) {
          downloadedFile(response, fileName);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const generateExcelReport = (record) => {
    setGenratingFile(true);
    let pos = record.fileName.lastIndexOf(".");
    var fileName =
      record.fileName.substr(0, pos < 0 ? record.fileName.length : pos) +
      ".xlsx";

    generateExcel(record.uniqueId)
      .then((response) => {
        if (response.status === 200) {
          downloadedFile(response, fileName);
          setGenratingFile(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setGenratingFile(false);
      });
  };

  const generateFinalReport = (record) => {
    let name = record.outputPath;
    const index = name.indexOf(
      "_",
      name.indexOf("_", name.indexOf("_") + 1) + 1
    );
    name = name.substring(index + 1);
    setGenratingFile(true);
    if (record) {
      setDownloadModal(true);
      downlaodFile(record.uniqueId, false)
        .then((response) => {
          if (response.status === 200) {
            downloadedFile(response, "Final Report_" + name);
            setGenratingFile(false);
          }
          setGenratingFile(false);
        })
        .catch((error) => {
          console.log("error", error);
          setGenratingFile(false);
        })
        .finally(() => {
          setDownloadModal(false);
          setDownloadCompletionModal(true);
        });
    }
  };

  const sendMail = (record) => {
    mailPaymentReceipt(record.uniqueId)
      .then((response) => {
        if (response.status === 200) {
          message.success(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFilterClear = () => {
    setFormValues("");
    form.resetFields();
    loadSSRCount();
  };

  const onDataFilter = (values) => {
    setFormValues(values);
    onSearch(pageNumber, recordsPerPage, values);
  };

  const onSearch = (page, pageSize, values) => {
    getSSRFilterCount(
      values.success,
      values.failed,
      values.paid,
      values.unPaid
    ).then((res) => {
      if (res.status === 200) {
        setLoading(true);
        setSSRCount(res.data);
        getSSRFilterData(
          page,
          pageSize,
          values.success,
          values.failed,
          values.paid,
          values.unPaid
        ).then((res) => {
          if (res.status === 200) {
            setData(res.data);
            setLoading(false);
          }
        });
      }
    });
  };

  const searchRecord = (file) => {
    setLoading(true);
    getFile(file).then((res) => {
      if (res.status === 200) {
        setSSRCount(res.data.length);
        setLoading(false);
        setData(res.data);
      }
    });
  };

  const content = (record) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          padding: "12px 16px",
        }}
      >
        <div>
          <Tooltip title="Download Input File" placement="left">
            <Button
              style={{ marginRight: "5px" }}
              shape="round"
              className="ant-button"
              icon={<FileTextTwoTone style={{ fontSize: "20px" }} />}
              onClick={(e) => {
                downloadInputFile(record);
                e.stopPropagation();
              }}
            ></Button>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Download Excel Report" placement="left">
            <Button
              style={{ marginRight: "5px" }}
              shape="round"
              className="ant-button"
              icon={<FileExcelTwoTone style={{ fontSize: "20px" }} />}
              onClick={(e) => {
                generateExcelReport(record);
                e.stopPropagation();
              }}
            ></Button>
          </Tooltip>
        </div>

        <div>
          <Tooltip title="Download Final Report" placement="left">
            <Button
              style={{
                marginRight: "5px",
                marginBottom: "2px",
              }}
              className="ant-button"
              icon={<FilePdfTwoTone style={{ fontSize: "20px" }} />}
              shape="round"
              onClick={(e) => {
                generateFinalReport(record);
                e.stopPropagation();
              }}
            ></Button>
          </Tooltip>
        </div>

        <div hidden={!record.hasError}>
          <Tooltip title="Send Mail" placement="left">
            <Button
              style={{
                marginRight: "5px",
                marginBottom: "2px",
              }}
              className="ant-button"
              icon={<MailTwoTone style={{ fontSize: "20px" }} />}
              shape="round"
              disabled={true}
              onClick={(e) => {
                sendMail(record);
                e.stopPropagation();
              }}
            ></Button>
          </Tooltip>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "Request Id",
      dataIndex: "uniqueId",
      key: "uniqueId",
      width: "11%",
      align: "center",
      render: (uniqueId) => (
        <React.Fragment>{uniqueId ? uniqueId : "-"}</React.Fragment>
      ),
      // sorter: (a, b) => {
      //   setOrderBy(!orderBy);
      // },
    },
    {
      title: "Username",
      dataIndex: "userName",
      width: "6.2%",
      align: "center",
      render: (_, record) => {
        return record?.userId?.username;
      },
    },
    {
      title: "File Size (kb)",
      dataIndex: "fileSize",
      width: "5.3%",
      key: "fileSize",
      align: "center",
      sorter: {},
      render: (fileSize) => (fileSize ? `${(fileSize / 1024).toFixed(0)}` : ""),
    },
    {
      title: "Run Date (UTC)",
      dataIndex: "creationDate",
      width: "8.3%",
      align: "center",
      sorter: {},
      render: (creationDate) => (
        <React.Fragment>
          {creationDate ? moment(creationDate).format("MM/DD/YYYY HH:mm") : ""}
        </React.Fragment>
      ),
    },
    {
      title: "Processing Time (s)",
      dataIndex: "processingTime",
      key: "processingTime",
      width: "6%",
      align: "center",
      sorter: {},
      render: (processingTime) => (
        <React.Fragment>
          {processingTime
            ? `${moment.duration(processingTime).asSeconds().toFixed(0)}`
            : ""}
        </React.Fragment>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      sorter: {},
      // width: "15%",
      render: (email) => <React.Fragment>{email ? email : "-"}</React.Fragment>,
    },
    {
      title: "User File",
      dataIndex: "fileName",
      key: "fileName",
      align: "center",
      sorter: {},
      // width: "25%",
      render: (fileName) => (
        <React.Fragment>{fileName ? fileName : "-"}</React.Fragment>
      ),
    },

    {
      title: "Final Report",
      dataIndex: "outputPath",
      key: "outputPath",
      align: "center",
      width: "25%",
      render: (outputPath) => {
        if (outputPath) {
          let file = outputPath.split("/").pop();
          return <React.Fragment>{file ? file : ""}</React.Fragment>;
        }
      },
    },
    {
      title: "Error",
      dataIndex: "hasError",
      key: "hasError",
      align: "center",
      width: "3%",
      render: (hasError) => (
        <React.Fragment>
          {hasError ? (
            <ExclamationCircleOutlined
              style={{ color: "red", fontSize: "17px" }}
            />
          ) : (
            ""
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Paid",
      dataIndex: "isPaid",
      key: "isPaid",
      align: "center",
      width: "3%",
      render: (isPaid) => (
        <React.Fragment>
          {isPaid ? (
            <CheckCircleOutlined style={{ color: "green", fontSize: "17px" }} />
          ) : (
            ""
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Receipt",
      dataIndex: "receiptUrl",
      key: "receiptUrl",
      align: "center",
      width: "4%",
      render: (receiptUrl) => {
        if (receiptUrl) {
          return (
            <React.Fragment>
              <Tooltip title="View Receipt">
                <Link to={{ pathname: receiptUrl }} target="_blank">
                  <EyeTwoTone style={{ fontSize: "17px" }} />
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        }
      },
    },
    {
      title: "Action",
      width: "4%",
      align: "center",
      render: (_, record) => {
        return (
          <Popover placement="left" content={() => content(record)}>
            <Button
              shape="round"
              className="ant-button"
              onClick={(e) => e.stopPropagation()}
            >
              <MoreVertIcon style={{ width: "16px" }} />
            </Button>
          </Popover>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            marginBottom: "0.5rem",
            marginRight: "1rem",
          }}
        >
          <div style={{ textAlign: "left", display: "flex" }}>
            <h1>Self Service Request</h1>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
              marginLeft: "auto",
            }}
          >
            <div>
              <Button
                title="Search File"
                shape="round"
                icon={<SearchOutlined />}
                onClick={() => setSearchFile(!searchFile)}
              />
            </div>
            <div>
              <Button
                title="Filter"
                shape="round"
                icon={<FilterOutlined />}
                onClick={() => setFilter(!filter)}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {searchFile ? (
            <div>
              <Form
                name="file"
                form={form}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Form.Item name="file">
                  <Input
                    className="ssr-search-text-field"
                    size="middle"
                    style={{ width: "400px" }}
                    placeholder="Enter File Name"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    style={{
                      backgroundColor: "#2980b9",
                      borderColor: "#2980b9",
                      color: "white",
                    }}
                    icon={<SearchOutlined />}
                    type="danger"
                    onClick={() => {
                      form
                        .validateFields()
                        .then((res) => {
                          searchRecord(res.file);
                        })
                        .catch();
                    }}
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    onClick={() => {
                      form.resetFields();
                      loadSSRCount();
                    }}
                    shape="round"
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            ""
          )}

          {filter ? (
            <div>
              <Form
                form={form}
                initialValues={{
                  remember: true,
                }}
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Form.Item name="success" valuePropName="checked">
                  <Checkbox>Success Job</Checkbox>
                </Form.Item>
                <Form.Item name="failed" valuePropName="checked">
                  <Checkbox>Failed Jobs</Checkbox>
                </Form.Item>
                <Form.Item name="paid" valuePropName="checked">
                  <Checkbox>Paid Jobs</Checkbox>
                </Form.Item>
                <Form.Item name="unPaid" valuePropName="checked">
                  <Checkbox>Unpaid Jobs</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="danger"
                    style={{
                      backgroundColor: "#2980b9",
                      borderColor: "#2980b9",
                      color: "white",
                    }}
                    onClick={() => {
                      form.validateFields().then((res) => {
                        onDataFilter(res);
                      });
                    }}
                    shape="round"
                  >
                    Apply
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button onClick={() => onFilterClear()} shape="round">
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            ""
          )}
        </div>

        <div>
          <Modal
            open={downloadModal}
            width={500}
            footer={null}
            closable={false}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img
                alt="downloadicon"
                src={DOWNLOADICON}
                height={80}
                width={80}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{"Your file is downloading..."}</h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setDownloadModal(false);
                }}
              >
                OK
              </Button>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            open={downloadCompletionModal}
            width={500}
            footer={null}
            closable={false}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img
                alt="downloadicon"
                src={DOWNLOADICON}
                height={80}
                width={80}
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{"Downloading Completed..."}</h2>
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <br />
              <Button
                style={{
                  width: "120px",
                  height: "40px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setDownloadCompletionModal(false);
                }}
              >
                OK
              </Button>
            </div>
          </Modal>
        </div>

        <Form component={false} name="">
          <Spin
            style={{ color: CELLNINJACOLOR }}
            spinning={genratingFile}
            tip="Generating File..."
            delay={200}
          >
            <Table
              scroll={{
                x: "min-content",
                y: 300,
              }}
              style={{
                height: "50vh",
                width: "auto",
                overflow: "auto",
              }}
              bordered
              loading={loading}
              columns={columns}
              dataSource={data}
              pagination={false}
              onChange={(pagination, filters, sorter, extra) => {
                setPageNumber(1);
                setOrderBy(sorter.order);
                setSortBy(sorter.field);
                loadSSRCount(1, 10, sorter.field, sorter.order);
              }}
            />
          </Spin>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "1rem",
            }}
          >
            <Pagination
              current={pageNumber}
              total={ssrCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} Records`
              }
              showSizeChanger={true}
              onChange={onShowSizeChange}
            />
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default SelfServiceRequest;
