export const downloadedFile = (response, fileName) => {
  //Create a Blob from the PDF Stream
  // var fileName = response.headers["content-disposition"].split("filename=")[1];
  var url = window.URL.createObjectURL(new Blob([response.data]));
  var link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName.trim());
  link.click();
};

export const downloadFile = (response) => {
  //Create a Blob from the PDF Stream
  var fileName = response.headers["content-disposition"].split("filename=")[1];
  var url = window.URL.createObjectURL(new Blob([response.data]));
  var link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName.trim());
  link.click();
};

export const checkToken = (navigate) => {
  if (
    (!localStorage.getItem("user") || localStorage.getItem("user") === {}) &&
    !localStorage.getItem("__CDR-Analyzer")
  ) {
    navigate("/user/login");
  }
};
