import React, { useEffect } from "react";
import "./subscribe.scss";
import { Table, Typography } from "antd";
import list from "./listOfSub";

const Subscribe = (props) => {

  useEffect(() => {
    if (document.querySelector(".active")) {
      document.querySelector(".active").classList.remove("active");
      document.getElementById("subscribeRoute").classList.add("active");
    }
  }, []);

  const columns = [
    {
      title: "Feature",
      dataIndex: "feature",
      key: "feature",
      width: "70%",
      align: "center",
      render: (feature) => (
        <>
          <Typography.Text className="feature-col">{feature}</Typography.Text>
        </>
      ),
    },
    {
      title: (
        <>
          <Typography.Text className="title-style">
            Basic Reports
          </Typography.Text>
          <br />
          <Typography.Text className="subtitle-style">
            Pay per report
          </Typography.Text>
        </>
      ),
      dataIndex: "basicRPD",
      key: "basicRPD",
      align: "center",
    },
    {
      title: (
        <>
          <Typography.Text className="title-style">
            Premium Reports
          </Typography.Text>
          <br />
          <Typography.Text className="subtitle-style">
            Pay per report
          </Typography.Text>
        </>
      ),
      dataIndex: "premiumRPD",
      key: "premiumRPD",
      align: "center",
    },
    {
      title: (
        <>
          <Typography.Text className="title-style">
            Annual Member
          </Typography.Text>
          <br />
          <Typography.Text className="subtitle-style">
            Pay per report
          </Typography.Text>
        </>
      ),
      dataIndex: "annualRPD",
      key: "annualRPD",
      align: "center",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-con-for-subs">
      <div className="sub-con-for-sub">
        <div className="headline-con">
          <span className="b-text">On-Demand and Member </span>
          <span className="r-text">
            Plans <br />
          </span>
          <span className="rs-text">Cellphone Evidence Simplified™</span>
        </div>
        <div className="container-of-tabol">
          <Table
            rowClassName={(record, index) =>
              record.key === 17 ? "mid-row" : ""
            }
            columns={columns}
            dataSource={list}
            pagination={false}
          />
        </div>
        <div className="main-for-mob-view">
          <div className="first-for-mob">
            <h2 className="basic-report">Basic Reports</h2>
            <span className="pay-span">Pay per report</span>
            <div className="lineformob"></div>
            <ul className="outer-list">
              <li>Easy-to-read CellNinja Reports</li>
              <ul className="inner-list">
                <li>
                  Details of voice calls, Internet use, and text messaging by
                  date and time
                </li>
                <li>All activities in local times</li>
                <li>General location and movement of the target cell phone</li>
                <li>Internet usage details</li>
              </ul>
              <li>Cellphone companies' subpoena contacts</li>
              <li>Report certification letter</li>
              <li>
                Federal Motor Carrier Safety Administration (FMCSA) cell phone
                use rules
              </li>
            </ul>
          </div>
          <div className="first-for-mob">
            <h2 className="basic-report">Premium Reports</h2>
            <span className="pay-span">Pay per report</span>
            <span className="span-red">
              Includes all the of basic report features plus
            </span>
            <div className="lineformob"></div>
            <ul className="outer-list">
              <li>Maps of cell tower coverage areas for each activity</li>
              <li>Maps of cell tower coverage areas for each activity</li>
              <li>Customize your report with incident date and time</li>
              <li>Highlights activities when cell phone is moving</li>
              <li>Highlights activities at the incident time</li>
              <li>Highlights 911 calls</li>
              <li>
                Details ALL cell towers used during each activity
                <sup className="sup-for-mobversion">1</sup>
              </li>
              <li>
                Shows route map of ALL cell towers used for each activity
                <sup className="sup-for-mobversion">1</sup>
              </li>
              <li>
                Customize your report by entering names for associated telephone
                numbers
              </li>
            </ul>
          </div>
          <div className="first-for-mob">
            <h2 className="basic-report">Annual Member</h2>
            <span className="pay-span">Pay per report</span>
            <span className="span-red">
              Includes all the of premuim report features plus
            </span>
            <div className="lineformob"></div>
            <ul className="outer-list">
              <li>1 FREE report with membership</li>
              <li>20% discount on reports for Annual Members</li>
              <li>Volume discounts</li>
              <li>Templates to subpoena or preserve cell phone records</li>
              <li>Report of most frequently called and calling-numbers</li>
              <li>
                Report of most frequently utilized CELL TOWERS, with addresses
                and map links
              </li>
              <li>
                Report of Internet usage with sent and received data volumes,
                durations and times
              </li>
              <li>Peer-reviewed cellphone impairment studies library</li>
              <li>Telecommunications terminology glossary</li>
              <li>
                Litigation support<sup className="sup-for-lastpara-mob">*</sup>
              </li>
              <li>
                {" "}
                “Introduction to Cellphone Basics” class
                <sup className="sup-for-lastpara-mob">*</sup>
              </li>
              <li>
                User Name / cellphone number look-up service
                <sup className="sup-for-lastpara-mob">*</sup>
              </li>
            </ul>
          </div>
        </div>
        <div className="btnNlines">
          <div className="last-three-lines">
            <span>
              {" "}
              <sup className="sup-color">1</sup> When provided by the cell phone
              company
            </span>
            <span>
              <sup className="sup-color">*</sup>Optional services, subject to
              additional fees and availability
            </span>
            <span>©2021 IoT Analytics, LLC dba CellNinja</span>
          </div>
          <div>
            <button className="req-price-btn">REQUEST PRICING</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
