import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Modal,
  Popover,
  Spin,
  Upload,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  resetSelfService,
  saveSelfServiceData,
} from "../../actions/selfServiceEntityAction";
import {
  downlaodFile,
  finalReport,
  generateZipFinalExcelReport,
  getFileType,
} from "../../apis/projectapi";
import ERRORICON from "../../images/error-icon-cellninja-color.png";
import TIMEOUTICON from "../../images/timeout-hourglass.png";
import FILEINFO from "../../images/verizon_info.png";
import { dispatch } from "../../store";
import {
  CELLNINJACOLOR,
  DATE_REGEX,
  LATLNG_REGEX,
  TIME_REGEX,
} from "../../utils/constants";
import { ScreenRender } from "../newscreens/commonFunctions";
import "./selfServiceNew.scss";
import { loadUserInfo } from "../../actions/user";
import { getSpecificCustomerMessages } from "../../apis/customermessages";
import { downloadFile, downloadedFile } from "../../functions/functions";
import VERIZONDEVICEIDSAMPLE from "../../images/Verizon_Device_Id_Sample.png";
import VERIZONIPSESSIONSSAMPLE from "../../images/Verizon_Ip_Sessions_Sample.png";
import VERIZONTEXTSAMPLE from "../../images/Verizon_Text_Sample.png";
import VERIZONVOICESAMPLE from "../../images/Verizon_Voice_Sample.png";
import DOWNLOADICON from "../../images/downloading-icon-cloud.png";
import { fetchCustomerMessages } from "../app/mainIndex";
import moment from "moment";

const SelfServiceNew = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [spinnerText, setSpinnerText] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);
  const [timeoutModal, setTimeoutModal] = useState(false);
  const [missingTower, setMissingTower] = useState(false);
  const [voice, setVoice] = useState(false);
  const [sms, setSms] = useState(false);
  const [data, setData] = useState(false);
  const [ipsessions, setIpsessions] = useState(false);
  const [deviceId, setDeviceId] = useState(false);
  const [voiceFile, setVoiceFile] = useState("");
  const [smsFile, setSmsFile] = useState("");
  const [ipSessionsFile, setIpSessionsFile] = useState("");
  const [deviceIdFile, setDeviceIdFile] = useState("");
  const [dataFile, setDataFile] = useState("");
  const [title, setTitle] = useState("");
  const [verizonError, setVerizonError] = useState(false);
  const [tMobileError, setTMobileError] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [attTowerError, setAttTowerError] = useState(false);
  const [showSample, setShowSample] = useState("");
  const [showSamples, setShowSamples] = useState(false);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [verizonSample, setVerizonSample] = useState();
  const [reportType, setReportType] = useState("");
  const [open, setOpen] = useState(false);
  const [popOverMsg, setPopOverMsg] = useState("");
  const [fileNotMatchMessage, setFileNotMatchMessage] = useState("");
  const [invalidFileMessage, setInvalidFileMessage] = useState("");
  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadCompletionModal, setDownloadCompletionModal] = useState(false);

  const { currentUser = {} } = useSelector(({ app }) => app);
  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );
  let interval = useRef();

  const checkPage = () => {
    if (page === 1 || page === 5) return true;
    else return false;
  };

  useEffect(() => {
    if (verizonError) {
      getSelfServiceEntity.multipleCdrFiles = "verizon";
      getSelfServiceEntity.previewReport = false;
      dispatch(saveSelfServiceData());
    }
  }, [verizonError]);

  useEffect(() => {
    if (tMobileError) {
      getSelfServiceEntity.multipleTMobileFiles = "tmobile";
      getSelfServiceEntity.previewReport = false;
      dispatch(saveSelfServiceData());
    }
  }, [tMobileError]);

  useEffect(() => {
    if (showSample === "Voice") setVerizonSample(VERIZONVOICESAMPLE);
    else if (showSample === "Text") setVerizonSample(VERIZONTEXTSAMPLE);
    else if (showSample === "IP-Sessions")
      setVerizonSample(VERIZONIPSESSIONSSAMPLE);
    else if (showSample === "Device-ID")
      setVerizonSample(VERIZONDEVICEIDSAMPLE);
  }, [showSample]);

  const sendDataToRedux = () => {
    form.validateFields().then((values) => {
      let incidentDate =
        values.incidentDate === undefined ? "" : values.incidentDate;
      let incidentTime =
        values.incidentTime === undefined ? "" : values.incidentTime;

      if (incidentDate !== "") {
        let date = incidentDate.split("/");
        if (date[0].length === 1) date[0] = date[0].padStart(2, "0");
        if (date[1].length === 1) date[1] = date[1].padStart(2, "0");
        if (date[2].length === 2) date[2] = date[2].padStart(4, "20");
        incidentDate = date.join("/");
      }

      if (incidentTime !== "") {
        let time = incidentTime.split(":");
        if (time[0].length === 1) time[0] = time[0].padStart(2, "0");
        incidentTime = time.join(":");

        if (!time[1].includes(" ")) {
          incidentTime = incidentTime.replace("AM", " AM");
          incidentTime = incidentTime.replace("PM", " PM");
          incidentTime = incidentTime.replace("am", " AM");
          incidentTime = incidentTime.replace("pm", " PM");
        } else {
          incidentTime = incidentTime.replace("am", "AM");
          incidentTime = incidentTime.replace("pm", "PM");
        }
      }

      getSelfServiceEntity.customizeReport.reportName = values.reportName
        ? values.reportName
        : "";
      getSelfServiceEntity.customizeReport.incidentDate = incidentDate
        ? incidentDate
        : "";
      getSelfServiceEntity.customizeReport.incidentTime = incidentTime
        ? incidentTime
        : "";
      getSelfServiceEntity.customizeReport.incidentLocation =
        values.incidentLocation ? values.incidentLocation : "";
      if (values.cellPhoneDetail?.length > 0) {
        values.cellPhoneDetail?.forEach((element, index) => {
          let number = element.cellPhoneNumber.replace(/\D/g, "");
          getSelfServiceEntity.customizeReport.cellPhoneDetail[index] = {
            cellPhoneUserName: element.cellPhoneUserName,
            cellPhoneNumber: number,
          };
        });
      } else {
        getSelfServiceEntity.customizeReport.cellPhoneDetail = [];
      }
      getSelfServiceEntity.customizeReport.lookUpNumber = values.lookUpNumber
        ? values.lookUpNumber
        : "";
      getSelfServiceEntity.previewReport = false;
      dispatch(saveSelfServiceData());
    });
  };

  const checkCustomizeFields = (incidentDate, incidentTime) => {
    if (
      (incidentDate === undefined && incidentTime === undefined) ||
      (incidentDate === "" && incidentTime === "") ||
      (incidentDate !== "" &&
        incidentTime !== "" &&
        DATE_REGEX.test(incidentDate) &&
        TIME_REGEX.test(incidentTime))
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkForMultipleFiles = () => {
    if (getSelfServiceEntity.multipleCdrFiles === "verizon") {
      if (getSelfServiceEntity.uploadedFiles.voice === "") {
        return true;
      } else {
        return false;
      }
    } else if (getSelfServiceEntity.multipleTMobileFiles === "tmobile") {
      if (getSelfServiceEntity.uploadedFiles.voice === "") {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkForRequiredFiles = (files) => {
    if (files.voice !== "") {
      return false;
    } else {
      return true;
    }
  };

  const checkBeforeGenerateReport = () => {
    let cdrType = getSelfServiceEntity.cdrType;
    let files = getSelfServiceEntity.uploadedFiles;
    if (cdrType === "at&t" && files.voice === "") {
      setOpen(true);
      setPopOverMsg("Call File is missing.");
    } else if (
      (cdrType.includes("sprint") || cdrType.includes("tMobile")) &&
      checkForRequiredFiles(files)
    ) {
      setOpen(true);
      setPopOverMsg("Required File is missing.");
    } else if (cdrType.includes("verizon") && files.voice === "") {
      setOpen(true);
      setPopOverMsg("Required File is missing.");
    } else {
      setSpinnerText(true);
      generateCdrReport();
    }
  };

  const jumpToPreviousPage = () => {
    if (
      page === 4 &&
      getSelfServiceEntity.reportType &&
      getSelfServiceEntity.reportType !== "annual"
    ) {
      setPage(page - 2);
    } else if (
      page === 3 &&
      !checkExpireDate()
      // currentUser.reportCount < 21
    ) {
      setPage(page - 2);
    } else setPage(page - 1);
    setOpen(false);
  };

  const checkCustomerUser = () => {
    if (
      //currentUser?.roles?.length === 1 &&
      currentUser?.userType === "BetaUser"
      //currentUser?.roles[0]?.name === "Customer"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkExpireDate = () => {
    if (currentUser) {
      let date = currentUser?.expiryDate;
      const then =
        currentUser.expiryDate === null
          ? new Date().setHours(0, 0, 0, 0)
          : new Date(moment(date).format("MM/DD/YYYY")).setHours(0, 0, 0, 0);
      const now = new Date().setHours(0, 0, 0, 0);

      if (now > then) {
        // console.log("previous");
        return true;
      } else if (now < then) {
        // console.log("future");
        return false;
      } else {
        // console.log("today");
        return false;
      }
    }
  };

  const jumpToNextPage = () => {
    let incidentDate =
      form.getFieldValue("incidentDate") === undefined
        ? ""
        : form.getFieldValue("incidentDate");
    let incidentTime =
      form.getFieldValue("incidentTime") === undefined
        ? ""
        : form.getFieldValue("incidentTime");
    let incidentLocation =
      form.getFieldValue("incidentLocation") === undefined
        ? ""
        : form.getFieldValue("incidentLocation");

    if (page === 1 && checkCustomerUser() && checkExpireDate()) {
      setOpen(true);
      setPopOverMsg(
        "Your Free Trial is Over. For any queries, please contact at info@cell-ninja.com"
      );
    } else if (
      page === 1 &&
      checkCustomerUser() &&
      !checkExpireDate()
      // currentUser.reportCount < 21
    ) {
      dispatch(saveSelfServiceData("reportType", "annual"));
      setPage(page + 2);
    } else if (page === 2 && getSelfServiceEntity.reportType === null) {
      setOpen(true);
      setPopOverMsg("Please Select your Report Type");
    } else if (page === 2 && getSelfServiceEntity.reportType !== null) {
      if (getSelfServiceEntity.reportType !== "annual") setPage(page + 2);
      else setPage(page + 1);
    } else if (page === 3) {
      if (
        incidentDate !== "" &&
        !DATE_REGEX.test(incidentDate) &&
        incidentTime !== "" &&
        !TIME_REGEX.test(incidentTime)
      ) {
        setOpen(true);
        setPopOverMsg(
          "Incident Date & Time does not match the required format."
        );
      } else if (incidentDate !== "" && !DATE_REGEX.test(incidentDate)) {
        setOpen(true);
        setPopOverMsg("Incident Date does not match the required format.");
      } else if (incidentTime !== "" && !TIME_REGEX.test(incidentTime)) {
        setOpen(true);
        setPopOverMsg("Incident Time does not match the required format.");
      } else if (incidentDate === "" && incidentTime !== "") {
        setOpen(true);
        setPopOverMsg("Incident Date field can not be empty.");
      }
      // else if (incidentDate !== "" && incidentTime === "") {
      //   setOpen(true);
      //   setPopOverMsg("Incident Time field can not empty.");
      // }
      else if (
        incidentDate === "" &&
        incidentTime === "" &&
        incidentLocation !== ""
      ) {
        setOpen(true);
        setPopOverMsg("Incident Date and Time fields are empty.");
      } else if (
        incidentLocation !== "" &&
        !LATLNG_REGEX.test(incidentLocation)
      ) {
        setOpen(true);
        setPopOverMsg(
          "Please enter street address, city state or longitude, latitude."
        );
      } else if (
        (incidentDate !== "" && incidentTime !== "") ||
        (incidentDate === "" && incidentTime === "") ||
        (incidentDate !== "" && incidentTime === "")
      ) {
        setOpen(false);
        sendDataToRedux();
        setPage(page + 1);
      }
    } else if (page === 4 && getSelfServiceEntity.file === null) {
      setOpen(true);
      setPopOverMsg("Please Upload Call File");
    }
    // else if (
    //   page === 4 &&
    //   getSelfServiceEntity.file &&
    //   currentUser.reportCount > 20 &&
    //   currentUser?.roles?.filter(function (entry) {
    //     return entry.name === "Customer";
    //   }).length > 0
    // ) {
    //   setOpen(true);
    //   setPopOverMsg("Beta User Trial Completed");
    // }
    else if (page === 4 && getSelfServiceEntity.file) {
      checkBeforeGenerateReport();
    } else if (page === 5 && getSelfServiceEntity.reportPayment === false) {
      setOpen(true);
      setPopOverMsg("Payment Pending");
    } else {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (getSelfServiceEntity.reportPayment) {
      dispatch(resetSelfService());
      let redux = getSelfServiceEntity.uploadedFiles;
      redux.voice = "";
      redux.data = "";
      redux.sms = "";
      redux.ipSessions = "";
      redux.tower = "";

      dispatch(saveSelfServiceData("filesToUpload", []));
      dispatch(saveSelfServiceData());
      setPage(1);
    }
  }, [getSelfServiceEntity.reportPayment]);

  useEffect(() => {
    if (getSelfServiceEntity.page === 5) {
      setTimeoutModal(false);
    }
  }, [getSelfServiceEntity]);

  useEffect(() => {
    if (
      !checkCustomerUser() ||
      (checkCustomerUser() && checkExpireDate())
      // currentUser.reportCount > 20)
    ) {
      if (
        getSelfServiceEntity.reportType &&
        getSelfServiceEntity.reportType === "annual"
      ) {
        setOpen(false);
        setPage(page + 1);
      } else if (
        getSelfServiceEntity.reportType &&
        getSelfServiceEntity.reportType !== "annual"
      ) {
        setOpen(false);
        setPage(page + 2);
      }
    }
  }, [getSelfServiceEntity.reportType]);

  const timer = () => {
    var sec = 70;

    function updateSec() {
      sec--;
      if (sec === 0) {
        stopTimer(); // stop timer
        setGeneratingReport(false);
        setTimeoutModal(true);
      }
    }
    updateSec();

    interval.current = setInterval(updateSec, 1000);
  };

  const stopTimer = () => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = null;
    }
  };

  const getFileNmae = (name) => {
    let fileName = "";
    if (
      getSelfServiceEntity.generateReport !== null &&
      (getSelfServiceEntity.uploadedFiles.data !== "" ||
        getSelfServiceEntity.uploadedFiles.sms !== "" ||
        getSelfServiceEntity.uploadedFiles.ipSessions !== "" ||
        getSelfServiceEntity.uploadedFiles.deviceId)
    ) {
      if (getSelfServiceEntity.generateReport === "verizon") {
        fileName = "Multiple Verizon Files.pdf";
      } else if (getSelfServiceEntity.generateReport === "tmobile") {
        fileName = "Multiple T-Mobile Files.pdf";
      } else {
        fileName = name;
      }
    } else {
      fileName = name;
    }

    return fileName;
  };

  const setLocalStorage = (file, id) => {
    let fileName;
    if (file.name.includes(".txt")) {
      fileName = file.name.split(".txt");
      fileName = fileName[0] + ".pdf"; //replacing .txt with .pdf in case of uploading txt file, if we dont do this our final report generates in .txt format
    } else if (file.name.includes(".csv")) {
      fileName = file.name.split(".csv");
      fileName = fileName[0] + ".pdf"; //replacing .csv with .pdf in case of uploading csv file, if we dont do this our final report generates in .csv format
    } else if (file.name.includes(".xls")) {
      fileName = file.name.split(".xls");
      fileName = fileName[0] + ".pdf";
    } else fileName = file.name;
    localStorage.setItem("uniqueId", id);
    localStorage.setItem(
      "fileName",
      getSelfServiceEntity?.customizeReport.reportName
        ? getSelfServiceEntity?.customizeReport.reportName + ".pdf"
        : getFileNmae(fileName)
    );
  };

  const updateUploadedFile = (name, value) => {
    getSelfServiceEntity.uploadedFiles[name] = value.slice(1, value.length - 1);
    dispatch(saveSelfServiceData());
  };

  const checkBenUserForFile = (uniqueId) => {
    if (currentUser.username === "ben") {
      setDownloadModal(true);
      generateZipFinalExcelReport(uniqueId)
        .then((response) => {
          downloadFile(response);
          dispatch(resetSelfService());
          dispatch(saveSelfServiceData("filesToUpload", []));
          dispatch(
            saveSelfServiceData("uploadedFiles", {
              voice: "",
              data: "",
              sms: "",
              ipSessions: "",
              tower: "",
            })
          );
        })
        .catch((error) => {
          message.error("error", error);
        })
        .finally(() => {
          setDownloadModal(false);
          setDownloadCompletionModal(true);
        });
    }
  };

  const resetFilesState = () => {
    setVoice(false);
    setVoiceFile("");
    setData(false);
    setDataFile("");
    setSms(false);
    setSmsFile("");
    setIpsessions(false);
    setIpSessionsFile("");
    setDeviceId(false);
    setDeviceIdFile("");
  };

  const generateFinalReport = async (formData) => {
    var ext = getSelfServiceEntity.fileName.split(".").pop();
    if (
      ext === "pdf" ||
      ext === "txt" ||
      ext === "csv" ||
      ext === "xls" ||
      ext === "xlsx"
    ) {
      timer();
      await finalReport(formData, null)
        .then((response) => {
          setTimeoutModal(false);
          if (
            JSON.parse(localStorage.getItem("user")).message ===
              "Request failed with status code 401" ||
            !localStorage.getItem("__CDR-Analyzer")
          ) {
            navigate("/user/login");
          }
          setGeneratingReport(false);
          stopTimer();
          if (response.data.object.status === 200) {
            if (response.data.object.id !== null) {
              dispatch(loadUserInfo());
              setLocalStorage(
                getSelfServiceEntity.file,
                response.data.object.id
              );

              if (currentUser.username === "ben") {
                checkBenUserForFile(response.data.object.id);
                setPage(1);
              } else {
                if (
                  checkCustomerUser() &&
                  !checkExpireDate()
                  // currentUser.reportCount < 21
                ) {
                  downloadFinalReport(response.data.object.id);
                  setPage(1);
                } else setPage(page + 1);
              }
              setReportType("");
              resetFilesState();
              form.resetFields();
            }
          }
          if (response.data.object.status === 500) {
            setErrorModal(true);
            setTimeoutModal(false);
            stopTimer();
            setGeneratingReport(false);
          }
          if (response.data.object.failureReason === "Invalid CDR File") {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          stopTimer();
          setGeneratingReport(false);
          if (
            error.response.data.message === "Tower not found" &&
            getSelfServiceEntity.cdrType === "at&t"
          ) {
            setErrorModal(true);
            setAttTowerError(true);
          } else if (error.response.data.message === "Tower not found") {
            setTimeoutModal(false);
            getSelfServiceEntity.uploadedFiles.tower = "";

            let files = getSelfServiceEntity.filesToUpload?.filter(
              (file) => file.type !== "tower"
            );
            getSelfServiceEntity.filesToUpload = files.length > 0 ? files : [];

            getSelfServiceEntity.towerError = "tower not found";

            dispatch(saveSelfServiceData());
          } else if (error.response.status === 504) {
            setErrorModal(false);
            setTimeoutModal(true);
          } else {
            setErrorModal(true);
            setTimeoutModal(false);
          }
        });

      return false;
    }
  };

  const downloadFinalReport = (uniqueId) => {
    setDownloadModal(true);
    downlaodFile(uniqueId, false)
      .then((response) => {
        if (response.status === 200) {
          let fileName = getSelfServiceEntity.customizeReport.reportName
            ? getSelfServiceEntity.customizeReport.reportName + ".pdf"
            : getSelfServiceEntity.fileName;
          downloadedFile(response, "Final Report_" + fileName);
          dispatch(resetSelfService());
          dispatch(saveSelfServiceData("filesToUpload", []));
          dispatch(
            saveSelfServiceData("uploadedFiles", {
              voice: "",
              data: "",
              sms: "",
              ipSessions: "",
              tower: "",
            })
          );
        }
      })
      .catch((error) => {
        message.error("error", error);
      })
      .finally(() => {
        setDownloadModal(false);
        setDownloadCompletionModal(true);
      });
  };

  const getFinalReportType = () => {
    if (getSelfServiceEntity.cdrType.includes("at&t")) return "at&t";
    else if (getSelfServiceEntity.cdrType.includes("tMobile")) return "tMobile";
    else if (getSelfServiceEntity.cdrType.includes("verizon")) return "verizon";
  };

  const getFormData = () => {
    let formData = new FormData();
    for (let i = 0; i < getSelfServiceEntity.filesToUpload.length; i++) {
      formData.append("files", getSelfServiceEntity.filesToUpload[i].file);
    }
    formData.append("filename", getSelfServiceEntity.fileName);
    formData.append("reportType", getSelfServiceEntity.reportType);
    formData.append(
      "cellPhoneDetail",
      JSON.stringify(getSelfServiceEntity.customizeReport)
    );
    formData.append(
      "key",
      getSelfServiceEntity.key ? getSelfServiceEntity.key : ""
    );
    formData.append("generateReport", reportType);
    formData.append("cdrType", getFinalReportType());
    formData.append(
      "uploadedFiles",
      JSON.stringify(getSelfServiceEntity.uploadedFiles)
    );

    return formData;
  };

  const generateCdrReport = () => {
    if (getSelfServiceEntity) {
      setGeneratingReport(true);
      generateFinalReport(getFormData());
    }
  };

  useEffect(() => {
    if (getSelfServiceEntity.uploadAnotherFile) {
      dispatch(saveSelfServiceData("uploadAnotherFile", false));
      generateCdrReport();
    }
  }, [getSelfServiceEntity.uploadAnotherFile]);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 40,
      }}
      spin
    />
  );

  useEffect(() => {
    if (reportType !== "") {
      if (voice) {
        setTMobileError(false);
        setVerizonError(false);
        setGeneratingReport(true);
        getSelfServiceEntity.generateReport = reportType;
        dispatch(saveSelfServiceData());
        generateFinalReport(getFormData());
      } else {
        setReportType("");
        message.error("Voice File With Cell Towers is Missing.");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, voice]);

  const uploadMedia = async (file) => {
    getSelfServiceEntity.file = file;
    getSelfServiceEntity.fileName = file.name;

    dispatch(saveSelfServiceData());

    setVerizonError(false);
    setTMobileError(false);
    setGeneratingReport(true);
    generateFinalReport(getFormData());
  };

  const uploadProps = {
    name: "file",
    showUploadList: false,
    // action: callApiForUpload,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
    // data: { formValues },
    beforeUpload: uploadMedia,
    credentials: "include",
    onChange(info) {
      // if (info.file.status === "done") {
      //   message.success(`${info.file.name} File Uploaded Successfully`);
      // } else if (info.file.status === "error") {
      //   message.error("Invalid File Type. Only PDF files are accepted");
      // } else {
      // }
    },
  };

  const getReportType = () => {
    if (getSelfServiceEntity.reportType === "basic") return "Basic";
    else if (getSelfServiceEntity.reportType === "premium") return "Premium";
    else return "Pro";
  };

  const columns = [
    {
      title: "User Name",
      dataIndex: "cellPhoneUserName",
      align: "center",
      key: "cellPhoneUserName",
    },
    {
      title: "Phone Number",
      dataIndex: "cellPhoneNumber",
      align: "center",
      key: "cellPhoneNumber",
    },
  ];

  useEffect(() => {
    if (page) {
      getSelfServiceEntity.page = page;
      dispatch(saveSelfServiceData());
    }
  }, [page]);

  const uploadMultipleFileInRedux = (type) => {
    const file = { file: getSelfServiceEntity.file, type: type };
    let flag, index;
    if (getSelfServiceEntity.filesToUpload.length > 0) {
      for (let i = 0; i < getSelfServiceEntity.filesToUpload.length; i++) {
        if (type === getSelfServiceEntity.filesToUpload[i].type) {
          flag = true;
          index = i;
        }
      }
      if (flag) {
        getSelfServiceEntity.filesToUpload[index] = file;
      } else {
        getSelfServiceEntity.filesToUpload.push(file);
      }
    } else {
      getSelfServiceEntity.filesToUpload.push(file);
    }
    dispatch(saveSelfServiceData());
  };

  useEffect(() => {
    getSpecificCustomerMessage(
      "Uploaded cell phone file doesn’t match with previous uploaded cell phone file"
    );
  }, []);

  const getSpecificCustomerMessage = (value) => {
    getSpecificCustomerMessages(value, true).then((response) => {
      if (response.status === 200) {
        if (
          value ===
          "Uploaded cell phone file doesn’t match with previous uploaded cell phone file"
        )
          setFileNotMatchMessage(response.data.object);
      }
    });
  };

  useEffect(() => {
    if (getSelfServiceEntity.file) {
      setOpen(false);
      setGeneratingReport(true);
      let formData = new FormData();
      formData.append("file", getSelfServiceEntity.file);
      getFileType(formData).then((response) => {
        setGeneratingReport(false);
        let fileType = response.data.object;
        if (!getSelfServiceEntity.cdrType) {
          getSelfServiceEntity.cdrType = fileType;
          dispatch(saveSelfServiceData());
        }

        if (response.status === 200) {
          if (fileType !== "") {
            if (
              getSelfServiceEntity.cdrType === "at&t" &&
              fileType === "at&t"
            ) {
              getSelfServiceEntity.uploadedFiles.voice =
                getSelfServiceEntity.fileName;

              const file = { file: getSelfServiceEntity.file, type: "voice" };

              getSelfServiceEntity.filesToUpload[0] = file;

              dispatch(saveSelfServiceData());
            } else if (
              (getSelfServiceEntity.cdrType.includes("tMobile") &&
                fileType.includes("tMobile")) ||
              fileType.includes("tower")
            ) {
              let type = fileType.includes("tMobileVoice")
                ? "voice"
                : fileType.includes("tMobileData")
                ? "data"
                : "tower";
              if (type === "tower") getSelfServiceEntity.towerError = "";
              if (fileType.includes("tMobileVoice"))
                getSelfServiceEntity.uploadedFiles.voice =
                  getSelfServiceEntity.fileName;
              else if (fileType.includes("tMobileData"))
                getSelfServiceEntity.uploadedFiles.data =
                  getSelfServiceEntity.fileName;
              else if (fileType.includes("tMobileTower"))
                getSelfServiceEntity.uploadedFiles.tower =
                  getSelfServiceEntity.fileName;

              uploadMultipleFileInRedux(type);
            } else if (
              getSelfServiceEntity.cdrType.includes("verizon") &&
              fileType.includes("verizon")
            ) {
              let type = fileType.includes("verizonVoice")
                ? "voice"
                : fileType.includes("verizonSms")
                ? "sms"
                : "data";

              if (fileType.includes("verizonVoice"))
                getSelfServiceEntity.uploadedFiles.voice =
                  getSelfServiceEntity.fileName;
              else if (fileType.includes("verizonSms"))
                getSelfServiceEntity.uploadedFiles.sms =
                  getSelfServiceEntity.fileName;
              else if (fileType.includes("verizonData"))
                getSelfServiceEntity.uploadedFiles.ipSessions =
                  getSelfServiceEntity.fileName;

              uploadMultipleFileInRedux(type);
            } else {
              message.error(fileNotMatchMessage, 5);
            }
          } else {
            message.error(response.data.message, 5);
          }
        }
      });
    }
  }, [getSelfServiceEntity.file]);

  const popoverTitle = () => {
    return (
      <>
        <div className="popover-title">
          <div className="txt-color font">Error</div>
          <div>
            <CloseOutlined onClick={() => setOpen(false)} />
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <Spin
        style={{ color: "red" }}
        size="large"
        indicator={antIcon}
        spinning={generatingReport}
        tip={
          spinnerText
            ? "Generating Final Report..."
            : "Analyzing CellPhone File..."
        }
      >
        {ScreenRender(page, form)}

        {/* tMobile multiple files modal */}
        <div hidden={tMobileError}>
          <Modal
            open={false}
            width={500}
            closable={false}
            maskClosable={false}
            footer={null}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img alt="erroricon" src={FILEINFO} height={80} width={80} />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{title}</h2>
            </div>

            <div className="checkbox-verizon">
              <div className="verizon-row">
                <Checkbox checked={voice}>
                  T-Mobile Voice & Sms File with Cell Towers
                </Checkbox>
                <div className="samples">
                  <Button
                    type="text"
                    onClick={() => {
                      setShowSample("Voice");
                      setShowSamples(true);
                      setPreviewVisible(!isPreviewVisible);
                    }}
                  >
                    [Sample]
                  </Button>
                </div>
              </div>
              {voice ? <p className="fileName">{voiceFile}</p> : ""}

              <div className="verizon-row">
                <Checkbox checked={dataFile}>
                  T-Mobile Data File (Optional)
                </Checkbox>
                <div className="samples">
                  <Button
                    type="text"
                    onClick={() => {
                      setShowSample("Text");
                      setShowSamples(true);
                      setPreviewVisible(!isPreviewVisible);
                    }}
                  >
                    [Sample]
                  </Button>
                </div>
              </div>
              {data ? <p className="fileName">{dataFile}</p> : ""}

              <br />
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div>
                <Button
                  style={{
                    width: "150px",
                    height: "50px",
                    backgroundColor: CELLNINJACOLOR,
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setReportType("tmobile");
                  }}
                >
                  Generate
                </Button>
              </div>

              <div>
                <Upload {...uploadProps}>
                  <Button
                    style={{
                      width: "150px",
                      height: "50px",
                      backgroundColor: CELLNINJACOLOR,
                      color: "white",
                      fontSize: "18px",
                    }}
                    onClick={() => {}}
                  >
                    Upload Next File
                  </Button>
                </Upload>
              </div>
            </div>
          </Modal>
        </div>

        {/* verizon multiple files modal */}
        <div hidden={verizonError}>
          <Modal
            maskClosable={false}
            open={false}
            width={500}
            closable={false}
            footer={null}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img alt="erroricon" src={FILEINFO} height={80} width={80} />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>{title}</h2>
              {title === "Invalid Verizon CDR" ? (
                <h3>Please Upload one or more of these required files</h3>
              ) : (
                ""
              )}
            </div>
            <div hidden={showSamples}>
              <Image
                src={verizonSample}
                preview={{
                  visible: isPreviewVisible,
                  onVisibleChange: (visible, prevVisible) =>
                    setPreviewVisible(visible),
                }}
              />
            </div>
            <div className="checkbox-verizon">
              <div className="verizon-row">
                <Checkbox checked={voice}>
                  Verizon Voice File with Cell Towers
                </Checkbox>
                <div className="samples">
                  <Button
                    type="text"
                    onClick={() => {
                      setShowSample("Voice");
                      setShowSamples(true);
                      setPreviewVisible(!isPreviewVisible);
                    }}
                  >
                    [Sample]
                  </Button>
                </div>
              </div>
              {voice ? <p className="fileName">{voiceFile}</p> : ""}
              <div className="verizon-row">
                <Checkbox checked={sms}>Verizon Text File (Optional)</Checkbox>
                <div className="samples">
                  <Button
                    type="text"
                    onClick={() => {
                      setShowSample("Text");
                      setShowSamples(true);
                      setPreviewVisible(!isPreviewVisible);
                    }}
                  >
                    [Sample]
                  </Button>
                </div>
              </div>
              {sms ? <p className="fileName">{smsFile}</p> : ""}
              <div className="verizon-row">
                <Checkbox checked={ipsessions}>
                  Verizon IP-Sessions File (Optional)
                </Checkbox>
                <div className="samples">
                  <Button
                    type="text"
                    onClick={() => {
                      setShowSample("IP-Sessions");
                      setShowSamples(true);
                      setPreviewVisible(!isPreviewVisible);
                    }}
                  >
                    [Sample]
                  </Button>
                </div>
              </div>
              {ipsessions ? <p className="fileName">{ipSessionsFile}</p> : ""}

              <div className="verizon-row">
                <Checkbox checked={deviceId}>
                  Verizon Device ID File (Optional)
                </Checkbox>
                <div className="samples">
                  <Button
                    type="text"
                    onClick={() => {
                      setShowSample("Device-ID");
                      setShowSamples(true);
                      setPreviewVisible(!isPreviewVisible);
                    }}
                  >
                    [Sample]
                  </Button>
                </div>
              </div>
              {deviceId ? <p className="fileName">{deviceIdFile}</p> : ""}
              <br />
            </div>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <div>
                <Button
                  style={{
                    width: "150px",
                    height: "50px",
                    backgroundColor: CELLNINJACOLOR,
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    setReportType("verizon");
                  }}
                >
                  Generate
                </Button>
              </div>

              <div>
                <Upload {...uploadProps}>
                  <Button
                    style={{
                      width: "150px",
                      height: "50px",
                      backgroundColor: CELLNINJACOLOR,
                      color: "white",
                      fontSize: "18px",
                    }}
                    onClick={() => {}}
                  >
                    Upload Next File
                  </Button>
                </Upload>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            style={{
              align: "center",
            }}
            maskClosable={false}
            closable={false}
            width={500}
            open={errorModal}
            footer={null}
            onCancel={() => {
              setErrorModal(false);
            }}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img alt="erroricon" src={ERRORICON} height={80} width={80} />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>We are Sorry!</h2>
              <br />
            </div>

            <div style={{ textAlign: "center", fontSize: "17px" }}>
              {attTowerError ? (
                <h3>
                  {" "}
                  {fetchCustomerMessages()
                    ? fetchCustomerMessages()["tower not found"]
                    : ""}
                </h3>
              ) : (
                <h3>
                  {fetchCustomerMessages()
                    ? fetchCustomerMessages()["File error"]
                    : ""}
                </h3>
              )}
              <br />
            </div>

            <div style={{ textAlign: "center" }}>
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setErrorModal(false);
                }}
              >
                Dismiss
              </Button>
            </div>
          </Modal>
        </div>

        <div>
          <Modal
            maskClosable={false}
            open={timeoutModal}
            width={500}
            closable={true}
            footer={null}
            onCancel={(e) => {
              setTimeoutModal(false);
            }}
          >
            <div style={{ textAlign: "center" }}>
              <br />
              <img alt="erroricon" src={TIMEOUTICON} height={100} width={100} />
            </div>

            <br />
            <br />
            <div style={{ textAlign: "center", fontSize: "17px" }}>
              <h3>
                {fetchCustomerMessages()
                  ? fetchCustomerMessages()[
                      "Large file parsing greater than 1 minute"
                    ]
                  : ""}
              </h3>
              <br />
            </div>

            <div style={{ textAlign: "center" }}>
              <Link to="/user/selfservice/reports">
                <Button
                  style={{
                    width: "180px",
                    height: "50px",
                    backgroundColor: CELLNINJACOLOR,
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    dispatch(resetSelfService());
                  }}
                >
                  Go To Dashboard
                </Button>
              </Link>
            </div>
          </Modal>
        </div>
        {
          <div>
            <Modal
              open={missingTower}
              width={500}
              footer={null}
              closable={false}
              maskClosable={false}
              onCancel={(e) => {
                setMissingTower(false);
              }}
            >
              <div style={{ textAlign: "center" }}>
                <br />
                <img alt="erroricon" src={ERRORICON} height={80} width={80} />
              </div>

              <div style={{ textAlign: "center" }}>
                <br />
                <h2>We're sorry.</h2>
                <br />
              </div>

              <div style={{ textAlign: "center", fontSize: "17px" }}>
                <h3>
                  Towers are missing. Upload Tower file to generate final
                  report.
                </h3>
                <br />
              </div>

              <div style={{ textAlign: "center" }}>
                <Upload {...uploadProps}>
                  <Button
                    style={{
                      // width: "200px",
                      height: "50px",
                      backgroundColor: CELLNINJACOLOR,
                      color: "white",
                      fontSize: "18px",
                    }}
                    onClick={() => {
                      setMissingTower(false);
                      // handleOk();
                    }}
                  >
                    Upload Tower Report
                  </Button>
                </Upload>
              </div>
            </Modal>
          </div>
        }

        <div
          className={`paginator ${page === 1 ? "first-page-paginator" : ""}`}
        >
          <div className="paginator-first-div">
            <div className="paginator-second-div">
              <div
                className="playicom"
                style={{
                  pointerEvents: checkPage() ? "none" : "",
                  opacity: checkPage() ? "0" : "",
                }}
              >
                <div className="step-i addion mr-lft adleft num-btn l-btn">
                  <ArrowLeftIcon
                    className="play-itself"
                    onClick={() => jumpToPreviousPage()}
                  />
                </div>
                <span className="sim-play-font">
                  B<span className="spec-play-font">ACK</span>
                </span>
              </div>
              <div className="playicom">
                <span className="sim-play-font">
                  N<span className="spec-play-font">EXT</span>
                </span>
                <div className="step-i addion num-btn r-btn">
                  <Popover
                    className="popover"
                    destroyTooltipOnHide
                    title={() => popoverTitle()}
                    content={popOverMsg}
                    trigger="click"
                    placement="top"
                    open={open}
                    overlayStyle={{ width: "16rem" }}
                  >
                    <ArrowRightIcon
                      className="play-itself"
                      onClick={() => jumpToNextPage()}
                    />
                  </Popover>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>

      <div>
        <Modal open={downloadModal} width={500} footer={null} closable={false}>
          <div style={{ textAlign: "center" }}>
            <br />
            <img alt="downloadicon" src={DOWNLOADICON} height={80} width={80} />
          </div>

          <div style={{ textAlign: "center" }}>
            <br />
            <h2>{"Your file is downloading..."}</h2>
          </div>

          <div style={{ textAlign: "center" }}>
            <br />
            <br />
            <Button
              style={{
                width: "120px",
                height: "40px",
                backgroundColor: CELLNINJACOLOR,
                color: "white",
                fontSize: "18px",
              }}
              onClick={() => {
                setDownloadModal(false);
              }}
            >
              OK
            </Button>
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          open={downloadCompletionModal}
          width={500}
          footer={null}
          closable={false}
        >
          <div style={{ textAlign: "center" }}>
            <br />
            <img alt="downloadicon" src={DOWNLOADICON} height={80} width={80} />
          </div>

          <div style={{ textAlign: "center" }}>
            <br />
            <h2>{"Downloading Completed..."}</h2>
          </div>

          <div style={{ textAlign: "center" }}>
            <br />
            <br />
            <Button
              style={{
                width: "120px",
                height: "40px",
                backgroundColor: CELLNINJACOLOR,
                color: "white",
                fontSize: "18px",
              }}
              onClick={() => {
                setDownloadCompletionModal(false);
              }}
            >
              OK
            </Button>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default SelfServiceNew;
