import {
  ArrowLeftOutlined,
  EditOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
// import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom/dist";
import { setCustomerMessage } from "../../../actions/app";
import {
  getCustomerMessages,
  updateCustomerMessages,
} from "../../../apis/customermessages";
import { getAPI } from "../../app/mainIndex";

const CustomerMessages = () => {
  const navigate = useNavigate();
  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);

  const loadCustomerMessages = () => {
    getCustomerMessages().then((response) => {
      setData(response.data.object);
      setCustomerMessage(response.data.object);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadCustomerMessages();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  const isEditing = (record) => record.id === editingKey;

  const columns = [
    {
      title: "Occasion",
      dataIndex: "occasion",
      key: "occasion",
      width: "40%",
      align: "center",
    },
    {
      title: "Messages",
      dataIndex: "messages",
      key: "messages",
      width: "40%",
      align: "center",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "operation",
      align: "center",
      width: "15%",
      render: (_, record) => {
        const editable = isEditing(record);
        // setEditable(isEditing(record));
        return editable ? (
          <div>
            <Button
              title="Save"
              style={{ marginRight: "5px" }}
              shape="round"
              icon={<SaveOutlined />}
              onClick={() => saveData(record)}
            ></Button>
            <Button
              title="Cancel"
              shape="round"
              onClick={() => cancelEditing()}
              icon={<StopOutlined />}
            ></Button>
          </div>
        ) : (
          <Button
            title="Edit"
            shape="round"
            disabled={editingKey !== ""}
            onClick={() => editPhoneData(record)}
            icon={<EditOutlined />}
          ></Button>
        );
      },
    },
  ];

  const mergedColumnsForReportTb = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const editPhoneData = (record) => {
    form.setFieldsValue({
      id: record.id,
      occasion: record.occasion,
      messages: record.messages,
    });
    setEditingKey(record.id);
  };

  const saveData = (record) => {
    form.validateFields().then(async (values) => {
      let newValues = {
        id: record.id,
        occasion: record.occasion,
        messages: values.messages,
      };
      const response = await updateCustomerMessages(newValues);
      if (response.status === 200) {
        setEditingKey("");
        loadCustomerMessages();
      }
    });
  };

  const cancelEditing = () => {
    setEditingKey("");
  };

  const editableCell = ({
    editing,
    dataIndex,
    title,
    record,
    index,
    children,
    ...restProps
  }) => {
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            // rules={[
            //   {
            //     required: true,
            //     message: `Please Input ${title}!`,
            //   },
            // ]}
          >
            <Input />
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ display: "flex" }}>
          <ArrowLeftOutlined
            style={{
              marginTop: "5px",
              marginRight: "10px",
            }}
            onClick={() => navigate("/user/home/projects")}
          />
          <h1>Customer Messages</h1>
        </div>
      </div>
      <Form component={false} name="" form={form}>
        <Table
          scroll={{ y: "67vh" }}
          loading={loading}
          style={{ margin: "0.5% 0%" }}
          bordered
          className="admin-table-container"
          rowKey={"id"}
          rowClassName="editable-row"
          components={{
            body: {
              cell: editableCell,
            },
          }}
          key="1"
          size="middle"
          columns={mergedColumnsForReportTb}
          dataSource={data}
          pagination={false}
        />
      </Form>
    </React.Fragment>
  );
};

export default CustomerMessages;
