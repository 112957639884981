import React from 'react'
import './style.scss'
import howitworksimg from '../../../images/easy-straigtht-forward.jpg'
const Easy = () => {
    return (
        <div className='main-container-easy'>
            <div className='staticheading'>
                <span className='about'>Easy </span>
                <span className='us'>and Straightforward</span>
            </div>
            <div className='m-con'>
                <div className='left-right-con'>
                    <div className='leftie'>
                        <div className='point'>
                            <span className='descrip'>CellNinja is a secure web-based service. Simply drag and drop a file to create an accurate, actionable report in minutes.</span>
                        </div>
                        <div className='sec-portion'>
                            <span className='span-understand'>Skip the learning curve<br /></span>
                            <ul className='ul-for-accurate'>
                                <li>No hardware or software to install</li>
                                <li>No contracts to sign</li>
                                <li>Nothing to manage</li>
                                <li>No set-up fees</li>


                            </ul>
                        </div>
                        <div className='find-relevant-fact'>
                            <span className='span-understand'>Ask about litigation support and consulting<br /></span>
                            <span className='frf-span'>
                                CellNinja offers certification letters and optional Daubert coaching, and remote testimony support by cellular technology, wire-tap, IP and records experts qualified in state and Federal courts.
                            </span>
                        </div>
                    </div>
                    <div className='rightie'>
                        <img className='img-how-it-works' src={howitworksimg} alt="" />
                        <div className='facts-container'>
                            <span className='facts'>Fact:</span>
                            <span className='people-span'>Sending or reading a text message takes your eyes off the road for about 5 seconds, long enough to travel the length of a football field while driving at 55 mph.</span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Easy;