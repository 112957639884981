import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";
import {
  APP_CONTEXT_URL,
  STRIPE_API_KEY_TEST,
  STRIPE_API_KEY_PRODUCTION,
} from "../../utils/constants";
import { useSelector } from "react-redux";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// To avoid exposing it, don't submit any personally identifiable information through requests with this API key.
// Sign in to see your own test API key embedded in code samples.
var stripePromise;
if (window.location.host === "cell-ninja.com") {
  stripePromise = loadStripe(STRIPE_API_KEY_PRODUCTION);
} else {
  stripePromise = loadStripe(STRIPE_API_KEY_TEST);
}

export default function StripComponent(props) {
  const [clientSecret, setClientSecret] = useState("");
  const [checkForModel, setCheckForModel] = useState(false);

  const id = localStorage.getItem("uniqueId");
  const fileName = localStorage.getItem("fileName");
  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );

  useEffect(() => {
    if (checkForModel && props) {
      props.onPaymentDone();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [checkForModel]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(APP_CONTEXT_URL + "/v1/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      body: JSON.stringify({
        items: [
          {
            id: id,
            name: fileName,
            reportType: getSelfServiceEntity?.reportType,
          },
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#e4003b",
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App border">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm
            onPaymentDone={() => {
              setCheckForModel(true);
            }}
          />
        </Elements>
      )}
    </div>
  );
}
