import React from "react";
import "./index.css";
import "./App.css";
import App from "./components/app";
import { createRoot } from "react-dom/client";
import { Provider as StateProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import TagManager from "react-gtm-module";
import {
  GOOGLE_TAG_MANAGER_ID,
  CELL_NINJA_HOST_NAME,
} from "../src/utils/constants";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

if (window.location.hostname === CELL_NINJA_HOST_NAME) {
  TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
}

root.render(
  <Router>
    <StateProvider key={4321} store={store}>
      <App />
    </StateProvider>
  </Router>
);
