import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllSSR = (current, pageSize, sortBy, orderBy) => {
  return axios.get(
    `${APP_CONTEXT_URL}/v1/selfservicerequest/${orderBy}/?page=${current - 1
    }&size=${pageSize}&sort=${sortBy}`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const getUserSSRRecords = (current, pageSize) => {
  return axios.get(APP_CONTEXT_URL + "/v1/userselfservicerequest/?page=" + (current - 1) +
    "&size=" + pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const getSSRCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/ssr/count", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const getUserSSRCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/ssr/usercount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const getFile = (file) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/ssr/searchFile",
    { file },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const getSSRFilterCount = (success, failed, paid, unPaid) => {
  return axios.get(APP_CONTEXT_URL + "/v1/ssr/filterCount", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
    params: {
      success: success,
      failed: failed,
      paid: paid,
      unPaid: unPaid,
    },
  });
};

export const getSSRFilterData = (
  page,
  pageSize,
  success,
  failed,
  paid,
  unPaid
) => {
  return axios.get(
    APP_CONTEXT_URL +
    "/v1/ssr/filterData" +
    "?page=" +
    (page - 1) +
    "&size=" +
    pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      params: {
        success: success,
        failed: failed,
        paid: paid,
        unPaid: unPaid,
      },
    }
  );
};

export const getCellNinjaToken = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/ssr/getCellNinjaToken", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const checkReportPayment = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/ssr/checkreportpayment/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};


export const getUserHistory = (userId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/userselfservicerequest/userhistory/" + userId,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};