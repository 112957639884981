import React, { useState } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { AppProvider } from "../../contexts/app";
import AboutUs from "../staticPages/aboutUs/aboutUs";
import Accuracy from "../staticPages/accuracy/accuracy";
import CallDetailRecord from "../staticPages/callDetailRecord/callDetailRecord";
import ContactUs from "../staticPages/contactUs/contactUs";
import Easy from "../staticPages/easyStraightFwd/easyStraightFwd";
import Fast from "../staticPages/fast/Fast";
import Footer from "../staticPages/Footer/Footer";
import MainHome from "../staticPages/home/home";
import HowItWorks from "../staticPages/howItWorks/howItWorks";
import NavigationBar from "../staticPages/NavigationBar/NavigationBar";
import ResetPass from "../staticPages/resetPass/ResetPass";
import SecureSafe from "../staticPages/secureSafe/secureSafe";
import CreateAccount from "./createAccount/CreateAccount";
import LookUp from "./lookup/lookup";
import Subscribe from "./subscribe/subscribe";

const Root = () => {
  const [language, setLanguage] = useState("en");

  return (
    <AppProvider value={{ language, setLanguage }}>
      <main className="cdr-analyzer-app">
        <div
          className="content"
          style={{
            paddingTop: "0.1px",
            background: "white",
            maxWidth: "100vw",
            overflow: "auto",
            margin: " 0 auto",
          }}
        >
          <Outlet />
          <NavigationBar />

          <Routes>
            <Route path="/">
              <Route index element={<MainHome />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/how-it-works" element={<HowItWorks />} />
              <Route path="/accurate" element={<Accuracy />} />
              <Route path="/easy" element={<Easy />} />
              <Route path="/secure" element={<SecureSafe />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/subscribe" element={<Subscribe />} />
              <Route path="/lookup" element={<LookUp />} />
              <Route
                path="/call-detail-records"
                element={<CallDetailRecord />}
              />
              <Route path="/fast" element={<Fast />} />
              <Route path="/resetpassword/*" element={<ResetPass />} />
            </Route>
          </Routes>
          <Footer />
        </div>
      </main>
    </AppProvider>
  );
};

export default Root;
