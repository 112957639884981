import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Spin,
  Button,
  Form,
  Row,
  Col,
  DatePicker,
  Checkbox,
  Pagination,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  getProjectData,
  getProjectFilterCount,
  getProjectFilterData,
  getRecordsCount,
} from "../../apis/projectapi";
import { useNavigate } from "react-router-dom";
import { size } from "lodash";
import { checkToken } from "../../functions/functions";
import { CELLNINJACOLOR } from "../../utils/constants";

const ProjectView = (props) => {
  var projectId = props.project.id;
  const navigate = useNavigate();
  const [project, setProject] = useState();
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState();
  const [recordsPerPage, setRecordsPerPage] = useState(1000);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState();

  const onFinish = (values) => {
    setFormValues(values);
    setPageNumber(1);
    onSearch(1, recordsPerPage, values);
  };

  function onShowSizeChange(pageNumber, recordsPerPage, projectId) {
    projectId = props.project.id;
    setLoading(true);
    setPageNumber(pageNumber);
    setRecordsPerPage(recordsPerPage);
    if (formValues === undefined)
      loadProjectDetails(pageNumber, recordsPerPage, projectId);
    else onSearch(pageNumber, recordsPerPage, formValues);
  }

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    getProjectsRecordCount(
      projectId
    ); /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const getProjectsRecordCount = (projectId) => {
    getRecordsCount(projectId).then((res) => {
      if (res.status === 200) {
        setTotalRecords(res.data);
        loadProjectDetails(pageNumber, recordsPerPage, projectId);
      }
    });
  };

  const loadProjectDetails = (page, pageSize, projectId) => {
    getProjectData(page, pageSize, projectId).then((res) => {
      if (res.status === 200) {
        setProject(res.data);
        setLoading(false);
      }
    });
  };

  const onSearch = (page, pageSize, values) => {
    setLoading(true);
    const from = values.from
      ? values.from.format("YYYY-MM-DD HH:mm:ss")
      : undefined;
    const to = values.to ? values.to.format("YYYY-MM-DD HH:mm:ss") : undefined;
    getProjectFilterCount(
      projectId,
      values.data,
      values.SMS,
      values.voice,
      from,
      to
    ).then((res) => {
      setTotalRecords(res.data);
      getProjectFilterData(
        page,
        pageSize,
        projectId,
        values.data,
        values.SMS,
        values.voice,
        from,
        to
      ).then((res) => {
        if (res.status === 200) {
          setProject(res.data);
          setLoading(false);
        }
      });
    });
  };

  const onFilterClear = () => {
    setFormValues("");
    setLoading(true);
    form.resetFields();
    setPageNumber(1);
    getProjectsRecordCount(projectId);
  };

  return (
    <React.Fragment>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <ArrowLeftOutlined
              style={{
                marginTop: "10px",
                marginRight: "10px",
              }}
              onClick={() => navigate("/user/home/projects")}
            />
            <h2>{props.project.name}</h2>
          </div>
        </div>
        <div style={{ textAlignLast: "center" }}>
          <Spin
            spinning={loading}
            style={{ color: CELLNINJACOLOR }}
            tip="Loading Data..."
          />
        </div>
        {project ? (
          <div style={{ padding: "0px 10px 0px 5px" }}>
            <Row
              style={{
                background: "#2980b9",
                padding: "0rem 0.75rem",
                borderBottom: "6px solid WHITE",
              }}
            >
              <Col
                span={16}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h3 style={{ color: "white", margin: "0px" }}>
                  Total Records: {props.project.totalCdrRecords} [Voice :{" "}
                  {props.project.totalVoiceRecords}, Data :{" "}
                  {props.project.totalDataRecords}, Sms :{" "}
                  {props.project.totalSmsRecords}]
                </h3>
              </Col>
              <Col
                span={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <h3 style={{ color: "white", margin: "0px" }}>
                  {project.fullFirstDateDesc} to {project.fullLastDateDesc}
                </h3>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        <Form
          onFinish={onFinish}
          form={form}
          initialValues={{
            remember: true,
          }}
        >
          <Row gutter={24}>
            <Col span={5}>
              <Form.Item label="From" name="from">
                <DatePicker
                  className="date-time-picker"
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="Start"
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="To" name="to">
                <DatePicker
                  className="date-time-picker"
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="End"
                />
              </Form.Item>
            </Col>
            <Col
              span={10}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Form.Item name="voice" valuePropName="checked">
                <Checkbox key="voice" className="cdr-type-filter-checkbox">
                  Voice
                </Checkbox>
              </Form.Item>
              <Form.Item name="data" valuePropName="checked">
                <Checkbox key="data">Data</Checkbox>
              </Form.Item>
              <Form.Item name="SMS" valuePropName="checked">
                <Checkbox key="SMS">SMS</Checkbox>
              </Form.Item>
            </Col>
            <Col
              span={4}
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Button
                className="login-form-clear-button"
                style={{ marginRight: "10px" }}
                onClick={() => onFilterClear()}
              >
                Clear
              </Button>
              <Button className="login-form-apply-button" htmlType="submit">
                Apply
              </Button>
            </Col>
          </Row>
        </Form>
        <div
          style={{
            height: "59vh",
            overflow: "scroll",
            padding: "0px 5px",
          }}
        >
          <table className="table">
            <thead
              style={{
                background: "red",
                position: "sticky",
                top: "-1px",
                boxShadow: "0 2px 2px -1px #025594",
              }}
            >
              <tr className="table-row">
                <th className="table-main-heading">Time</th>
                <th className="table-main-heading">Activity</th>
                <th className="table-main-heading">From</th>
                <th className="table-main-heading">To</th>
                <th className="table-main-heading">Duration</th>
                <th className="table-main-heading">Location During Call</th>
                <th className="table-main-heading">Item ID</th>
              </tr>
            </thead>
            <tbody>
              {project
                ? project["dayWiseRecords"].map((daywise) => {
                    return daywise["hourWiseRecords"].map((hourwise) => {
                      return (
                        <>
                          <tr className="table-row">
                            <th className="table-row-group" colSpan={4}>
                              {hourwise.hourDesc} (
                              {size(hourwise["cdrRecords"])}{" "}
                              {size(hourwise["cdrRecords"]) > 1
                                ? "activities"
                                : "activity"}
                              )
                            </th>
                            <th className="table-row-group" colSpan={3}>
                              {hourwise["cdrRecords"][0].fullDateDesc}
                            </th>
                          </tr>
                          {hourwise["cdrRecords"].map((record) => {
                            return (
                              <tr className="table-row">
                                <td className="table-data">
                                  {record.activityTime}
                                </td>
                                <td className="table-data">
                                  {record.cdrTypeDesc}
                                </td>
                                <td className="table-data">{record.caller}</td>
                                <td className="table-data">{record.called}</td>
                                <td className="table-data">
                                  {record.convertTimeToHoursAndSecond}
                                  <br />
                                  Towers - {record.towerCount}
                                </td>
                                <td className="table-data">
                                  {record.locationDuringCall}
                                  <br />
                                  {record.locationDuringCall2}
                                </td>
                                <td className="table-data">{record.itemId}</td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    });
                  })
                : ""}
            </tbody>
          </table>
        </div>
        <div style={{ float: "right", padding: "0px 30px" }}>
          <Pagination
            current={pageNumber}
            defaultPageSize={recordsPerPage}
            showSizeChanger={true}
            total={totalRecords}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} Records`
            }
            onChange={onShowSizeChange}
            pageSizeOptions={[1000, 1500, 2000]}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectView;
