import React from "react";
import "./style.scss";
import howitworksimg from "../../../images/how-it-work.jpg";
const HowItWorks = () => {
  return (
    <div className="main-container-how-it-works">
      <div className="staticheading">
        <span className="about">How</span>
        <span className="us"> it Works</span>
      </div>
      <div className="m-con">
        <div className="left-right-con">
          <div className="leftie">
            <div className="points">
              <div className="point">
                <span className="number">1</span>
                <span className="descrip">Quickly register and log in.</span>
              </div>
              <div className="point">
                <span className="number">2</span>
                <span className="descrip">
                  Drag and drop your file into the CellNinja report analyzer.
                </span>
              </div>
              <div className="point">
                <span className="number">3</span>
                <span className="descrip">
                  Most reports will process within a few minutes and often in
                  just seconds.
                </span>
              </div>
              <div className="point">
                <span className="number">4</span>
                <span className="descrip">
                  Click your report link, pay, and then download your CellNinja
                  report!{" "}
                </span>
              </div>
            </div>
            <div className="find-relevant-fact">
              <span className="frf-span">
                Find relevant facts effortlessly, and assess potential cases
                faster. Cut through immaterial data to focus on critical
                evidence in minutes.
              </span>
            </div>
          </div>
          <div className="rightie">
            <img className="img-how-it-works" src={howitworksimg} alt="" />
            <div className="facts-container">
              <span className="facts">Fact:</span>
              <span className="people-span">
                People are as impaired when they drive and talk on a cell phone
                as they are when they drive intoxicated at the legal
                blood-alcohol limit of 0.08%.{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
