import React, { useState } from "react";
import "./ScreenFive.scss";
import { Button, Form, Input, Select, Space } from "antd";
import { useSelector } from "react-redux";
import {
  MinusCircleOutlined,
  PlusOutlined,
  TrademarkCircleOutlined,
} from "@ant-design/icons";
import { DATE_REGEX, LATLNG_REGEX, TIME_REGEX } from "../../utils/constants";

const ScreenFive = (props) => {
  const [cellPhoneList, setCellPhoneList] = useState();
  const [selectedItems, setSelectedItems] = useState([]);
  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );

  const checkDisable = (key) => {
    switch (key) {
      case 1:
        if (getSelfServiceEntity.reportType === "basic") {
          return false;
        }
        break;
      case 2:
        if (getSelfServiceEntity.reportType === "basic") {
          return true;
        } else if (getSelfServiceEntity.reportType === "premium") {
          return false;
        }
        break;
      case 3:
        if (getSelfServiceEntity.reportType === "basic") {
          return true;
        } else if (getSelfServiceEntity.reportType === "premium") {
          return false;
        }
        break;
      case 4:
        if (getSelfServiceEntity.reportType === "basic") {
          return true;
        } else if (getSelfServiceEntity.reportType === "premium") {
          return false;
        }
        break;
      case 5:
        if (getSelfServiceEntity.reportType === "basic") {
          return true;
        } else if (getSelfServiceEntity.reportType === "premium") {
          return true;
        }
        break;

      default:
        break;
    }
  };
  return (
    <Form form={props.formData}>
      <div className="main-con-five m-top">
        <div className="content-cont-five">
          <div className="step-i mr-five num-btn num-btn-color ml">2</div>
          <div className="cont-arr-five">
            <div className="vol-II-five">
              <span className="big-head-normal-five">
                <span className="big-head-spec-five">C</span>USTOMIZE
                <span className="big-head-spec-five"> Y</span>OUR
                <span className="big-head-spec-five"> R</span>EPORT
                <span className="fs-five">
                  {" "}
                  <i>(OPTIONAL)</i>
                </span>
              </span>
            </div>
            <div className="five-main">
              <div className="txt-input-d-five">
                <div className="t-five">
                  <span className="head-f-five">
                    <span className="norm-five-head">
                      <span className="spec-five-head">G</span>IVE
                      <span className="spec-five-head"> Y</span>OUR
                      <span className="spec-five-head"> R</span>EPORT A
                      <span className="spec-five-head"> N</span>AME
                    </span>
                  </span>
                  <span className="subhead-f-five">
                    <span className="red-five">
                      The default name is the file name.
                    </span>
                  </span>
                </div>
                <div className="i-five">
                  <Form.Item name="reportName">
                    <Input
                      disabled={checkDisable(1)}
                      className="ifield-five"
                      size="large"
                      placeholder="Enter a name to display on your report header"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="txt-input-d-five">
                <div className="t-five">
                  <span className="head-f-five">
                    <span className="norm-five-head">
                      <span className="spec-five-head">E</span>NTER AN
                      <span className="spec-five-head"> I</span>NCIDENT
                      <span className="spec-five-head"> D</span>ATE
                    </span>
                  </span>
                  <span className="subhead-f-five">
                    <span className="red-five">
                      CellNinja will highlight activities at the time and date
                      of incident.
                    </span>
                  </span>
                </div>
                <div className="i-five">
                  <Form.Item
                    name="incidentDate"
                    rules={[
                      {
                        required: false,
                        message: "Please enter mm/dd/yyyy or m/d/yy",
                        pattern: DATE_REGEX,
                      },
                    ]}
                  >
                    <Input
                      disabled={checkDisable(2)}
                      allowClear
                      className="ifield-five"
                      size="large"
                      placeholder="mm/dd/yyyy or m/d/yy"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="txt-input-d-five">
                <div className="t-five">
                  <span className="head-f-five">
                    <span className="norm-five-head">
                      <span className="spec-five-head">E</span>NTER AN
                      <span className="spec-five-head"> I</span>NCIDENT
                      <span className="spec-five-head"> T</span>IME
                    </span>
                  </span>
                  <span className="subhead-f-five">
                    <span className="red-five">
                      CellNinja will highlight activities at the time of the
                      incident.
                    </span>
                  </span>
                </div>
                <div className="i-five">
                  <Form.Item
                    name="incidentTime"
                    rules={[
                      {
                        required: false,
                        message: "Please enter hh:mm AM|PM or h:mm AM|PM",
                        pattern: TIME_REGEX,
                      },
                    ]}
                  >
                    <Input
                      disabled={checkDisable(3)}
                      allowClear
                      className="ifield-five"
                      size="large"
                      placeholder="hh:mm AM|PM or h:mm AM|PM (local time)"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="txt-input-d-five">
                <div className="t-five">
                  <span className="head-f-five">
                    <span className="norm-five-head">
                      <span className="spec-five-head">E</span>NTER
                      <span className="spec-five-head"> I</span>NCIDENT
                      <span className="spec-five-head"> L</span>OCATION
                    </span>
                  </span>
                  <span className="subhead-f-five">
                    <span className="red-five">
                      CellNinja will highlight on the map.
                    </span>
                  </span>
                </div>
                <div className="i-five">
                  <Form.Item
                    name="incidentLocation"
                    rules={[
                      {
                        required: false,
                        message:
                          "Please enter Street address, city state or longitude, latitude",
                        pattern: LATLNG_REGEX,
                      },
                    ]}
                  >
                    <Input
                      disabled={checkDisable(3)}
                      allowClear
                      className="ifield-five"
                      size="large"
                      placeholder="Street address, city state or longitude, latitude"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="txt-input-d-five">
                <div className="t-five">
                  <span className="head-f-five">
                    <span className="norm-five-head">
                      <span className="spec-five-head">A</span>DD
                      <span className="spec-five-head"> </span>A
                      <span className="spec-five-head"> N</span>AME TO
                      <span className="spec-five-head"> </span>A
                      <span className="spec-five-head"> N</span>UMBER
                    </span>
                  </span>
                  <span className="subhead-f-five">
                    <span className="red-five">
                      CellNinja can display a name with a phone number.
                    </span>
                  </span>
                </div>
                <div className="i-five">
                  <Form.List name="cellPhoneDetail">
                    {(fields, { add, remove }, { errors }) => (
                      <>
                        <Form.Item wrapperCol={{ span: 24 }}>
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Button
                              disabled={checkDisable(4)}
                              type="danger"
                              style={{
                                background: "#fa0000",
                                color: "white",
                                fontSize: "15px",
                                height: "45px",
                                whiteSpace: "pre-wrap",
                              }}
                              onClick={() => add("cellPhoneNumber", 0)}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add Names to Cellphone Numbers
                            </Button>
                          </div>
                        </Form.Item>
                        {fields.length > 0 ? (
                          <div className="custom-scrollable-div">
                            {fields.map((field) => (
                              <Space key={field.key} align="baseline">
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, curValues) =>
                                    prevValues.area !== curValues.area ||
                                    prevValues.sights !== curValues.sights
                                  }
                                >
                                  {() => (
                                    <Form.Item
                                      {...field}
                                      wrapperCol={{ span: 24 }}
                                      name={[field.name, "cellPhoneUserName"]}
                                      rules={[
                                        {
                                          required: TrademarkCircleOutlined,
                                          message:
                                            "Missing cell phone username",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="User Name" />
                                    </Form.Item>
                                  )}
                                </Form.Item>
                                <Form.Item
                                  {...field}
                                  wrapperCol={{ span: 24 }}
                                  name={[field.name, "cellPhoneNumber"]}
                                  rules={
                                    !cellPhoneList
                                      ? [
                                          {
                                            required: true,
                                            pattern: new RegExp(
                                              /^(?:\d{3}-\d{3}-\d{4}|\(\d{3}\)\s?\d{3}-\d{4}|\d{10})$/
                                            ),
                                            message:
                                              "Missing cell phone number",
                                          },
                                        ]
                                      : []
                                  }
                                >
                                  {cellPhoneList ? (
                                    <Select
                                      showSearch
                                      placeholder="Phone Numbers"
                                      value={selectedItems}
                                      onChange={setSelectedItems}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      {cellPhoneList
                                        ? cellPhoneList.map((item) => (
                                            <Select.Option
                                              key={item}
                                              value={item}
                                            >
                                              {item}
                                            </Select.Option>
                                          ))
                                        : []}
                                    </Select>
                                  ) : (
                                    <Input placeholder="Phone Number" />
                                  )}
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => remove(field.name)}
                                />
                              </Space>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                        <Form.ErrorList errors={errors} />
                      </>
                    )}
                  </Form.List>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default ScreenFive;
