import React from 'react'
import img1 from '../../../images/about-icon.png'
import img2 from '../../../images/detctive.jpg'
import './style.scss'

const AboutUs = () => {
    return (
        <div className="main-container-aboutus">
            <div className="content-container-aboutus">
                <div className="heading-con-aboutus">
                    <span className="about">About</span>
                    <span className="us"> Us</span>
                </div>
                <div className="about-us-content-con">
                    <div className="left-sec">
                        <p className="paraI">
                            The CellNinjas are experts from the cellphone industry who enjoy
                            nothing more than applying their skills to solving problems like
                            making complex call records simple. No one knows cellphone records
                            better! Our cellular network engineers helped develop the call
                            records used by cellphone companies worldwide.
                        </p>
                        <span className="subject-adjuster">
                            Our subject-matter expertise includes:
                        </span>
                        <ul className="list-container">
                            <li>30 patents in cellphone technology</li>
                            <li>
                                Training law enforcement in cellphone record interpretation
                            </li>
                            <li>Providing litigation support in state and Federal courts</li>
                            <li>
                                Assisting the Federal Government with regulatory and technical
                                support
                            </li>
                        </ul>
                        <div className="img-con">
                            <img className="img-adjuster" src={img1} alt="" />
                            <img src={img2} alt="" />
                        </div>
                    </div>
                    <div className="right-sec">
                        <p className="paraI">
                            As former employees of cellphone companies including Verizon,
                            Sprint, AT&T and others, we have unique expertise in designing
                            today’s cellular networks that are used worldwide. Our combined
                            100 years of experience includes:
                        </p>
                        <ul className="list-container">
                            <li>
                                Cell Network System Design / Location-based Services (LBS)
                            </li>
                            <li>
                                Specialized knowledge of cellular call flows and protocols for
                                voice, text, data, and location services
                            </li>
                            <li>Billing systems and standards development</li>
                            <li>Call Detail Records (CDR) and analysis</li>
                            <li>3, 4,& 5G, LTE Wireless broadband communications</li>
                            <li>US wiretap system (CALEA)</li>
                            <li>Wireless 911 system</li>
                            <li>
                                GPS, Internet, Wi-Fi, data, broadband, spoofing and roaming
                            </li>
                            <li>
                                Internet, Wi-Fi, data, broadband, spoofing and roaming that are
                                used worldwide.
                            </li>
                            <li>
                                Cellular Intellectual Property and cellular patent prosecution
                            </li>
                        </ul>
                        <div className="fact-container">
                            <span className="fact">Fact:</span>
                            <span>
                                Cell phone users are 5.36 times more likely to get into an
                                accident than undistracted drivers.{" "}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AboutUs;