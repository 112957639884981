import { SET_ENTITY, RESET_ENTITIES } from "./../utils/constants";

/**
 *
 *  SERVER DATA REDUCER
 *
 */

const initialState = {
  entities: {
    // countries: []
  },
};

const data = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ENTITY:
      return {
        ...state,
        entities: { ...state.entities, [payload.key]: payload.value },
      };

    case RESET_ENTITIES:
      return {
        ...state,
        entities: {},
      };

    default:
      return state;
  }
};

export default data;
