import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, message, Spin, Table } from "antd";
import Form from "antd/lib/form/Form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  generatePdfTowerReport,
  getUniqiueTowersLocation,
} from "../../apis/projectapi";
import { checkToken, downloadedFile } from "../../functions/functions";
import { CELLNINJACOLOR } from "../../utils/constants";

const ProjectTowers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingpdf, setLoadingPdf] = useState(false);

  const loadUTL = () => {
    if (location?.state?.projectId) {
      getUniqiueTowersLocation(location?.state?.projectId).then((res) => {
        if (res.status === 200) {
          setData(res.data);
          setLoading(false);
        }
      });
    }
  };
  const generatingPdf = () => {
    setLoadingPdf(true);
    let pos = location?.state?.name.lastIndexOf(".");
    var projectName =
      "Tower Report_" +
      location?.state?.name.substr(
        0,
        pos < 0 ? location?.state?.name.length : pos
      ) +
      ".pdf";
    generatePdfTowerReport(location?.state?.projectId)
      .then((response) => {
        setLoadingPdf(false);
        downloadedFile(response, projectName);
      })
      .catch(async (error) => {
        var resBlob = error.response.data;
        var tested = await resBlob.text();
        message.error(tested);
      });
  };

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    loadUTL();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const columns = [
    {
      title: "Cell Id",
      dataIndex: "cellId",
      key: "cell_id",
      align: "center",
      width: "12%",
    },
    {
      title: "Tower No",
      dataIndex: "towerNo",
      key: "towers no",
      align: "center",
      width: "5%",
    },
    {
      title: "Latitude",
      dataIndex: "latitude",
      key: "name",
      align: "center",
      width: "10%",
    },
    {
      title: "Longitude",
      dataIndex: "longitude",
      key: "name",
      align: "center",
      width: "10%",
    },
    {
      title: "Uses",
      dataIndex: "uses",
      key: "uses",
      align: "center",
      width: "5%",
    },
    {
      title: "Physical Address",
      dataIndex: "physicalAddress",
      key: "physical address",
      align: "center",
      width: "44%",
    },
    {
      title: "City/State",
      dataIndex: "cityState",
      key: "city",
      align: "center",
      width: "10%",
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ textAlign: "left", display: "flex" }}>
          <ArrowLeftOutlined
            style={{
              marginTop: "5px",
              marginRight: "10px",
            }}
            onClick={() => navigate("/user/home/projects")}
          />
          <h1>Unique Towers</h1>
        </div>
        <div style={{ marginLeft: "32%" }}>
          <Spin
            spinning={loadingpdf}
            style={{ color: CELLNINJACOLOR }}
            tip="Generating PDF..."
          />
        </div>
        <div style={{ marginLeft: "auto", marginRight: "2%" }}>
          <Button
            shape="round"
            type="danger"
            style={{ background: CELLNINJACOLOR }}
            onClick={generatingPdf}
          >
            PDF
          </Button>
        </div>
      </div>
      <Form component={false} name="">
        <Table
          scroll={{ y: "67vh" }}
          loading={loading}
          style={{
            margin: "0.5% 0%",
          }}
          bordered
          className="admin-table-container"
          rowKey={"id"}
          rowClassName="editable-row"
          key="1"
          size="middle"
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </Form>
    </React.Fragment>
  );
};
export default ProjectTowers;
