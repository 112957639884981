import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getCustomerMessage = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/open/fetchcustomermessages", {
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
};

export const getCustomerMessages = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/fetchcustomermessages", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const getSpecificCustomerMessages = (value, authorized) => {
  const apiUrl = authorized
    ? APP_CONTEXT_URL + "/v1/open/fetchcustomermessages/" + value
    : APP_CONTEXT_URL + "/v1/fetchcustomermessages/" + value;

  const headers = authorized
    ? {
        "Access-Control-Allow-Origin": "*",
      }
    : {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      };

  return axios.get(apiUrl, { headers });
};

export const updateCustomerMessages = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updatecustomermessages", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};
