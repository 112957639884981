import axios from "axios";
import { resetEntities } from "./../actions/data";
import { dispatch } from "./../store/index";
import { clearAuth } from "./../utils/authToken";
export function initAxiosInterceptors(navigate) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        window.location.href = "/user/login";
        navigate("/user/login");
        window.location.reload();
        clearAuth();
        dispatch(resetEntities());
      }

      return Promise.reject(error);
    }
  );
}
