import React from "react";
import ScreenOne from "./ScreenOne";
import ScreenTwo from "./ScreenTwo";
import ScreenFour from "./ScreenFour";
import ScreenFive from "./ScreenFive";
import ScreenSix from "./ScreenSix";

export const ScreenRender = (key, formData) => {
  if (key === 1) {
    return (
      <React.Fragment>
        <ScreenOne />
      </React.Fragment>
    );
  } else if (key === 2) {
    return (
      <React.Fragment>
        {/* Report Features + Type */}
        <ScreenFour />
      </React.Fragment>
    );
  } else if (key === 3) {
    return (
      <React.Fragment>
        {/* Customize Report */}
        <ScreenFive formData={formData} />
      </React.Fragment>
    );
  } else if (key === 4) {
    return (
      <React.Fragment>
        {/* CDR File */}
        <ScreenTwo />
      </React.Fragment>
    );
  } else if (key === 5) {
    return (
      <React.Fragment>
        {/* Payment Screen */}
        <ScreenSix />
      </React.Fragment>
    );
  }
};
