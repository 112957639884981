import React from "react";
import "./HeaderNew.scss";

const HeaderNew = () => {
  return (
    <div>
      <div className="above-head">
        <span className="aboveHeadNormalFont">
          <span className="aboveHeadCustomFont">G</span>ET{" "}
          <span className="aboveHeadCustomFont">S</span>TARTED
        </span>
      </div>
    </div>
  );
};

export default HeaderNew;
