/**
 * Application redux store with configured middlewares and reducers.
 */

import {
  applyMiddleware,
  legacy_createStore as createStore,
  compose,
} from "redux";
import reducers from "./../reducers/index.jsx";
import { thunk } from "redux-thunk";

const initialState = {};

const store = createStore(
  reducers,
  initialState,
  compose(applyMiddleware(thunk))
);

export const dispatch = store.dispatch;

export default store;
