import { combineReducers } from "redux";
import SELFSERVICEREDUCER from "./selfServiceEntityReducer";

/**
 *  Create a combine reducers
 */
import app from "./app";
import data from "./data";

const index = combineReducers({
  app,
  data,
  selfServiceEntity: SELFSERVICEREDUCER,
});

export default index;
