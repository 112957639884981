import { update } from "lodash";
import {
  SET_SELFSERVICE,
  RESET_SELFSERVICE,
  DELETE_SELFSERVICE,
} from "./../utils/constants";

/**
 *
 *  SERVER DATA REDUCER
 *
 */

const initialState = {
  entities: {
    file: null,
    fileName: null,
    key: null,
    generateReport: null,
    reportType: null,
    customizeReport: {
      reportName: "",
      incidentDate: "",
      incidentTime: "",
      incidentLocation: "",
      cellPhoneDetail: [],
      lookUpNumber: "",
    },
    uploadedFiles: {
      voice: "",
      data: "",
      sms: "",
      ipSessions: "",
      tower: "",
    },
    reportPayment: false,
    cdrType: "",
    filesToUpload: [],
  },
};

const data = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SELFSERVICE:
      return {
        ...state,
        entities: { ...state.entities, [payload.key]: payload.value },
      };

    case RESET_SELFSERVICE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default data;
