import React, { useState, useEffect } from "react";
import "./style.scss";
import {
  Button,
  Tag,
  Modal,
  Upload,
  Spin,
  Card,
  Popover,
  Pagination,
  Tooltip,
  message,
  Row,
  Col,
  Typography,
  Select,
  Checkbox,
} from "antd";
// import "antd/dist/antd.css";
import {
  FileExcelOutlined,
  FilePdfOutlined,
  PhoneOutlined,
  WifiOutlined,
  ImportOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { DeleteOutlined } from "@ant-design/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  APP_CONTEXT_URL,
  DOWNLOAD_FINAL_REPORT_EXCEL,
  DOWNLOAD_FINAL_REPORT_PDF,
  VIEW_UNIQUE_PHONE_NUMBER,
  VIEW_UNIQUE_TOWER_LOCATION,
  DELETE_PROJECT,
  CELLNINJACOLOR,
} from "../../utils/constants";
import {
  generateExcelTemplate,
  generatePdfTemplate,
  getAllProjects,
  getProjectsCount,
  checkProjectExists,
  cdrFileParserApi,
  deleteProjectById,
} from "../../apis/projectapi";
import { dispatch } from "../../store/index";
import { saveEntityData } from "../../actions/data";
import { checkToken, downloadedFile } from "../../functions/functions";
import { WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import { fetchCustomerMessages } from "../app/mainIndex";

const Projects = (props) => {
  const navigate = useNavigate();
  const [projectCount, setProjectCount] = useState();
  const [project, setProject] = useState({});
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingexcel, setLoadingExcel] = useState(false);
  const [loadingpdf, setLoadingPdf] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [fileName, setFileName] = useState();
  const [cdrParserFile, setCdrParserFile] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const [viewUniquePhoneNumber, setViewUniquePhoneNumber] = useState(false);
  const [viewUniqueTowerLocation, setViewUniqueTowerLocation] = useState(false);
  const [deleteProjectPermission, setDeleteProjectPermission] = useState(false);
  const [existingProjectMessage, setExistingProjectMessage] = useState();
  const [sortBy, setSortBy] = useState("date");
  const [orderBy, setOrderBy] = useState(false);

  useEffect(() => {
    loadProjects(pageNumber, recordsPerPage, sortBy, orderBy);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [sortBy, orderBy]);

  const projects = useSelector(({ data }) =>
    data.entities.projects ? data.entities.projects : []
  );

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          uniquePermissions[permission.id] = permission;
          if (permission.name === VIEW_UNIQUE_PHONE_NUMBER) {
            setViewUniquePhoneNumber(true);
          }
          if (permission.name === VIEW_UNIQUE_TOWER_LOCATION) {
            setViewUniqueTowerLocation(true);
          }
          if (permission.name === DELETE_PROJECT) {
            setDeleteProjectPermission(true);
          }
        });
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  useEffect(() => {
    fetchPermissions();
    projectsCount(false);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser]);

  useEffect(() => {
    checkToken(navigate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const projectSummary = (voice, data, sms) => {
    var summary = {
      Voice: voice,
      Data: data,
      SMS: sms,
    };

    return Object.keys(summary).map((category) => {
      return (
        <Tag key={category}>
          {category}: &nbsp; {summary[category]}
        </Tag>
      );
    });
  };

  const content = (project) => {
    return (
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          padding: "12px 16px",
        }}
      >
        <div>
          {permissions.map((permission) => {
            return permission.name === VIEW_UNIQUE_PHONE_NUMBER ? (
              <Tooltip title="UPN" placement="right">
                <Button
                  style={{ marginRight: "5px" }}
                  shape="round"
                  className="ant-button"
                  icon={<PhoneOutlined />}
                  onClick={(e) => {
                    getAllPhoneNumbers(project);
                    e.stopPropagation();
                  }}
                ></Button>
              </Tooltip>
            ) : (
              ""
            );
          })}
        </div>
        <div>
          {permissions.map((permission) => {
            return permission.name === VIEW_UNIQUE_TOWER_LOCATION ? (
              <Tooltip title="UTL" placement="right">
                <Button
                  style={{ marginRight: "5px" }}
                  shape="round"
                  className="ant-button"
                  icon={<WifiOutlined />}
                  onClick={(e) => {
                    getAllUniqueTowers(project);
                    e.stopPropagation();
                  }}
                ></Button>
              </Tooltip>
            ) : (
              ""
            );
          })}
        </div>
        <div>
          {permissions.map((permission) => {
            return permission.name === DELETE_PROJECT ? (
              <Tooltip title="Delete Project" placement="right">
                <Button
                  type="danger"
                  onClick={(e) => {
                    setConfirmDeleteModal(true);
                    setProject(project);
                    e.stopPropagation();
                  }}
                  className="delete-project-ant-btn"
                  icon={<DeleteOutlined />}
                  shape="round"
                ></Button>
              </Tooltip>
            ) : (
              ""
            );
          })}
        </div>
      </div>
    );
  };

  const viewTemplates = (project) => {
    setLoadingExcel(true);
    let pos = project.name.lastIndexOf(".");
    var projectName =
      project.name.substr(0, pos < 0 ? project.name.length : pos) + ".xlsx";
    generateExcelTemplate(project.id)
      .then((response) => {
        setLoadingExcel(false);
        downloadedFile(response, projectName);
      })
      .catch(async (error) => {
        var resBlob = error.response.data;
        var tested = await resBlob.text();
        message.error(tested);
      });
  };

  const downloadPdf = (project) => {
    setLoadingPdf(true);
    let pos = project.name.lastIndexOf(".");
    var projectName =
      "FINAL REPORT_" +
      project.name.substr(0, pos < 0 ? project.name.length : pos) +
      ".pdf";
    generatePdfTemplate(project.id)
      .then((response) => {
        setLoadingPdf(false);
        downloadedFile(response, projectName);
      })
      .catch(async (error) => {
        var resBlob = error.response.data;
        var tested = await resBlob.text();
        message.error(tested);
      });
  };

  const getAllPhoneNumbers = (project) => {
    if (project) {
      navigate("/user/home/uniquephonenumbers", {
        state: {
          projectName: project.name,
          projectId: project.id,
        },
      });
    }
  };

  const getAllUniqueTowers = (project) => {
    if (project) {
      navigate("/user/home/uniquetowers", {
        state: {
          name: project.name,
          projectId: project.id,
        },
      });
    }
  };

  const deleteProject = async (project) => {
    if (project) {
      await deleteProjectById(project.id)
        .then((response) => {
          if (response.status === 200) {
            projectsCount(false);
          }
        })
        .catch((error) => {
          message.error(error.response.data);
        });
    }
  };

  const onShowSizeChange = (pageNumber, recordsPerPage) => {
    setPageNumber(pageNumber);
    setRecordsPerPage(recordsPerPage);
    loadProjects(pageNumber, recordsPerPage, sortBy, orderBy);
  };

  const loadProjects = async (current, pageSize, sortBy, orderBy) => {
    setLoading(true);
    await getAllProjects(current, pageSize, sortBy, orderBy).then(
      (response) => {
        if (response.status === 200) {
          dispatch(saveEntityData("projects", response.data));
        }
        setLoading(false);
      }
    );
  };

  const projectsCount = (value) => {
    setLoading(true);
    getProjectsCount().then((response) => {
      if (response.data != null) {
        if (value) message.success(response.data.message);
        setProjectCount(response.data.object);
        loadProjects(pageNumber, recordsPerPage, sortBy, orderBy);
      }
    });
  };

  const uploadProps = {
    name: "file",
    showUploadList: false,
    action: APP_CONTEXT_URL + "/v1/parsePDF/" + false,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
    credentials: "include",
    onChange(info) {
      if (info.file.status === "done") {
        setIsLoading(false);
        projectsCount(true);
      } else if (info.file.status === "error") {
        message.error(info.file.response);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    },
  };

  const loadingIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
  );

  const onModalBoxOk = async () => {
    setShowConfirmationModal(false);
    setLoading(true);
    const res = await cdrFileParserApi(true, cdrParserFile);
    if (res.status === 200) {
      message.success(
        `${cdrParserFile.name.slice(0, -4)} ` + existingProjectMessage
      );
      setLoading(false);
      setIsLoading(false);
      loadProjects(1, recordsPerPage, sortBy, orderBy);
    }
  };

  const handleChange = (value) => {
    setSortBy(value);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ textAlign: "left" }}>
          <h1>Projects</h1>
        </div>
        <div style={{ marginLeft: "37%" }}>
          <Spin
            spinning={loading}
            style={{ color: CELLNINJACOLOR }}
            tip="Loading Projects..."
          />
          <Spin
            spinning={loadingexcel}
            style={{ color: CELLNINJACOLOR }}
            tip="Generating Excel..."
          />
          <Spin
            spinning={loadingpdf}
            style={{ color: CELLNINJACOLOR }}
            tip="Generating PDF..."
          />
        </div>
        <div style={{ marginLeft: "auto", marginRight: "2%" }}>
          <Upload
            {...uploadProps}
            disabled={isLoading}
            beforeUpload={async (file, fileList) => {
              setCdrParserFile(file);

              const projectExists = await checkProjectExists(
                file.name.slice(0, -4)
              );
              if (projectExists.status === 200)
                if (projectExists.data) {
                  setFileName(
                    '"' +
                      file.name.substring(0, 20) +
                      ' ... "' +
                      " already exists!"
                  );
                  setExistingProjectMessage(projectExists.data.message);
                  setShowConfirmationModal(true);
                  return false;
                } else return true;
              return true;
            }}
          >
            <Button
              style={{
                marginLeft: "-6px",
                background: "#2980b9",
                borderColor: "#2980b9",
                color: "white",
              }}
              shape="round"
              type="danger"
              icon={<ImportOutlined />}
            >
              Import CDR File &nbsp;&nbsp;
              <Spin indicator={loadingIcon} spinning={isLoading} />
            </Button>
          </Upload>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
          marginTop: "1rem",
          marginRight: "2rem",
        }}
      >
        <div>
          <Typography>Sort By:</Typography>
        </div>
        <div>
          <Select
            defaultValue={sortBy === "id" ? "" : sortBy}
            style={{ width: "13rem" }}
            onChange={handleChange}
          >
            <Select.Option value="name">Project Name</Select.Option>
            <Select.Option value="size">File Size</Select.Option>
            <Select.Option value="date">Run Date</Select.Option>
            <Select.Option value="time">Run Time</Select.Option>
          </Select>
        </div>
        <div>
          <Checkbox
            checked={orderBy}
            onChange={() => {
              setOrderBy(!orderBy);
            }}
          >
            Older First
          </Checkbox>
        </div>
      </div>
      <div
        style={{
          overflow: "overlay",
          margin: "5px 0px",
          height: "62vh",
        }}
      >
        {projects?.map((project) => {
          return (
            <>
              <Card
                className="project-main-card"
                onClick={(e) => {
                  e.stopPropagation();
                  props.projectView(project);
                }}
              >
                <Row>
                  <Col xs={4}>
                    <p className="heading">Project Name</p>
                    <p className="project-name-para">
                      {project.name ? project.name : ""}
                    </p>
                  </Col>
                  <Col xs={2}>
                    <p className="heading">Processing Time</p>
                    <p className="project-name-para">
                      {project.processingTime
                        ? `${moment
                            .duration(project.processingTime)
                            .asSeconds()} s`
                        : ""}
                    </p>
                  </Col>
                  <Col xs={2}>
                    <p className="heading">File Size</p>
                    <p className="project-name-para">
                      {project.fileSize
                        ? `${(project.fileSize / 1024).toFixed(2)} kb`
                        : ""}
                    </p>
                  </Col>
                  <Col xs={2}>
                    <p className="heading">Run Date</p>
                    <p className="project-name-para">
                      {project.runDate
                        ? moment(project.runDate).format("MM/DD/yyyy")
                        : ""}
                    </p>
                  </Col>
                  <Col xs={2}>
                    <p className="heading">Run Time</p>
                    <p className="project-name-para">
                      {project.runTime
                        ? moment(project.runTime, "hh:mm:ss.SSS").format(
                            "hh:mm:ss a"
                          )
                        : ""}
                    </p>
                  </Col>
                  <Col xs={3}>
                    <p className="heading">Total CDR Record(s)</p>
                    <div className="source-report-div">
                      <div className="source-report-tag-div">
                        {project.totalCdrRecords ? project.totalCdrRecords : ""}
                      </div>
                    </div>
                  </Col>
                  <Col xs={5}>
                    <p className="heading">Summary</p>
                    <div className="summary">
                      {projectSummary(
                        project.totalVoiceRecords,
                        project.totalDataRecords,
                        project.totalSmsRecords
                      )}
                    </div>
                  </Col>
                  <Col xs={4} style={{ display: "flex", alignItems: "center" }}>
                    {permissions.map((permission) => {
                      return permission.name === DOWNLOAD_FINAL_REPORT_EXCEL ? (
                        <Tooltip title="Download Excel">
                          <Button
                            className="ant-button"
                            icon={<FileExcelOutlined />}
                            shape="round"
                            onClick={(e) => {
                              viewTemplates(project);
                              e.stopPropagation();
                            }}
                          ></Button>
                        </Tooltip>
                      ) : (
                        ""
                      );
                    })}
                    {permissions.map((permission) => {
                      return permission.name === DOWNLOAD_FINAL_REPORT_PDF ? (
                        <Tooltip title="Download Final Report">
                          <Button
                            className="ant-button"
                            icon={<FilePdfOutlined />}
                            shape="round"
                            onClick={(e) => {
                              downloadPdf(project);
                              e.stopPropagation();
                            }}
                          ></Button>
                        </Tooltip>
                      ) : (
                        ""
                      );
                    })}
                    {viewUniquePhoneNumber === false &&
                    viewUniqueTowerLocation === false &&
                    deleteProjectPermission === false ? (
                      ""
                    ) : (
                      <Popover content={() => content(project)}>
                        <Button
                          shape="round"
                          className="ant-button"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <MoreVertIcon style={{ width: "16px" }} />
                        </Button>
                      </Popover>
                    )}
                  </Col>
                </Row>
              </Card>
            </>
          );
        })}
      </div>
      <div>
        <Modal
          style={{
            align: "center",
          }}
          title={project.name}
          open={confirmDeleteModal}
          okButtonProps={{
            style: { backgroundColor: "#2980b9", borderColor: "#2980b9" },
          }}
          onOk={(e) => {
            deleteProject(project);
            setConfirmDeleteModal(false);
            setLoading(true);
            e.stopPropagation();
          }}
          onCancel={(e) => {
            setConfirmDeleteModal(false);
            e.stopPropagation();
          }}
          destroyOnClose
          autoFocus
        >
          <div style={{ display: "flex" }}>
            <div>
              <WarningOutlined
                style={{ fontSize: "45px", color: "rgb(220 220 5)" }}
              />
            </div>
            <div style={{ marginLeft: "4%", textAlignLast: "left" }}>
              <h3 style={{ margin: "0px" }}>
                Are you sure you want to delete?
              </h3>
              You cannot undo this action.
            </div>
          </div>
        </Modal>
      </div>
      <div style={{ float: "right", padding: "0px 30px" }}>
        <Pagination
          current={pageNumber}
          showSizeChanger={true}
          total={projectCount}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} Records`
          }
          onChange={onShowSizeChange}
          pageSizeOptions={[10, 30, 50]}
        />
      </div>
      <div>
        <Modal
          style={{
            align: "center",
          }}
          title={fileName}
          open={showConfirmationModal}
          onOk={onModalBoxOk}
          okButtonProps={{
            style: {
              backgroundColor: "#2980b9",
              borderColor: "#2980b9",
            },
          }}
          onCancel={() => {
            setShowConfirmationModal(false);
            setIsLoading(false);
          }}
          destroyOnClose
          autoFocus
        >
          <div style={{ display: "flex" }}>
            <div>
              <WarningOutlined
                style={{ fontSize: "45px", color: "rgb(220 220 5)" }}
              />
            </div>
            <div style={{ marginLeft: "4%", textAlignLast: "left" }}>
              <h3 style={{ margin: "0px" }}>
                Are you sure you want to overwrite project?
              </h3>
              You cannot undo this action.
            </div>
          </div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default Projects;
