// import { Button, Form, Input, message, Table } from "antd";
// import React, { useState } from "react";
// import { getLookUpRecord, phoneLookUp } from "../../../apis/lookupservice";

// const LookUp = () => {
//   const [apiLoading, setApiLoading] = useState(false);
//   const [fetchLoading, setFetchLoading] = useState(false);
//   const [key, setKey] = useState();
//   const [data, setData] = useState();
//   const [fileName, setFileName] = useState([]);
//   const [form] = Form.useForm();

//   const onSubmit = (values) => {
//     // form.validateFields().then((values) => {
//     if (
//       values.phoneNumber1 ||
//       values.phoneNumber2 ||
//       values.phoneNumber3 ||
//       values.phoneNumber4 ||
//       values.phoneNumber5
//     ) {
//       let value = [
//         values.phoneNumber1,
//         values.phoneNumber2,
//         values.phoneNumber3,
//         values.phoneNumber4,
//         values.phoneNumber5,
//       ];
//       let key = crypto.randomUUID();
//       setKey(key);
//       phoneLookUp(value, key)
//         .then((response) => {
//           setApiLoading(true);
//           setFileName(response.data);
//         })
//         .catch((e) => {
//           message.error(e);
//         });
//     } else {
//       message.error("Enter number in LookUp Field(s).");
//     }
//     // });
//   };

//   const formItemLayout = {
//     labelCol: { span: 9 },
//     wrapperCol: { span: 15 },
//     labelAlign: "right",
//     name: "user-form",
//   };

//   const columns = [
//     {
//       title: (
//         <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
//           <strong>Phone Number</strong>
//         </div>
//       ),
//       dataIndex: "phoneNumber",
//       key: "phoneNumber",
//       // fixed: "left",
//       // width: "18%",
//       render: (phoneNumber) => (
//         console.log(phoneNumber),
//         (
//           <React.Fragment>
//             <div style={{ fontSize: "10.5px" }}>
//               {phoneNumber ? phoneNumber : "-"}
//             </div>
//           </React.Fragment>
//         )
//       ),
//     },
//     {
//       title: (
//         <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
//           <strong> Name</strong>
//         </div>
//       ),
//       dataIndex: "fullName",
//       key: "fullName",
//       // fixed: "left",
//       // width: "18%",
//       render: (fullName) => (
//         console.log(fullName),
//         (
//           <React.Fragment>
//             <div style={{ fontSize: "10.5px" }}>
//               {fullName ? fullName : "-"}
//             </div>
//           </React.Fragment>
//         )
//       ),
//     },
//   ];

//   const fetchLookUpData = () => {
//     setFetchLoading(true);
//     getLookUpRecord(fileName)
//       .then((response) => {
//         if (response.status === 200) {
//           setFetchLoading(false);
//           setData(response.data);
//         }
//       })
//       .catch((e) => {
//         setFetchLoading(false);
//         message.info("Please wait while data is being processed");
//         // message.error(e);
//       });
//   };

//   const resetState = () => {
//     setApiLoading(false);
//     setFetchLoading(false);
//     setKey();
//     setData();
//     setFileName([]);
//     form.resetFields();
//   };

//   return (
//     <React.Fragment>
//       {/* <Spin spinning={apiLoading}> */}

//       <div style={{ float: "right", marginRight: "20px" }}>
//         <Button onClick={() => resetState()}>Clear All</Button>
//       </div>
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           // marginLeft: "8em",
//           // marginTop: "10%",
//           justifyContent: "space-around",
//           margin: "10em",
//         }}
//       >
//         <div
//           style={{
//             height: "352px",
//             width: "520px",
//             pointerEvents: apiLoading ? "none" : "",
//             opacity: apiLoading ? "0.5" : "",
//           }}
//         >
//           <Form {...formItemLayout} form={form} onFinish={onSubmit}>
//             <div
//               style={{
//                 display: "flex",
//                 justifyItems: "center",
//                 alignItems: "center",
//                 gap: "2rem",
//                 margin: "3rem",
//               }}
//             >
//               <div>
//                 <Form.Item name="phoneNumber1" label="Phone Number 1">
//                   <Input maxLength={512} />
//                 </Form.Item>
//                 <Form.Item name="phoneNumber2" label="Phone Number 2">
//                   <Input maxLength={512} />
//                 </Form.Item>
//                 <Form.Item name="phoneNumber3" label="Phone Number 3">
//                   <Input maxLength={512} />
//                 </Form.Item>
//                 <Form.Item name="phoneNumber4" label="Phone Number 4">
//                   <Input maxLength={512} />
//                 </Form.Item>
//                 <Form.Item name="phoneNumber5" label="Phone Number 5">
//                   <Input maxLength={512} />
//                 </Form.Item>
//               </div>
//               <div>
//                 <Button htmlType="submit" style={{ width: "6em" }}>
//                   Look Up
//                 </Button>
//                 <div>
//                   <br />
//                 </div>
//                 <Button htmlType="reset" style={{ width: "6em" }}>
//                   Clear{" "}
//                 </Button>
//               </div>
//             </div>
//           </Form>
//         </div>
//         <div
//           style={{
//             height: "352px",
//             width: "610px",

//             pointerEvents: apiLoading ? "" : "none",
//             opacity: apiLoading ? "" : "0.5",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               placeItems: "center",
//               gap: "2rem",
//               marginTop: "12%",
//             }}
//           >
//             <div>
//               <Button
//                 // style={{ width: "6em" }}
//                 onClick={() => fetchLookUpData()}
//               >
//                 Retrive Data
//               </Button>
//             </div>
//             <div>
//               <Table
//                 bordered
//                 columns={columns}
//                 dataSource={data}
//                 style={{ width: "30rem" }}
//                 loading={fetchLoading}
//                 pagination={false}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* </Spin> */}
//     </React.Fragment>
//   );
// };

// export default LookUp;

import React, { useEffect, useState } from "react";
import { Button, Form, Input, Spin, message, Table } from "antd";
import { loopUpService } from "../../../apis/lookupservice";
import moment from "moment";

const LookUp = () => {
  const [jsonData, setJsonData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  var jsondata = [];

  const getDate = (date) => {
    if (date === 0) {
      return "-";
    } else {
      return moment(date.toString()).format("MM/DD/YYYY");
    }
  };
  useEffect(() => {
    if (jsonData) {
      console.log(jsonData);
      let name = "";
      let number = "";
      let carrier = "";
      let lastSeen = "";
      let firstSeen = "";
      // eslint-disable-next-line array-callback-return
      jsonData?.result?.map((json) => {
        // eslint-disable-next-line array-callback-return
        json.phone.map((phoneList) => {
          let comma;
          if (name === "") {
            comma = "";
          } else {
            // eslint-disable-next-line no-unused-vars
            comma = " , ";
          }
          // if (phoneList.number === phone) {
          // name = name + comma + json.name[0]?.data;
          name =
            name === "" ? json.name[0]?.data : name + "\n" + json.name[0]?.data;
          number =
            number === ""
              ? // phoneList.number !== phone
                // ?
                phoneList?.number
              : // : ""
                number +
                "\n" +
                // phoneList.number !== phone
                // ?
                phoneList?.number;
          // : ""
          carrier =
            carrier === ""
              ? phoneList?.providerName
              : carrier + "\n" + phoneList?.providerName;
          lastSeen =
            lastSeen === ""
              ? // ? phoneList?.meta?.lastSeen
                getDate(phoneList?.meta?.lastSeen)
              : lastSeen +
                "\n" +
                //  phoneList?.meta?.lastSeen;
                getDate(phoneList?.meta?.lastSeen);
          firstSeen =
            firstSeen === ""
              ? // ? phoneList?.meta?.firstSeen
                getDate(phoneList?.meta?.firstSeen)
              : firstSeen +
                "\n" +
                //  phoneList?.meta?.firstSeen;
                getDate(phoneList?.meta?.firstSeen);
          // }
        });
      });
      jsondata.push({
        fullName: name,
        phoneNumber: phone,
        otherNumber: number,
        providerName: carrier,
        lastSeen: lastSeen,
        firstSeen: firstSeen,
      });
      console.log(jsondata);
      setData(jsondata);
    }
  }, [jsonData, jsondata, phone]);

  const columns = [
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          <strong>Phone Number</strong>
        </div>
      ),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
      // fixed: "left",
      // width: "18%",
      render: (phoneNumber) => (
        // console.log(phoneNumber),
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>
            {phoneNumber ? phoneNumber : "-"}
          </div>
        </React.Fragment>
      ),
    },
    // {
    //   title: (
    //     <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
    //       <strong>Other Number</strong>
    //     </div>
    //   ),
    //   dataIndex: "otherNumber",
    //   key: "otherNumber",
    //   // fixed: "left",
    //   // width: "18%",
    //   render: (otherNumber) => (
    //     <React.Fragment>
    //       <div style={{ fontSize: "10.5px" }}>
    //         {otherNumber ? otherNumber : "-"}
    //       </div>
    //     </React.Fragment>
    //   ),
    // },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          <strong>Name</strong>
        </div>
      ),
      dataIndex: "fullName",
      key: "fullName",
      align: "center",
      // fixed: "left",
      // width: "18%",
      render: (fullName) => (
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>{fullName ? fullName : "-"}</div>
        </React.Fragment>
      ),
    },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          <strong>Telephone Company</strong>
        </div>
      ),
      dataIndex: "providerName",
      key: "providerName",
      align: "center",
      // fixed: "left",
      // width: "18%",
      render: (providerName) => (
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>
            {providerName ? providerName : "-"}
          </div>
        </React.Fragment>
      ),
    },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          <strong>First Seen</strong>
        </div>
      ),
      dataIndex: "firstSeen",
      key: "firstSeen",
      align: "center",
      // fixed: "left",
      // width: "18%",
      render: (firstSeen) => (
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>
            {firstSeen ? firstSeen : "-"}
          </div>
        </React.Fragment>
      ),
    },
    {
      title: (
        <div style={{ fontSize: "larger", color: "black", fontWeight: "500" }}>
          <strong>Last Seen</strong>
        </div>
      ),
      dataIndex: "lastSeen",
      key: "lastSeen",
      align: "center",
      // fixed: "left",
      // width: "18%",
      render: (lastSeen) => (
        <React.Fragment>
          <div style={{ fontSize: "10.5px" }}>{lastSeen ? lastSeen : "-"}</div>
        </React.Fragment>
      ),
    },
  ];

  const onSubmit = () => {
    form.validateFields().then((values) => {
      setPhone(values.phoneNumber);
      setApiLoading(true);
      loopUpService(values)
        .then((res) => {
          if (res.status === 200) {
            setJsonData(res.data);
            setApiLoading(false);
          } else {
          }
        })
        .catch((e) => {
          console.log(e.response);
          message.error(e.response.statusText);
          setApiLoading(false);
        });
    });
  };

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    labelAlign: "right",
    name: "user-form",
  };

  return (
    <React.Fragment>
      <Spin spinning={apiLoading}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginTop: "10%",
          }}
        >
          <div>
            <Form {...formItemLayout} form={form} onFinish={onSubmit}>
              <div
                style={{
                  display: "flex",
                  justifyItems: "center",
                  alignItems: "center",
                  gap: "2rem",
                  margin: "3rem",
                }}
              >
                <div>
                  {/* <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter valid name",
                      },
                    ]}
                  >
                    <Input maxLength={512} />
                  </Form.Item> */}
                  <Form.Item
                    name="phoneNumber"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        pattern: new RegExp(/^(?:\d{3}-\d{3}-\d{4})$/),
                        message:
                          "PLEASE ENTER NUMBER IN REQUIRED FORMAT ###-###-####.",
                      },
                    ]}
                  >
                    <Input maxLength={12} placeholder="###-###-####" />
                  </Form.Item>
                  {/* <Form.Item name="areaCode" label="Area Code">
                    <Input maxLength={512} />
                  </Form.Item>
                  <Form.Item name="email" label="Email">
                    <Input maxLength={512} />
                  </Form.Item> */}
                </div>
                <div>
                  <Button htmlType="submit">Look Up</Button>
                </div>
              </div>
            </Form>
          </div>
          <div>
            <Table
              scroll={{ y: "60vh" }}
              bordered
              columns={columns}
              dataSource={data}
              style={{ width: "50rem", whiteSpace: "pre" }}
              pagination={false}
            />
          </div>
          {/* <div
            hidden={jsonData.length > 0 ? true : false}
            style={{ height: "60vh", overflow: "scroll", margin: "3rem" }}
          >
            <ReactJson src={jsonData} groupArraysAfterLength={1} />
          </div> */}
          {/* <div>
            <Table
              bordered
              columns={columns}
              dataSource={jsonData}
              style={{ width: "30rem" }}
              pagination={false}
            />
          </div> */}
        </div>
      </Spin>
    </React.Fragment>
  );
};

export default LookUp;
