/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Modal } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const [termsModal, setTermsModal] = useState(false);
  const [privacyModal, setPrivacyModal] = useState(false);

  return (
    <React.Fragment>
      <div>
        <footer className="footer">
          <div className="row">
            <div className="col">
              <div className="footer-new ">
                <div>
                  <p className="footer-text">
                    <span className="font-adjuster-for-footer">CellNinja</span>
                  </p>
                </div>
                <div>
                  <span className="footer-font">
                    CellNinja takes the complexity out of cell phone records,
                    enabling attorneys, law enforcement, mobile forensic
                    examiners and PI’s to uncover and evaluate potential
                    evidence faster, and to present it correctly.
                  </span>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="footer-new ">
                <div>
                  <p className="footer-text">
                    <Link className="anchor-for-fotter" to="/contactus">
                      <span className="font-adjuster-for-footer">Contact</span>
                    </Link>
                  </p>
                </div>
                <div>
                  <div className="footer-text">
                    {/* <a className="anchor-for-fotter" href="">
                      <p className="footer-text">help@cell.ninja</p>
                    </a> */}
                    <p className="footer-text footer-font">
                      Copyright 2022, IoT Analytics, LLC.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="footer-new ">
                <div>
                  <p className="footer-text">
                    <span className="font-adjuster-for-footer">Links</span>
                  </p>
                </div>
                <div className="footer-new">
                  <div className="footer-text">
                    <ul className="footer-links">
                      <p>
                        <li>
                          <Link className="anchor-for-fotter" to="/*">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link className="anchor-for-fotter" to="/aboutus">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link className="anchor-for-fotter" to="/contactus">
                            Contact
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="anchor-for-fotter"
                            to="/create-account"
                          >
                            Subscribe
                          </Link>
                        </li>
                        <li>
                          <a
                            className="footer-font"
                            onClick={() => {
                              setTermsModal(true);
                            }}
                          >
                            Terms
                          </a>
                        </li>
                        <li>
                          <a
                            className="footer-font"
                            onClick={() => {
                              setPrivacyModal(true);
                            }}
                          >
                            Privacy
                          </a>
                        </li>
                        <li>
                          <Link className="anchor-for-fotter" href="" to="">
                            Cookies
                          </Link>
                        </li>
                      </p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/* <div> 
              <span id="siteseal"
                style={{ position: "absolute", bottom: "20px", right: "0", zIndex: "5" }}
              >
                <script async type="text/javascript"
                  src="https://seal.godaddy.com/getSeal?sealID=fKSYGbDITb0zCSYN284A5MsIRjhz9BSIndbQgUZ4frbsliQfRAznElBva3Eb"></script>
              </span>
            </div> */}
          </div>
        </footer>
        <Modal
          footer={null}
          style={{ height: "580px" }}
          width="800px"
          open={termsModal}
          onCancel={() => setTermsModal(false)}
        >
          <iframe
            style={{
              width: "760px",
              height: "550px",
              borderWidth: "0px",
              marginTop: "40px",
            }}
            src="https://app.termly.io/document/terms-of-use-for-saas/4941e60a-52d4-4750-a059-a9b56039ffde"
          />
        </Modal>
        <Modal
          footer={null}
          style={{ height: "580px" }}
          width="800px"
          open={privacyModal}
          onCancel={() => setPrivacyModal(false)}
        >
          <iframe
            style={{
              width: "760px",
              height: "550px",
              borderWidth: "0px",
              marginTop: "40px",
            }}
            src="https://app.termly.io/document/privacy-policy/653e2505-d7a4-48f6-bc75-9ffd61f0b5ab"
          />
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default Footer;
