import React from 'react'
import './style.scss'
import howitworksimg from '../../../images/guaranteed-accurate.jpg'
const Accuracy = () => {
    return (
        <div className='main-container-accurate'>
            <div className='staticheading'>
                <span className='us'>Guaranteed </span>
                <span className='about'>Accurate</span>
            </div>
            <div className='m-con'>
                <div className='left-right-con'>
                    <div className='leftie'>
                        <div className='point'>
                            <span className='descrip'>Call detail records are highly reliable evidence for</span>
                        </div>
                        <div>
                            <ul className='ul-for-accurate'>
                                <li>criminal cases</li>
                                <li>distracted driving</li>
                                <li>divorce</li>
                                <li>child custody</li>
                                <li>IP cases</li>
                                <li>wiretap</li>
                                <li>and more</li>

                            </ul>
                        </div>
                        <div className='find-relevant-fact'>
                            <span className='span-understand'>Understand and present evidence correctly<br /></span>
                            <span className='frf-span'>
                                CellNinja helps you interpret cellphone records and understand the facts. Now you can provide highly accurate, defensible evidence and clear, compelling data for triers of fact. Our unrivaled cellphone industry experience means you can rely on CellNinja objectivity and accuracy.
                            </span>
                        </div>
                    </div>
                    <div className='rightie'>
                        <img className='img-how-it-works' src={howitworksimg} alt="" />
                        <div className='facts-container'>
                            <span className='facts'>Fact:</span>
                            <span className='people-span'>Over 84% of drivers KNOW the dangers of driving while distracted by their cellphones, however...<br />
                                36% of those drivers admit to reading or sending a text/email message while driving in the last month! </span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Accuracy;