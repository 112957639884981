import { FloatButton, Image } from "antd/es";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getCustomerMessage } from "../../../apis/customermessages";
import GuaranteedImg from "../../../images/home-row-01.webp";
import Row2 from "../../../images/home-row-02.png";
import Row3 from "../../../images/home-row-03.png";
import Row4 from "../../../images/home-row-04.png";
import Images from "../../../images/landing-page-logo.png";
import "./style.css";
import { UpOutlined } from "@ant-design/icons";

const loadMessages = () => {
  const hashMap = {};
  getCustomerMessage().then((response) => {
    if (response.status === 200) {
      response.data.forEach((item) => {
        hashMap[item.occasion] = item.messages;
      });
      const customerMessages = JSON.stringify(hashMap);
      localStorage.setItem("customerMessages", customerMessages);
    }
  });
};

const Home = () => {
  const customerMessages = JSON.parse(localStorage.getItem("customerMessages"));
  useEffect(() => {
    if (customerMessages === null) {
      loadMessages();
    }
  }, []);
  return (
    <React.Fragment>
      <div>
        <div className="body">
          <div className="container-f-both">
            <Link to={"/create-account"}>
              <button className="free-trial-btn">Get a FREE Report</button>
            </Link>
            <img className="banner-imgs" src={Images} alt="" />
          </div>
          <div className="para">
            <p>
              Developed by cellular industry experts as best evidence in civil
              and criminal matters.&nbsp;
              <br />
              Our online service creates Accurate, Fast, Easy and Secure
              reports… in minutes.&nbsp;
              <br />
              Pay per use… no contracts to sign, or software to manage.&nbsp;
              <a href="/how-it-works" className="anchor-for-how">
                How it works
              </a>
            </p>
          </div>
        </div>
        <div className="container">
          <div className="main-class">
            <div className="body-col">
              <div className="sub-layouts-container">
                <div className="body-row">
                  <div className="sub-layouts">
                    <img className="m-img" src={GuaranteedImg} alt="" />
                  </div>
                  <div className="sub-layouts">
                    <div className="fp-Title">
                      <span className="black-text">
                        Guaranteed <br />
                      </span>
                      <span className="red-text">Accurate</span>
                    </div>
                    <div className="fp-para">
                      Cell phone records are highly reliable evidence for
                      distracted driving, criminal cases, divorce, child
                      custody, IP cases, wiretap, and more.
                    </div>
                    <div className="fp-link">
                      <a href="/accurate">➔ View Details</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-layouts-container">
                <div className="body-row">
                  <div className="sub-layouts">
                    <div>
                      <div className="fp-Title">
                        <span className="red-text">Easy</span>
                        <span className="black-text">
                          {" "}
                          and <br /> Straightforward
                        </span>
                      </div>
                      <div className="fp-para">
                        CellNinja is a secure web-based service. Simply drag and
                        drop a file to create an accurate, actionable report in
                        minutes.
                      </div>
                      <div className="fp-link">
                        <a href="/easy">➔ View Details</a>
                      </div>
                    </div>
                  </div>
                  <div className="sub-layouts">
                    <img className="m-img" src={Row2} alt="" />
                  </div>
                </div>
              </div>
              <div className="sub-layouts-container">
                <div className="body-row">
                  <div className="sub-layouts">
                    <img className="m-img" src={Row3} alt="" />
                  </div>
                  <div className="sub-layouts">
                    <div className="fp-Title">
                      <span className="red-text">Fast </span>
                      <span className="black-text">Results</span>
                    </div>
                    <div className="fp-para">
                      We’ve engineered the time and complexity out of cellphone
                      records analysis, to give you quick insights into the
                      users’ activities, locations, movements, local times,
                      connections, and patterns of behavior.
                    </div>
                    <div className="fp-link">
                      <a href="/fast">➔ View Details</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-layouts-container">
                <div className="body-row">
                  <div className="sub-layouts">
                    <div>
                      <div className="fp-Title">
                        <span className="red-text">Secure </span>
                        <span className="black-text"> and Safe</span>
                      </div>
                      <div className="fp-para">
                        CellNinja data is stored on GovCloud, a secure isolated
                        cloud environment for government clients. GovCloud meets
                        the most stringent federal, state and local government
                        security and compliance standards to protect your data.
                      </div>
                      <div className="fp-link">
                        <a href="/secure">➔ View Details</a>
                      </div>
                    </div>
                  </div>
                  <div className="sub-layouts">
                    <Image
                      preview={false}
                      src={Row4}
                      className="m-img"
                      alt="Responsive image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cta-main">
          <div className="cta-title-2">About Call Detail Records</div>
          <div className="cta-details">
            <p>
              Quickly evaluate potential cases and prepare easily understandable
              exhibits. Uncover evidence that documents every activity of
              cellphone users with scientific certainty.&nbsp;
              <a href="/call-detail-records" className="anchor-for-learn">
                Learn More
              </a>
            </p>
          </div>
        </div>
        <div className="cta-main-bg">
          <div className="cta-title">Get Your FREE CellNinja Analysis Now</div>
          <div className="cta-details-for-lower">
            <a
              to={"/create-account"}
              className="btn-white"
              href="/create-account"
            >
              REGISTER
            </a>
          </div>
        </div>
        <FloatButton.BackTop
          shape="square"
          icon={<UpOutlined style={{ color: "white" }} />}
          style={{ backgroundColor: "#e4003b" }}
        />
      </div>
    </React.Fragment>
  );
};
export default Home;
