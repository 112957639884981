import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllProjects = (current, pageSize, sortBy, orderBy) => {
  return axios.get(
    APP_CONTEXT_URL +
      "/v1/projects/user/" +
      orderBy +
      "/" +
      "?page=" +
      (current - 1) +
      "&size=" +
      pageSize +
      "&sort=" +
      sortBy,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const getRecordsCount = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/project/" + id + "/count", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const getProjectData = (page, pageSize, projectId) => {
  return axios.get(
    APP_CONTEXT_URL +
      "/v1/projects/" +
      projectId +
      "?page=" +
      (page - 1) +
      "&size=" +
      pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const getProjectFilterCount = (
  projectId,
  data,
  sms,
  voice,
  fromDate,
  toDate
) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/project/" + projectId + "/filterCount",
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      params: {
        data: data,
        SMS: sms,
        voice: voice,
        fromDate: fromDate,
        toDate: toDate,
      },
    }
  );
};

export const getProjectFilterData = (
  page,
  pageSize,
  projectId,
  data,
  sms,
  voice,
  fromDate,
  toDate
) => {
  return axios.get(
    APP_CONTEXT_URL +
      "/v1/projects/" +
      projectId +
      "?page=" +
      (page - 1) +
      "&size=" +
      pageSize,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      params: {
        data: data,
        SMS: sms,
        voice: voice,
        fromDate: fromDate,
        toDate: toDate,
      },
    }
  );
};

export const generateExcelTemplate = (projectId) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/projects/" + projectId + "/preview/msexcel",
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      responseType: "blob",
    }
  );
};

export const generatePdfTemplate = (projectId) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/projects/" + projectId + "/preview/finalreport",
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      responseType: "blob",
    }
  );
};

export const generatePdfTowerReport = (projectId) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/projects/" + projectId + "/preview/uniquetower",
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      responseType: "blob",
    }
  );
};

export const getUniqiuePhoneNumbers = (id) => {
  return axios.get(
    APP_CONTEXT_URL + "/v1/projects/" + id + "/uniquephonenumbers",
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};
export const getUniqiueTowersLocation = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/uniquetower/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};
export const getProjectsCount = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/projects/count", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};
export const getProjectlocations = (projectId) => {
  return axios.get(APP_CONTEXT_URL + "/v1/projects/" + projectId + "/ucl", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const updateprojectUpn = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/projectupn", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const deleteProjectById = (projectId) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/projects/" + projectId,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};
export const checkProjectExists = (value) => {
  return axios.get(APP_CONTEXT_URL + "/v1/projects/projectname/" + value, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const cdrFileParserApi = (overWrite, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return axios.post(APP_CONTEXT_URL + "/v1/parsePDF/" + overWrite, formData, {
    headers: {
      "Content-Type": `multipart/form-data`,
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const finalReport = (formData, recordId) => {
  return axios.post(APP_CONTEXT_URL + "/v1/finalreport/" + recordId, formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const downlaodInputFile = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/downloadinputfile",
    { uniqueId: id },

    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      responseType: "blob",
    }
  );
};

export const downlaodFile = (id, analysisFile) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/downloadfile/" + analysisFile,
    { uniqueId: id },
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
      responseType: "blob",
    }
  );
};

export const generateExcel = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/ssr/generateExcel/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
    responseType: "blob",
  });
};

export const importCellPhoneNumbers = (formData) => {
  return axios.post(APP_CONTEXT_URL + "/v1/uniquephonenumber", formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const getFileType = (formData) => {
  return axios.post(APP_CONTEXT_URL + "/v1/checkFileType/", formData, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const generateZipFinalExcelReport = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/downloadreportexcelzipfile/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
    responseType: "blob",
  });
};
