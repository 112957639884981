import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const payment = (token) => {
    return axios.post(
        APP_CONTEXT_URL + "/v1/payment", { token: token.id, email: token.email },
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: localStorage.getItem("__CDR-Analyzer"),
            },
        }
    )
}

export const updateInfo = (id, data) => {
    return axios.post(APP_CONTEXT_URL + "/v1/updateinfo", {
        uniqueId: id, receiptUrl: data.data.receiptURL, email: data.data.email
    }, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__CDR-Analyzer"),
        },
    });
};

export const updateInfoWithID = (id, data) => {
    return axios.post(APP_CONTEXT_URL + "/v1/updateinfo", {
        uniqueId: id, receiptUrl: data.id, email: data.receipt_email
    }, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__CDR-Analyzer"),
        },
    });
};

export const updateErrorRecord = (id, email) => {
    return axios.post(APP_CONTEXT_URL + "/v1/updateErrorRecord", {
        uniqueId: id, email: email
    }, {
        headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: localStorage.getItem("__CDR-Analyzer"),
        },
    });
};