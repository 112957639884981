import React from "react";
import "./style.scss";
import callrecord1 from "../../../images/call-record-01.jpg";
import callrecord2 from "../../../images/call-record-02.jpg";

const CallDetailRecord = () => {
	return (
		<div className="main-container-detail">
			<div className="staticheading">
				<span className="cell-ninja-color">CellNinja</span>
				<span className="us"> Makes Cellphone Records Simple</span>
			</div>
			<div className="m-con">
				<div className="left-right-con">
					<div className="leftie">
						<div className="find-relevant-fact">
							<span className="span-understand">
								Interpreting cellphone records is confusing.
								<br />
							</span>
							<span className="frf-span">
								Manual analysis of technical records and cell tower locations,
								and making time zone adjustments result in missed evidence,
								evidentiary errors and wasted time.{" "}
							</span>
						</div>
						<div className="find-relevant-fact">
							<span className="darker-span">
								That’s why we developed our online service, so
								
								<span className="color-adjuster"> anyone</span> can accurately
								interpret cellphone records like a CellNinja.
							</span>
						</div>
						<div className="find-relevant-fact">
							<span className="frf-span">
								Quickly evaluate potential cases and prepare easily
								understandable exhibits. Uncover evidence that documents every
								activity of the cellphone user with scientific certainty.
							</span>
						</div>
						<div className="find-relevant-fact">
							<span className="span-understand">
								CellNinja reports provide:
								<br />
							</span>
							<span className="frf-span">
								<ol className="ul-of-report">
									<li>Users’ general location, movement and activity</li>
									<li>Type of activity: calls, texts, and data</li>
									<li>
										Calls made, received, attempted, forwarded, waiting or
										ignored
									</li>
									<li>Time and duration of activity</li>
									<li>Amount of data sent and received</li>
									<li>Contacts and interactions</li>
									<li>Behavior patterns and practices</li>
									<li>Inactivity periods / opportunities for sleep</li>
									<li>Subscriber and phone device details</li>
									<li>
										Data to affirm or contradict sworn testimony or alibis
									</li>
								</ol>
							</span>
						</div>
					</div>
					<div className="rightie">
						<img className="img-how-it-works" src={callrecord1} alt="" />
						<div className="divider">
							<span>
								A standard technical report (above) from the cellphone company
								compared to a clear, concise, and easy to read CellNinja report
								(below).
							</span>
						</div>
						<img className="img-how-it-works" src={callrecord2} alt="" />
					</div>
				</div>
			</div>
		</div>
	);
};
export default CallDetailRecord;
