import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const loopUpService = (data) => {
  return axios.post(APP_CONTEXT_URL + "/v1/idilookup", data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const phoneLookUp = (data, uniqueKey) => {
  return axios.post(APP_CONTEXT_URL + "/v1/idilookup/" + uniqueKey, data, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};
export const getLookUpRecord = (fileName) => {
  return axios.get(APP_CONTEXT_URL + "/v1/getidilookuprecord/" + fileName, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};
