import React, { useEffect, useState } from "react";
import { Button, Input, Form, Select, message } from "antd";
import {
  addPermission,
  editPermission,
  getAllPermissions,
} from "../../../apis/settingsapi";
import { ALPHABETICAL_INPUTS } from "../../../utils/constants";
import { fetchCustomerMessages } from "../../app/mainIndex";

function RoleForm(props) {
  const [permissionOptions, setPermissionOptions] = useState([]);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.role && props.action === "Edit") {
      const values = props.role;
      values &&
        form.setFieldsValue({
          ...values,
          permissions: (values.permissions || []).map((p) => p.id),
        });
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.action, props.role]);

  const formItemLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 17 },
    labelAlign: "right",
  };

  const fetchPermission = async () => {
    const response = await getAllPermissions();
    if (response.status === 200) {
      setPermissionOptions(response.data);
    }
  };

  const handleSubmit = (e) => {
    if (props.action === "Add") {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          const { permissions = [], ...otherValues } = values;
          addPermission({
            permissions: permissions.map((pId) => ({ id: pId })),
            ...otherValues,
          }).then((response) => {
            if (response.status === 200) {
              props.closeModal(false);
              setIsLoading(false);
              props.newRole();
              form.resetFields();
              message.success(response.data.message);
            }
          });
        })
        .catch((info) => {
          message.error(info);
        });
    } else if (props.action === "Edit") {
      form
        .validateFields()
        .then((values) => {
          setIsLoading(true);
          const { permissions = [], ...otherValues } = values;
          editPermission({
            permissions: permissions.map((pId) => ({ id: pId })),
            id: props.id,
            ...otherValues,
          }).then((response) => {
            if (response.status === 200) {
              props.closeModal(false);
              setIsLoading(false);
              props.newRole();
              form.resetFields();
              message.success(response.data.message);
            }
          });
        })
        .catch((info) => {
          message.error(info);
        });
    }
  };

  useEffect(() => {
    fetchPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Form
        {...formItemLayout}
        name="role-form"
        form={form}
        style={{ textAlign: "-webkit-right" }}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              pattern: ALPHABETICAL_INPUTS,
              message: "Please enter valid name",
            },
          ]}
        >
          <Input maxLength={256} />
        </Form.Item>
        <Form.Item
          name="permissions"
          label="Permissions"
          rules={[
            {
              required: true,
              message: "Please select permissions from list",
            },
          ]}
        >
          <Select showSearch mode="multiple">
            {permissionOptions
              ? permissionOptions.map((permission) => {
                  return (
                    <Option key={permission.id} value={permission.id}>
                      {permission.name}
                    </Option>
                  );
                })
              : ""}
          </Select>
        </Form.Item>
        <Button
          loading={isLoading}
          type="primary"
          key="submit"
          htmlType="submit"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Form>
    </React.Fragment>
  );
}
export default RoleForm;
