import { Button, Form, Input, message } from "antd";
import React from "react";
import img1 from "../../../images/contact-us.jpg";

import { sendContactUsMail } from "../../../apis/mailapi";
import "./style.scss";
const ContactUs = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    sendContactUsMail(values)
      .then((response) => {
        if (response.status === 200) {
          form.resetFields();
          message.success(response.data.message);
        }
      })
      .catch((e) => {
        throw e;
      });
  };

  return (
    <div className="main-containers">
      <div className="content-containers">
        <div className="heading-cons">
          <span className="contact">Contact</span>
          <span className="us"> Us</span>
        </div>
        <div className="contact-us-content-cons">
          <div className="form-consting">
            <Form
              className="contactus"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 18 }}
              form={form}
              onFinish={onFinish}
            >
              <Form.Item
                // label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your first name",
                  },
                ]}
              >
                <Input
                  autoFocus={true}
                  placeholder="Please enter your First Name *"
                />
              </Form.Item>
              <Form.Item
                // label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter your last name",
                  },
                ]}
              >
                <Input placeholder="Please enter your Last Name *" />
              </Form.Item>
              <Form.Item
                // label="Work Email"
                name="emails"
                rules={[
                  {
                    required: true,
                    message: "Please enter your work email",
                  },
                ]}
              >
                <Input placeholder="Please enter your Work Email *" />
              </Form.Item>
              <Form.Item
                // label="Phone"
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                ]}
              >
                <Input placeholder="Please enter your Phone Number *" />
              </Form.Item>
              <Form.Item
                // label="Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter your message *",
                  },
                ]}
              >
                <Input.TextArea
                  style={{ height: "70px" }}
                  placeholder="Please enter your message"
                />
              </Form.Item>
              <Form.Item className="new-ac-create-btn-con">
                <Button htmlType="submit" className="contactus-btn">
                  Submit
                </Button>
              </Form.Item>
              {/* <Button htmlType="submit" className="submit-btn">Submit</Button> */}
            </Form>
          </div>
          <div className="right-sides-conss">
            <img className="image-con-us-n" src={img1} alt="" />
            <div className="fact-cons">
              <span className="facts">Fact:</span>
              <span>
                Cell phone users are 5.36 times more likely to get into an
                accident than undistracted drivers.{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
