/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  Modal,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSpecificCustomerMessages } from "../../apis/customermessages";
import { resetPasswordMailSend } from "../../apis/resetpasswordapi";
import logo from "../../images/Cell_Ninja_adjusted_logo.png";
import ERRORICON from "../../images/error-icon-cellninja-color.png";
import Reset from "../../images/reset.svg";
import { getAuthToken } from "../../utils/authToken";
import { CELLNINJACOLOR } from "../../utils/constants";
import NavigationBar from "../staticPages/NavigationBar/NavigationBar";
import { loginUser } from "./../../actions/user";
import "./style.scss";

const LoginForm = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [termsUseModal, setTermsUseModal] = useState(false);
  const [resetModalVisibility, setResetModalVisibility] = useState(false);
  const [accountDisableMessage, setAccountDisableMessage] = useState("");
  const [resetPasswordMessage, setResetPasswordMessage] = useState("");
  const [resetLoading, setResetLoading] = useState(false);
  const [agreementBox, setAgreementBox] = useState(false);
  const [blockModal, setBlockModal] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    handleLogin(values);
  };

  useEffect(() => {
    if (window.location?.pathname === "/user/login") {
      if (getAuthToken()) {
        navigate("/user/selfservice");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleLogin(values) {
    dispatch(loginUser(values, navigate, props));
  }

  const resetPassword = (values) => {
    setResetLoading(true);
    var email = values.email;
    resetPasswordMailSend(email)
      .then((response) => {
        if (response.status === 200) {
          setResetLoading(false);
          if (response.data.object !== null) {
            message.success(response.data.message);
            setResetModalVisibility(false);
            form.resetFields();
          } else {
            message.error(response.data.message);
            form.resetFields();
          }
        }
      })
      .catch((e) => {
        if (
          e.response.data.message === "Cell Ninja has disabled your account"
        ) {
          setResetLoading(false);
          setResetModalVisibility(false);
          setBlockModal(true);
        }
      });
  };

  const onChange = (e) => {
    setAgreementBox(e.target.checked);
  };

  useEffect(() => {
    if (accountDisableMessage === "")
      getSpecificCustomerMessage("Account disabled");
    if (resetPasswordMessage === "")
      getSpecificCustomerMessage("Reset password");
  }, []);

  const getSpecificCustomerMessage = (value) => {
    getSpecificCustomerMessages(value, true).then((response) => {
      if (response.status === 200) {
        if (value === "Account disabled")
          setAccountDisableMessage(response.data.customerMessages);
        else if (value === "Reset password")
          setResetPasswordMessage(response.data.customerMessages);
      }
    });
  };

  return (
    <React.Fragment>
      <NavigationBar />
      <div className="center-aligner">
        <Form
          className="width-adjuster-for-login"
          name="normal_login"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <div className="terms-container">
            <Form.Item
              name={agreementBox === true ? "username" : "test"}
              rules={[
                {
                  required: true,
                  message: "Please check this box to continue!",
                },
              ]}
            >
              <div className="checkbox-container">
                <Checkbox checked={agreementBox} onChange={onChange}></Checkbox>
                <p className="justify-text">
                  By continuing, I attest that I am in rightful possession of
                  the cell phone records I submit, that I am using CellNinja for
                  my own registered account and organization, and that I have
                  read and agree to CellNinja's&nbsp;
                  <a
                    style={{ color: "#e4003b" }}
                    onClick={(e) => {
                      e.preventDefault();
                      setTermsUseModal(true);
                    }}
                  >
                    Terms and Conditions
                  </a>
                  &nbsp;of use.
                </p>
              </div>
            </Form.Item>
          </div>
          <div>
            <p className="last-text">
              Please login to submit your cell phone records and get your
              CellNinja report.
            </p>
          </div>
          <div className="login-card">
            <div className="width-for-form">
              <h2>Login</h2>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input
                  autoFocus
                  prefix={
                    <UserOutlined
                      className="site-form-item-icon"
                      style={{ flex: "none" }}
                    />
                  }
                  placeholder="Username"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <a
                  onClick={() => {
                    setResetModalVisibility(true);
                  }}
                >
                  <p className="para-handler">Forgot username or password?</p>
                </a>
                <Button
                  type="danger"
                  htmlType="submit"
                  className="login-form-button"
                  style={{
                    float: "right",
                    background: CELLNINJACOLOR,
                  }}
                >
                  Login
                </Button>
              </Form.Item>
            </div>
            <div className="login-cn-logo">
              <Image src={logo} preview={false} />
            </div>
          </div>
        </Form>
        <div>
          <Modal
            style={{
              align: "center",
            }}
            width={500}
            open={resetModalVisibility}
            footer={null}
            onCancel={() => {
              setResetModalVisibility(false);
            }}
          >
            <Spin
              style={{ color: CELLNINJACOLOR }}
              spinning={resetLoading}
              tip="Loading..."
            >
              <div style={{ textAlign: "center" }}>
                <br />
                <img alt="erroricon" src={Reset} height={100} width={100} />
              </div>
              <div style={{ textAlign: "center" }}>
                <h1>Reset Password</h1>
              </div>
              <div className="justify-text" style={{ fontSize: "17px" }}>
                <h3>{resetPasswordMessage}</h3>
              </div>
              <br />
              <div style={{ textAlign: "center" }}>
                <Form
                  form={form}
                  style={{
                    width: "70%",
                    alignContent: "center",
                    marginLeft: "10%",
                  }}
                >
                  <div className="email-adjuster-for-reset-modal">
                    <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                          message: "Email is required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </Form>
                <Button
                  style={{
                    width: "100px",
                    height: "50px",
                    backgroundColor: CELLNINJACOLOR,
                    color: "white",
                    fontSize: "18px",
                  }}
                  onClick={() => {
                    form
                      .validateFields()
                      .then((res) => {
                        resetPassword(res);
                      })
                      .catch();
                  }}
                >
                  Submit
                </Button>
              </div>
            </Spin>
          </Modal>
        </div>
        <div hidden={blockModal}>
          <Modal open={blockModal} width={500} footer={false} closable={false}>
            <div style={{ textAlign: "center" }}>
              <br />
              <img alt="" src={ERRORICON} height={80} width={80} />
            </div>

            <div style={{ textAlign: "center" }}>
              <br />
              <h2>Account Disabled</h2>
              <br />
            </div>

            <div style={{ textAlign: "center", fontSize: "15px" }}>
              <h3>{accountDisableMessage}</h3>
            </div>
            <div style={{ textAlign: "center" }}>
              <br />
              <Button
                style={{
                  width: "150px",
                  height: "50px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setBlockModal(false);
                  form.resetFields();
                }}
              >
                Dismiss
              </Button>
            </div>
          </Modal>
        </div>
      </div>
      <Modal
        footer={null}
        style={{ height: "580px" }}
        width="800px"
        open={termsUseModal}
        onCancel={() => setTermsUseModal(false)}
      >
        <iframe
          style={{
            width: "760px",
            height: "550px",
            borderWidth: "0px",
            marginTop: "40px",
          }}
          src="https://app.termly.io/document/terms-of-use-for-saas/4941e60a-52d4-4750-a059-a9b56039ffde"
        />
      </Modal>
    </React.Fragment>
  );
};
export default LoginForm;
