import React from "react";
import "./style.scss";
import howitworksimg from "../../../images/secure-and-safe.jpg";
const SecureSafe = () => {
	return (
		<div className="main-container-safe">
			<div className="staticheading">
				<span className="about">Secure </span>
				<span className="us">and Safe</span>
			</div>
			<div className="m-con">
				<div className="left-right-con">
					<div className="leftie">
						<div className="point">
							<span className="frf-span">
								Our <span className="color-adjuster">website host</span> is
								ISO/IEC 27001:2013 certified and PCI DSS 3.2 Level 1 certified.
							</span>
						</div>
						<div className="find-relevant-fact">
							<span className="frf-span">
								Our <span className="color-adjuster">domain is secured</span> by
								an EV SSL certificate after thorough vetting of our physical,
								operational and authentic online identity. This provides an
								encrypted connection between your browser and our server, and
								confirms that you are dealing with the legitimate website.{" "}
							</span>
						</div>
						<div className="find-relevant-fact">
							<span className="frf-span">
								{/* added "For law enforcement clients, our...." new trello change */}
								For law enforcement clients, our{" "}
								<span className="color-adjuster">data is stored</span> on
								GovCloud, a secure isolated cloud environment for government
								clients. GovCloud meets the most stringent federal, state and
								local government security and compliance standards to protect
								your data.
							</span>
						</div>
						<div className="find-relevant-fact">
							{/* commented because of the new trello change */}
							{/* ----------------------- */}
							{/* <span className="frf-span">
								Our application is operated by US citizens, only on US soil,
								with restricted access.
							</span> */}
							{/* ----------------------- */}
						</div>
					</div>
					<div className="rightie">
						<img className="img-how-it-works" src={howitworksimg} alt="" />
						<div className="facts-container">
							<span className="facts">Fact:</span>
							<span className="people-span">
								EVERY Cellphone network activity creates a permanent telephone
								company record.
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SecureSafe;
