import axios from "axios";
import { APP_CONTEXT_URL } from "../utils/constants";

export const getAllPermissions = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/permissions", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const getRoles = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/roles", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const addPermission = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/addroles", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const editPermission = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/editroles", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const deleteRole = (id) => {
  return axios.post(
    APP_CONTEXT_URL + "/v1/deleteroles/" + id,
    {},
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("__CDR-Analyzer"),
      },
    }
  );
};

export const getUsers = () => {
  return axios.get(APP_CONTEXT_URL + "/v1/users", {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const addUser = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/adduser", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const editUser = (values) => {
  return axios.post(APP_CONTEXT_URL + "/v1/edituser", values, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const getUser = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/users/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const updateUserStatus = (value) => {
  return axios.post(APP_CONTEXT_URL + "/v1/updateuserstatus/", value, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const searchUser = (name) => {
  return axios.get(APP_CONTEXT_URL + "/v1/searchusers/" + name, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const changePassword = (value) => {
  return axios.post(APP_CONTEXT_URL + "/v1/users/password", value, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const deleteUserById = (value) => {
  return axios.get(APP_CONTEXT_URL + "/v1/users/deleteuser/" + value, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
  });
};

export const downlaodSubpoenaFile = (id) => {
  return axios.get(APP_CONTEXT_URL + "/v1/downloadsubpoenafile/" + id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("__CDR-Analyzer"),
    },
    responseType: "blob",
  });
};
