import { resetEntities } from './../actions/data';
import { dispatch } from './../store/index';
import { clearAuth } from './../utils/authToken';
import {message} from 'antd'
import request from './../service/index';
import { getAuthToken } from './../utils/authToken';
import {
  setLastSessionActivity,
  getLastSessionActivity,
} from './sessionIdentifier';
import { APP_CONTEXT_URL } from './constants';

export default function doSessionManagement(token) {
  //parse to get token string
  function parseToken(tokken) {
    tokken = tokken.split(' ')[1];
    //parse token to get token claims to get expiry time
    let base64Url = tokken.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  }

  let jwtObject = parseToken(token);
  if (jwtObject && jwtObject['exp']) {
    let expirationLimit = jwtObject['exp'] - jwtObject['iat']; //session expire after seconds
    //refresh after limit
    let maxInactivity = jwtObject['reflim'];

    //check user activity and do session handling to get new jwt w.r.t user activity
    //The number of seconds that have passed
    //since the user was active.
    let sessionlastActivity = getLastSessionActivity(); //session sync across tabs
    let secondsSinceLastActivity = sessionlastActivity || 0;
    let consecutiveActivityTime = 0;

    //The function that will be called whenever a user is active
    function activity() {
      //refresh token if last activity limit not exceeded
      if (
        secondsSinceLastActivity < expirationLimit &&
        secondsSinceLastActivity > expirationLimit - maxInactivity
      ) {
        //do refresh token
        request({
          method: 'POST',
          url: APP_CONTEXT_URL + '/v1/relogin',
          data: '',
        }).then((res) => {
          setLastSessionActivity(0);
        });
      }
      //reset the secondsSinceLastActivity variable
      //back to 0
      consecutiveActivityTime += parseInt(secondsSinceLastActivity);
      secondsSinceLastActivity = 0;
    }

    //Setup the setInterval method to run
    //every second. 1000 milliseconds = 1 second.
    let interval = setInterval(function () {
      secondsSinceLastActivity++;
      setLastSessionActivity(secondsSinceLastActivity);
      
      if (
        consecutiveActivityTime < expirationLimit &&
        consecutiveActivityTime > expirationLimit - maxInactivity
      ) {
        request({
          method: 'POST',
          url: APP_CONTEXT_URL + '/v1/relogin',
          data: '',
        }).then((res) => {
          setLastSessionActivity(0);
        });
        consecutiveActivityTime = 0;
        secondsSinceLastActivity = 0;
      } else if (secondsSinceLastActivity > expirationLimit) {
        //if the user has been inactive or idle for longer
        //then the seconds specified in maxInactivity
        //Redirect them to your logout
        window.appHistory.push('/login');
        clearAuth();
        dispatch(resetEntities());
        //Remove event listeners and stop interval(also on logout events)
        document.removeEventListener('mousedown', activity);
        document.removeEventListener('mousemove', activity);
        document.removeEventListener('keydown', activity);
        document.removeEventListener('scroll', activity);
        document.removeEventListener('touchstart', activity);
        clearInterval(interval);
        setLastSessionActivity(0);
        message.warn("Session expired due to inactivity")
      } else if (getAuthToken() == null) {
        clearAuth();
        dispatch(resetEntities());
        //Remove event listeners and stop interval(also on logout events)
        document.removeEventListener('mousedown', activity);
        document.removeEventListener('mousemove', activity);
        document.removeEventListener('keydown', activity);
        document.removeEventListener('scroll', activity);
        document.removeEventListener('touchstart', activity);
        setLastSessionActivity(0);
        clearInterval(interval);
      } else if (getAuthToken()) {
        //for handling across tabs
        let isNewToken = parseToken(getAuthToken());
        if (!(isNewToken && isNewToken['exp'])) {
          clearAuth();
          dispatch(resetEntities());
          //Remove event listeners and stop interval(also on logout events)
          document.removeEventListener('mousedown', activity);
          document.removeEventListener('mousemove', activity);
          document.removeEventListener('keydown', activity);
          document.removeEventListener('scroll', activity);
          document.removeEventListener('touchstart', activity);
          setLastSessionActivity(0);
          //clearInterval(interval);
        } else if (
          isNewToken &&
          isNewToken['exp'] &&
          isNewToken['exp'] !== jwtObject['exp']
        ) {
          //if token is refreshed from other tab
          jwtObject = isNewToken;
          secondsSinceLastActivity = 0;
          consecutiveActivityTime = 0;
          setLastSessionActivity(0);
        }
      }
    }, 1000);

    //An array of DOM events that should be interpreted as
    //user activity.
    var activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'scroll',
      'touchstart',
    ];

    //add these events to the document.
    //register the activity function as the listener parameter.
    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, activity, true);
    });

    return interval;
  }
}
